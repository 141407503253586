import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';

import { LobsterLayout, LobsterSheet, LobsterDivider, LobsterHeading, LobsterSheetTrigger, LobsterButton } from '@hlk/lobster-react/dist';

import PaymentsAgree from 'components/PaymentsAgree';
import PaymentsAddPayment from 'components/PaymentsAddPayment';
import PaymentsConfirmed from 'components/PaymentsConfirmed';
import PaymentsBalance from 'components/PaymentsBalance';

import { fetchCCInfo, createCCInfo, editCCInfo, removeCCInfo } from 'store/actions/api/eacceptances'

function cancelCloseModal(open, warnClosed) {
  return (
    <LobsterSheet
      open={open}
      dataTarget="sheet-stuck-confirm"
      direction="center"
      sheetTitle="stuck sheet confirmation"
      sheetOnClose={warnClosed}>
      <div className="sheet_title typo_body--regular typo_body--bold lobster_layout_margin-bottom--sm">Are you sure?</div>
      <div className="lobster_layout_margin-top--lg">Your progress will not be saved.</div>
      <LobsterLayout
        className="lobster_layout_margin-top--lg"
        data-align="center">
        <LobsterSheetTrigger
          dataTarget="payment-drawer"
          data="Yes I'm Sure"
          className="lobster_layout_margin-right--sm"></LobsterSheetTrigger>
        <LobsterSheetTrigger
          dataTarget="sheet-stuck-confirm"
          data="Cancel"
          dataType="text"
          dataVariation="primary"></LobsterSheetTrigger>
      </LobsterLayout>
    </LobsterSheet>
  )
}

function agreementsModal() {
  return (<LobsterSheet
    dataTarget="payment-acceptance"
    direction="center"
    sheetTitle="Payment Agreement Acceptance"
    className="sheet_wide">
    <div className="sheet_title typo_body--regular typo_body--bold">Payment Agreement Acceptance</div>
    <p className="lobster_layout_margin-top--lg">The cardholder hereby authorizes all actual charges for the above-listed Merchant order number(s) be charged to the cardholder's above-described credit card(s). The actual moving charges will be the final audited costs of all services performed, including the original services requested and additional moving/supplemental services approved or requested by cardholder or otherwise required in order to accomplish the safe transportation (as defined by federal law) of the shipment. In the event that the final audited charges are in excess of the estimates, the cardholder agrees to be responsible for supplemental charges. In the event that the final audited charges are less than the estimated charges that are charged to the cardholder's account, the cardholder shall be entitled to a refund. Cardholder agrees that his/her signature on this form constitutes his/her "signature on file" and is his/her agreement to pay all estimated and supplemental charges as described above, and that Merchant is authorized to charge all actual moving charges, including supplemental charges, to the identified account of cardholder(s). The estimated charges may be charged within 48 hours of loading.</p>

    <p>A credit card authorization will be requested up to two days prior to your scheduled load date. A successful authorization will put a hold on the funds that will be charged to your card on the day your shipment loads.</p>

    <p>Merchant:<br />
    One Premier Drive<br />
    Fenton, MO 63026<br />
    United States of America<br />
    </p>
  </LobsterSheet>)
}

function deleteCardConfirmModal(props, card, onDelete) {
  return (<LobsterSheet
    key="saved-card-confirm-delete"
    dataTarget="confirm-delete-card"
    direction="center"
    sheetTitle="confirm delete card">
    <div className="sheet_title typo_body--regular typo_body--bold lobster_layout_margin-bottom--sm">Are you sure?</div>
    <div className="lobster_layout_margin-top--lg">{props && props.paymentBalanceContent.deleteCardCopy} {card && card.lastFourCardNumber}</div>
    <LobsterLayout
      className="lobster_layout_margin-top--lg"
      data-align="center">
      <LobsterSheetTrigger
        dataTarget="confirm-delete-card"
        data={props && props.paymentBalanceContent.deleteCardCtaConfirmText}
        setter={() => {
          window.dataLayer.push({ 'event': 'deleteCardConfirmation' });
          onDelete()
        }}
        className="lobster_layout_margin-right--sm"></LobsterSheetTrigger>
      <LobsterSheetTrigger
        dataTarget="confirm-delete-card"
        data={props && props.paymentBalanceContent.deleteCardCtaCancelText}
        dataType="text"
        dataVariation="primary"></LobsterSheetTrigger>
    </LobsterLayout>
  </LobsterSheet>)
}

function PaymentDrawer(props) {
  const dispatch = useDispatch();

  // TODO: Testing state additions, pls remove when complete
  const savedCards = useSelector(R.path(['ccInfo', 'data', 'cards'])) || []
  const totalDue = useSelector(R.path(['ccInfo', 'data', 'totalDue']))
  const balanceDue = useSelector(R.path(['ccInfo', 'data', 'balanceDue']))

  const [selectedCardIdx, setSelectedCardIdx] = useState(null)
  // const [savedCards, setSavedCards] = useState([])
  const [openWarning, setWarningOpen] = useState(false);
  const [canClose, setCanClose] = useState(false);

  const warn = () => {
    return setWarningOpen(true)
  }

  const warnClosed = () => {
    return setWarningOpen(false)
  }

  const reset = () => {
    setCanClose(false)
    setWarningOpen(false)
    props.onReset()
  }

  const saveCard = (card) => {
    // setSavdCards([...savedCards, card])
    dispatch(createCCInfo(card))
    props.setStep(2)
  }

  const onEditCard = (idx) => {
    setSelectedCardIdx(idx)
    props.setStep(3)
  }

  const onDeleteCard = (idx) => {
    dispatch(removeCCInfo(savedCards[idx]))
    setSelectedCardIdx(null)
  }

  const updateCard = (data) => {
    setSelectedCardIdx(null)
    dispatch(editCCInfo(data))
    props.setStep(2)
  }

  const getTitle = (step) => {
    const titles = ['Agreements', 'Add Payment Methods', 'Add Payment Method', 'Your move is booked!']
    return titles[step - 1]
  }

  useEffect(() => {
    dispatch(fetchCCInfo())
  }, [])

  return ([
    <LobsterSheet
      key="payment-drawer"
      className='payment_drawer'
      dataTarget="payment-drawer"
      direction="bottom"
      fill="false"
      sheetTitle="Acceptance and Payment"
      canClose={canClose}
      sheetOnCloseAttempt={() => setWarningOpen(true)}
      sheetOnClose={() => reset()}
      sheetOnOpen={() => {
        if (props.paymentStep === 0) {
          return props.setStep(1)
        }
      }}
      closeButtonAriaLabel={'Close Acceptance and Payment, has popup'}>

      <div className="drawer_header">
        {(props.paymentStep === 2 || props.paymentStep === 3) &&
          <LobsterButton
            dataType="icon"
            data="chevron_left"
            className="payment_drawer-back-button"
            dataVariation="tertiary"
            dataAriaLabel="Previous Step"
            onClick={() => {
              props.setStep(props.paymentStep - 1)
              setSelectedCardIdx(null)
            }}></LobsterButton>}
        <LobsterHeading display="display-3">
          <h1>{props.stepTitle || 'Payments & Agreements'}</h1>
        </LobsterHeading>
        <LobsterDivider></LobsterDivider>
      </div>

      <div className="drawer_content-wrapper">
        {/* failsafe for accessibility focus trap */}
        {props.paymentStep === 0 && <a href="/">Payments & Agreements</a>}

        {props.paymentStep === 1 && <PaymentsAgree
          accept={() => props.setStep(2)}
          content={props.paymentAgreeContent}></PaymentsAgree>}

        {props.paymentStep === 2 && <PaymentsBalance
          onAddCard={() => props.setStep(3)}
          totalDue={totalDue}
          balanceDue={balanceDue}
          content={props.paymentBalanceContent}
          savedCards={savedCards}
          onEditCard={(idx) => onEditCard(idx)}
          hasSavedCards={savedCards.length > 0}
          onDeleteAttempt={(idx) => setSelectedCardIdx(idx)}
          complete={() => {
            props.setStep(4)
            setCanClose(true)
            props.onBookingCompleted()
          }}></PaymentsBalance>}

        {props.paymentStep === 3 && <PaymentsAddPayment
          savedCards={savedCards}
          onSaveCard={(card) => saveCard(card)}
          onUpdateCard={(data, idx) => updateCard(data, idx)}
          selectedCard={savedCards[selectedCardIdx]}
          selectedCardIdx={selectedCardIdx}
          content={props.paymentsAddPaymentContent}
        ></PaymentsAddPayment>}

        {props.paymentStep === 4 && <PaymentsConfirmed paymentSuccessContent={props.paymentSuccessContent}></PaymentsConfirmed>}
      </div>
    </LobsterSheet>,
    <div key="cancel-close-modal">{cancelCloseModal(openWarning, warnClosed)}</div>,
    <div key="agreements-modal">{agreementsModal()}</div>,
    <div key="delete-card-confirm-modal">
      {deleteCardConfirmModal(props, savedCards[selectedCardIdx],
        () => onDeleteCard(selectedCardIdx),
        () => setSelectedCardIdx(null), () => setSelectedCardIdx(null))}
    </div>
  ]);

}

export default PaymentDrawer;
