import React from 'react';
import { LobsterLayout, LobsterHeading, LobsterLink } from '@hlk/lobster-react/dist';
import EstimateLink from 'components/EstimateLink';
import BillOfLadingLink from 'components/BillOfLadingLink';

function AgreementsWidget(props) {
if (!props.agreementsWidgetContent) return null;
  return (
    <LobsterLayout
      width="100"
      widthSm="50"
      dataDirection="column"
      className="lobster_layout_padding-vertical-sm">
      <LobsterLayout
        dataAlign="center"
        dataJustify="space-between" style={{ minHeight: '42px' }}>
        <LobsterHeading display="display-3">
          <h2>Agreements</h2>
        </LobsterHeading>
      </LobsterLayout>
      <div className="widget widget--bordered">
        <p>You booked your move and accepted these agreements on August 31, 2020.</p>
        <LobsterLayout
          dataDirection="column"
          dataAlign="flex-start"
          dataJustify="flex-start">
          
          <LobsterLayout className="lobster_layout_margin-bottom--xs">
            <EstimateLink
              data={props.agreementsWidgetContent.estimate_cta_text}
              sendAgreementLinkToDataLayer="true"
              className="lobster_layout_margin-bottom--xs"></EstimateLink>
          </LobsterLayout>
          <LobsterLayout className="lobster_layout_margin-bottom--xs">
            <BillOfLadingLink
              sendAgreementLinkToDataLayer="true"
              data={props.agreementsWidgetContent.bill_of_lading_cta_text}></BillOfLadingLink>
          </LobsterLayout>

          <LobsterLink
            className="lobster_layout_margin-bottom--xs" dataType="text"
            data={props.agreementsWidgetContent.rights_and_responsibilities_form.title}
            dataHref={props.agreementsWidgetContent.rights_and_responsibilities_form.url}
            dataTarget="_blank"
            onClick={() => window.dataLayer.push({'event': 'agreementLink',
            'agreementLinkText': props.agreementsWidgetContent.rights_and_responsibilities_form.title})}
            ></LobsterLink>
          <LobsterLink
            className="lobster_layout_margin-bottom--xs" dataType="text"
            data={props.agreementsWidgetContent.ready_to_move_form.title}
            dataHref={props.agreementsWidgetContent.ready_to_move_form.url}
            dataTarget="_blank"
            onClick={() => window.dataLayer.push({'event': 'agreementLink',
            'agreementLinkText': props.agreementsWidgetContent.ready_to_move_form.title})}
            ></LobsterLink>
          <LobsterLink
            data={props.agreementsWidgetContent.credit_card_authorization_form}
            onClick={() => window.dataLayer.push({'event': 'agreementLink',
            'agreementLinkText': props.agreementsWidgetContent.credit_card_authorization_form})}
            className="lobster_layout_margin-bottom--xs"></LobsterLink>
        </LobsterLayout>
      </div>
    </LobsterLayout>
  );

}

export default AgreementsWidget;
