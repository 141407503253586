import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import * as R from 'ramda';

// React Redux
import { Provider, useDispatch, useSelector } from 'react-redux';
import configureStore from './store';

// Styles and Components
// import 'scss/index.scss';
// import 'scss/index.css';
import Root from 'routes'
import * as serviceWorker from 'serviceWorker';
import { keycloak } from "lib/api";
import { fetchUserProfile } from './store/actions/api/userProfile';
import { fetchEAcceptances } from 'store/actions/api/eacceptances';

// Import Lobster Components
import { applyPolyfills, defineCustomElements } from '@hlk/lobster/dist/loader';

// ---------------------------------------------------------------------
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// ---------------------------------------------------------------------

const Mayflower = React.lazy(() => import('./themes/mayflower'));
const United = React.lazy(() => import('./themes/united'));


// const store = configureStore();



// if (!userProfile) return null;

// Set up Routing and State Management
const Application = ({ store }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const dispatch = useDispatch();
  const userProfile = useSelector(state => R.path(['userProfile', 'data'], state))

  // Fetch Initial App Data
  useEffect(() => {
    keycloak
      .init({
        onLoad: "login-required",
        checkLoginIframe: false,
      })

      .then(() => {
        setAuthenticated(true);
        dispatch(fetchUserProfile());
      })
  }, [setAuthenticated]);

  if (!authenticated || !userProfile) return null
  return (
    <div>
      <React.Suspense fallback={<></>}>
        {(process.env.THEME === 'united') && <United />}
        {(process.env.THEME === 'mayflower') && <Mayflower />}
      </React.Suspense>
      <Root />
    </div>
  )
}

// Render our Application
ReactDOM.render(
  <Provider store={configureStore()}>
    <Application />
  </Provider>,
  document.getElementById('root')
);

// Lobster component polyfills and JS
applyPolyfills().then(() => {
  defineCustomElements();
});


// ---------------------------------------------------------------------
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// ---------------------------------------------------------------------

serviceWorker.register(('./serviceWorker.js', { scope: './' }))