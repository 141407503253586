export default (response) => {
  if (response && response.acf) {
    return response.acf
  } else {
    return {
      account_settings_link: {
        menu_item_label: 'Account Settings',
        menu_item_slug: 'account-settings',
        menu_item_target: '_self',
        icon_url_color: null,
        icon_url_white: null,
        icon_default: 'settings'
      },
      menu_item: [{
        menu_item_label: 'Dashboard',
        menu_item_slug: 'dashboard',
        icon_default: 'home'
      },
      {
        menu_item_label: 'Payment',
        menu_item_slug: 'payment',
        icon_default: 'payment'
      },
      {
        menu_item_label: 'High Value Inventory',
        menu_item_slug: 'high-value-inventory',
        icon_default: 'format_quote'
      },
      {
        menu_item_label: 'Contacts',
        menu_item_slug: 'contacts',
        icon_default: 'contacts'
      }],
      sign_out_link: {
        icon_url_color: null,
        icon_url_white: null,
        icon_default: 'exit_to_app',
        menu_item_label: 'Sign Out'
      },
      account_settings_link: {
        icon_url_color: null,
        icon_url_white: null,
        icon_default: 'settings',
        menu_item_label: 'Account Settings'
      }
    }
  }
}
