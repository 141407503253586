import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import TotalDueWidget from 'components/TotalDueWidget';
import MoveDetailsWidget from 'components/MoveDetailsWidget';
import ServicesIncludedModal from 'components/ServicesIncludedModal';
import PaymentDrawer from 'components/PaymentDrawer';
import PaymentsWidget from 'components/PaymentsWidget';
import AgreementsWidget from 'components/AgreementsWidget';
import { LobsterHeading, LobsterLayout, LobsterSheetTrigger, LobsterToast, LobsterIcon, LobsterImage } from '@hlk/lobster-react/dist';
import PageBanner from 'components/PageBanner';
import WidgetRow from 'components/WidgetRow';
import { fetchPaymentContent } from 'store/actions/wordpress/paymentContent'
import { fetchTimelineContent } from 'store/actions/wordpress/timelineContent';
import { completeBooking, fetchCCInfo } from 'store/actions/api/eacceptances'

function Payment() {
  const dispatch = useDispatch()

  const paymentContent = useSelector(state => R.path(['paymentContent'], state))
  const addPaymentsPageTitle = useSelector(state => R.path(['paymentContent', 'data', 'add_payment_methods_title'], state))
  const addPaymentPageTitle = useSelector(state => R.path(['paymentContent', 'data', 'add_payment_method_title'], state))
  const successPageTitle = useSelector(state => R.path(['paymentContent', 'data', 'success_title'], state))
  const paymentAgreementsPageTitle = useSelector(state => R.path(['paymentContent', 'data', 'page_title'], state))
  const agreementsWidgetContent = useSelector(state => R.path(['paymentContent', 'data', 'agreements_widget'], state))
  const moveDetailsWidgetContent = useSelector(state => R.path(['paymentContent', 'data', 'move_details_widget'], state))
  const paymentWidgetContent = useSelector(state => R.path(['paymentContent', 'data', 'payment_widget'], state))
  const agreementStepTitle = useSelector(state => R.path(['paymentContent', 'data', 'agreements_title'], state))
  const totalDueWidgetContent = useSelector(state => R.path(['paymentContent', 'data', 'total_due_widget'], state))
  const paymentPrompt = useSelector(state => R.path(['paymentContent', 'data', 'payment_prompt'], state))

  const fullTermsInfo = useSelector(state => R.path(['paymentContent', 'data', 'full_terms_info'], state))
  const tariffLink = useSelector(state => R.path(['paymentContent', 'data', 'tariff_link'], state))


  //pass to payment drawer
  const paymentBalanceContent = {
    preCtaText: useSelector(state => R.path(['paymentContent', 'data', 'pre_cta_text'], state)),
    completeBookingCtaText: useSelector(state => R.path(['paymentContent', 'data', 'complete_booking_cta_text'], state)),
    deleteCardCopy: useSelector(state => R.path(['paymentContent', 'data', 'delete_card_copy'], state)),
    deleteCardCtaConfirmText: useSelector(state => R.path(['paymentContent', 'data', 'delete_card_cta_confirm_text'], state)),
    deleteCardCtaCancelText: useSelector(state => R.path(['paymentContent', 'data', 'delete_card_cta_cancel_text'], state))
  }

  const paymentsAddPaymentContent = {
    tooltip: useSelector(state => R.path(['paymentContent', 'data', 'card_number_tooltip'], state)),
    ctaText: useSelector(state => R.path(['paymentContent', 'data', 'save_card_cta_text'], state))
  }

  const paymentAgreeContent = {
    ctaText: useSelector(state => R.path(['paymentContent', 'data', 'agree_cta_text'], state)),
    introCopy: useSelector(state => R.path(['paymentContent', 'data', 'agreement_into_copy'], state)),
    outroCopy: useSelector(state => R.path(['paymentContent', 'data', 'agreement_outro_copy'], state)),
    agreementsList: useSelector(state => R.path(['paymentContent', 'data', 'agreements_list'], state)),
    rightsAndResponsibilitesFile: useSelector(state => R.path(['paymentContent', 'data', 'rights_and_responsibilities_file'], state)),
    readyToMoveFile: useSelector(state => R.path(['paymentContent', 'data', 'ready_to_move_file'], state)),
    agreementStatement: useSelector(state => R.path(['paymentContent', 'data', 'agreement_statement'], state))
  }


  const paymentSuccessContent = {
    successSubtitle: useSelector(state => R.path(['paymentContent', 'data', 'success_sub_title'], state)),
    successImage: useSelector(state => R.path(['paymentContent', 'data', 'success_image'], state)),
    successListItems: useSelector(state => R.path(['paymentContent', 'data', 'success_list_items'], state)),
    successCtaText: useSelector(state => R.path(['paymentContent', 'data', 'success_cta_text'], state)),
  }

  useEffect(() => {
    let isMounted = true

    dispatch(fetchCCInfo())

    paymentContent.data ?
      setLoadingContent(false) :
      dispatch(fetchPaymentContent()).then(() => { if (isMounted) setLoadingContent(false) })

    timeline ?
      setLoadingTimeline(false) :
      dispatch(fetchTimelineContent()).then(() => { if (isMounted) setLoadingTimeline(false) })

    return () => { isMounted = false };
  }, [])

  const timeline = useSelector(state => R.path(['timelineContent', 'data'], state))
  const [paymentStep, setPaymentStep] = useState(0);
  const bookingCompleted = useSelector(state => state.bookingComplete)
  const [loadingContent, setLoadingContent] = useState(true);
  const [loadingTimeline, setLoadingTimeline] = useState(true);

  const getTitle = (step) => {
    const titles = [agreementStepTitle, addPaymentsPageTitle, addPaymentPageTitle, successPageTitle]
    return titles[step - 1]
  }

  const updateStep = (step) => {
    setPaymentStep(step)
  }

  const openPaymentDrawer = () => {
    window.dataLayer.push({ 'event': 'beginPay' });
    window.dataLayer.push({
      'event': 'screenLoaded',
      'screenUrl': '/agreements',
    })
  }

  const paymentDrawerTrigger = () => {
    return <LobsterSheetTrigger
      dataTarget="payment-drawer"
      data={paymentPrompt && paymentPrompt.payment_trigger_text}
      dataVariation="primary"
      dataType="button"
      dataDirection="row"
      sheetTitle="Acceptance and Payment"
      className="button_full"
      setter={openPaymentDrawer}></LobsterSheetTrigger>
  }

  return (
    <div>
      <PageBanner title={paymentAgreementsPageTitle}></PageBanner>
      <WidgetRow
        desktopReverse="true"
        bannerOverlay="true">
        <TotalDueWidget
          totalDueWidgetContent={totalDueWidgetContent}
          fullTermsInfo={fullTermsInfo}
          tariffLink={tariffLink}
        ></TotalDueWidget>
        <LobsterLayout
          width="100"
          widthSm="50"
          dataDirection="column"
          className="hidden_gt-sm"
          style={{ display: !bookingCompleted && `none` }}>
          <PaymentsWidget paymentWidgetContent={paymentWidgetContent && paymentWidgetContent}></PaymentsWidget>
        </LobsterLayout>
        <LobsterLayout
          width="100"
          dataDirection="column"
          className="hidden_gt-sm lobster_layout_margin-vertical--md"
          style={{ display: bookingCompleted && `none` }}>
          {paymentDrawerTrigger()}
        </LobsterLayout>
        <LobsterLayout
          width="100"
          widthSm="50"
          dataDirection="column"
          className="hidden_gt-sm lobster_layout_margin-top--md lobster_layout_margin-bottom--md"
          style={{ display: !bookingCompleted && `none` }}>

          <AgreementsWidget
            agreementsWidgetContent={agreementsWidgetContent && agreementsWidgetContent}
          ></AgreementsWidget>
        </LobsterLayout>

        <MoveDetailsWidget
          showEstimation
          moveDetailsWidgetContent={moveDetailsWidgetContent && moveDetailsWidgetContent}
        ></MoveDetailsWidget>
      </WidgetRow>

      <ServicesIncludedModal
        servicesIncludedModalContent={totalDueWidgetContent && totalDueWidgetContent.services_included_modal_title}
      ></ServicesIncludedModal>

      {!bookingCompleted &&
        <LobsterLayout
          className="payment_trigger_wrapper lobster_layout_padding-vertical--lg"
          dataJustify="center"
          dataAlign="center"
          dataDirection=""
          directionMd="column">
          <LobsterLayout
            dataContainer="centered"
            dataDirection="column"
            dataJustify="center"
            dataAlign="center">
            {(paymentPrompt && paymentPrompt.image) ?
              <LobsterLayout className="hidden_lt-md lobster_layout_margin-bottom--lg" data-direction="row" data-justify="center">
                <LobsterLayout width="30">
                  <LobsterImage
                    url={paymentPrompt.image.url}
                    imageAlt={paymentPrompt.heading}
                  ></LobsterImage>
                </LobsterLayout>
              </LobsterLayout>
              :
              <div className="payment_trigger-image hidden_lt-md lobster_layout_margin-bottom--lg"></div>
            }
            <LobsterHeading
              display="display-2"
              className="hidden_lt-md lobster_layout_margin-bottom--lg">
              <h2>{paymentPrompt && paymentPrompt.heading}</h2>
            </LobsterHeading>
            <div className="hidden_lt-sm">
              {paymentDrawerTrigger()}
            </div>
          </LobsterLayout>
        </LobsterLayout>
      }

      <PaymentDrawer
        onBookingCompleted={() => {
          dispatch(completeBooking())
        }}
        paymentBalanceContent={paymentBalanceContent}
        paymentAgreeContent={paymentAgreeContent}
        paymentsAddPaymentContent={paymentsAddPaymentContent}
        paymentSuccessContent={paymentSuccessContent}
        setStep={(step) => updateStep(step)}
        paymentStep={paymentStep}
        bookingCompleted={bookingCompleted}
        stepTitle={getTitle(paymentStep)}
        onReset={() => setPaymentStep(0)}></PaymentDrawer>

      <LobsterToast
        id="payment_add-success"
        toastMessage="Success - Payment method saved" toastDuration="3000" isStatic="false" verticalPosition="top" horizontalPosition="center"
      >
        <LobsterIcon
          data="check"
          dataSize="small"></LobsterIcon>
      </LobsterToast>
      <LobsterToast
        id="payment_add-error-invalid"
        toastMessage="Invalid Credit Card" toastDuration="3000" isStatic="false" verticalPosition="top" horizontalPosition="center"
      >
        <LobsterIcon
          data="close"
          dataSize="small"></LobsterIcon>
      </LobsterToast>

      <LobsterToast
        id="payment_add-error-cybersource-fail"
        toastMessage="An error has occurred. Try saving your card again." toastDuration="3000" isStatic="false" verticalPosition="top" horizontalPosition="center"
      >
        <LobsterIcon
          data="close"
          dataSize="small"></LobsterIcon>
      </LobsterToast>

      <LobsterToast
        id="payment_add-error-cybersource-down"
        toastMessage=" An error has occurred. Please try again later." toastDuration="3000" isStatic="false" verticalPosition="top" horizontalPosition="center"
      >
        <LobsterIcon
          data="close"
          dataSize="small"></LobsterIcon>
      </LobsterToast>
    </div>
  );
}

export default Payment;
