import { getChecklistContent } from 'lib/api'

export const CLEAR_CHECKLIST_CONTENT = 'CLEAR_CHECKLIST_CONTENT'
export function clearChecklistContent() {
  return {
    type: CLEAR_CHECKLIST_CONTENT,
  }
}

export const STORE_CHECKLIST_CONTENT = 'STORE_CHECKLIST_CONTENT'
export function storeChecklistContent(data) {
  return {
    type: STORE_CHECKLIST_CONTENT,
    data,
  }
}

export const CHECKLIST_CONTENT_ERROR = 'CHECKLIST_CONTENT_ERROR'
export function checklistContentError(data) {
  return {
    type: CHECKLIST_CONTENT_ERROR,
    data,
  }
}

export function fetchChecklistContent(data) {
  return function (dispatch) {

    dispatch(clearChecklistContent())
    return getChecklistContent()
      .then(
        (response) => {
          return dispatch(storeChecklistContent(response.data))
        }
      )
      .catch(
        (err) => {
          return dispatch(checklistContentError(null))
        }
      )
  }
}
