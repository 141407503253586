import { getHviContent } from 'lib/api'
import { trackingId } from 'lib/utils'

export const CLEAR_HVI_CONTENT = 'CLEAR_HVI_CONTENT'
export function clearHviContent() {
  return {
    type: CLEAR_HVI_CONTENT,
  }
}

export const STORE_HVI_CONTENT = 'STORE_HVI_CONTENT'
export function storeHviContent(data) {
  return {
    type: STORE_HVI_CONTENT,
    data,
  }
}

export const HVI_CONTENT_ERROR = 'HVI_CONTENT_ERROR'
export function HviContentError(data) {
  return {
    type: HVI_CONTENT_ERROR,
    data,
  }
}

export function fetchHviContent(filters) {
  return function (dispatch, getState) {
    dispatch(clearHviContent())

    const id = trackingId(getState())
    return getHviContent()
      .then(
        (response) => {
          return dispatch(storeHviContent(response.data))
        }
      )
      .catch(
        (err) => {
          return dispatch(HviContentError(null))
        }
      )
  }
}