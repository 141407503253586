import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LobsterLayout, LobsterIcon, LobsterButton, LobsterInput, LobsterCheckbox, LobsterTooltip, LobsterTooltipWrapper, LobsterErrorMessage } from '@hlk/lobster-react/dist';
import PaymentDrawerTwoColumnLayout from 'components/PaymentDrawerTwoColumnLayout';
import * as R from 'ramda'
import moment from 'moment'

import visa from 'assets/cc-visa.svg'
import amex from 'assets/cc-amex.svg'
import discover from 'assets/cc-discover.svg'
import mastercard from 'assets/cc-mastercard.svg'

function PaymentsAddPayment(props) {

  const csErrors = useSelector(state => R.path(['csErrors'], state))
  const [separateBilling, setSeparateBilling] = useState(false);

  const getCard = (selectedCard) => {
    const c = selectedCard ? {
      number: '0000 0000 0000 ' + selectedCard.lastFourCardNumber,
      name: `${selectedCard.firstName} ${selectedCard.lastName}`,
      chargeAmount: selectedCard.amount,
    } : {}
    return c
  }

  const [numberInvalidMessage, setNumberInvalidMessage] = useState(null)
  const [expirationInvalidMessage, setExpirationInvalidMessage] = useState(null)
  const [cvvInvalidMessage, setCvvInvalidMessage] = useState(null)
  const [cardType, setCardType] = useState()

  const [cardTypes, setCardTypes] = useState([{
    "type": "amex",
    "validLengths": [15],
    "regex": ['^3[47][0-9]{13}$'],
    "firstNumber": [3],
    "icon": amex,
    "iconAlt": "Amex Logo"
  }, {
    "type": "visa",
    "validLengths": [16],
    "regex": ['^4[0-9]{12}(?:[0-9]{3})?$'],
    "firstNumber": [4],
    "icon": visa,
    "iconAlt": "Visa Logo"
  }, {
    "type": "discover",
    "validLengths": [16, 17, 18, 19],
    "regex": ['^6011[0-9]{12}[0-9]*$', '^62[24568][0-9]{13}[0-9]*$', '^6[45][0-9]{14}[0-9]*$'],
    "firstNumber": [6],
    "icon": discover,
    "iconAlt": "Discover Logo"
  }, {
    "type": "mastercard",
    "validLengths": [16],
    "regex": ['^5[1-5][0-9]{14}$', '^2[2-7][0-9]{14}$'],
    "firstNumber": [2, 5],
    "icon": mastercard,
    "iconAlt": "Mastercard Logo"
  }])

  const [card, setCard] = useState(getCard(props.selectedCard));

  const cardNumberComponent = useRef(null);
  const cardCvvComponent = useRef(null);
  const [cardNumberInput, setCardNumberInput] = useState(null);
  const [cardCvvInput, setCardCvvInput] = useState(null);

  const updateCardProperty = (data, property) => {
    return setCard(card => ({
      ...card,
      [property]: data
    }))
  }

  const saveDisabled = (card) => {
    let requiredFields = ['number', 'chargeAmount', 'name', 'expirationDate', 'cvv']
    if (separateBilling) {
      requiredFields = ['number', 'chargeAmount', 'name', 'expirationDate', 'cvv', 'address1', 'city', 'state', 'zipcode']
    }
    if (props.selectedCard) {
      requiredFields = ['number', 'chargeAmount', 'name']
    }

    // reject any empty strings
    const filled = (value) => {
      return value && value.length > 0
    }

    // gets required fields only
    const filteredCardFields = R.pick(requiredFields, R.pickBy(filled, card));

    // if length of card data required fields does not match length of required fields, card cannot be saved
    const missingRequired = R.keysIn(filteredCardFields).length != requiredFields.length

    // make sure changes have been made
    const changesMade = props.selectedCard ? R.equals(props.selectedCard, card) : false

    return missingRequired || changesMade || cvvInvalidMessage || cvvInvalidMessage || expirationInvalidMessage
  }

  const saveCard = (card) => {
    let invalidToast = document.querySelector("#payment_add-error-invalid");
    let cyberSourceFirstErrorToast = document.querySelector("#payment_add-error-cybersource-fail");
    let cyberSourceDownErrorToast = document.querySelector("#payment_add-error-cybersource-down");

    if (!props.selectedCard) {
      validateCardEntry(card.number, card)

      if (csErrors === 1) {
        cyberSourceFirstErrorToast.toastIsOpen = true;
        return;
      } else if (csErrors > 1) {
        cyberSourceDownErrorToast.toastIsOpen = true;
        return;
      }

      if (card.invalid == true) {
        invalidToast.toastIsOpen = true;
        return;
      }

      const t = getCardType(card.number)
      card.type = t
    }

    window.dataLayer.push({
      'event': 'saveCard',
      'amount': card.chargeAmount
    });

    return props.selectedCard ? props.onUpdateCard(card) : props.onSaveCard(card)
  }

  const getCardNumber = (number) => {
    const formatSpaces = (str) => {
      return str.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()
    }
    const maskNumber = (str) => {
      const regex = /\d{4}(?= \d{1})/g;
      const substr = "****";
      return str.replace(regex, substr)
    }
    if (number) {
      return props.selectedCard ? maskNumber(number) : formatSpaces(number)
    }
  }

  const formatDateString = (data) => {
    return data && data.replace(
      /[^0-9]/g, '' // To allow only numbers
    ).replace(
      /^([2-9])$/g, '0$1' // To handle 3 > 03
    ).replace(
      /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
    ).replace(
      /^0{1,}/g, '0' // To handle 00 > 0
    ).replace(
      /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
    );
  }

  const getExpirationDate = (data) => {
    return data && formatDateString(data)
  }

  const validateExpDate = (data) => {
    setExpirationInvalidMessage(null)
    if (data) {
      const expDate = moment(data, "MM/YY");
      var isAfter = moment(expDate).isSameOrAfter(moment(), 'day');
      !isAfter && setExpirationInvalidMessage('Invalid expiration date')
      !data.match(/^(0[1-9]|1[0-2])\/([0-9]{2})$/g) && setExpirationInvalidMessage('Invalid expiration date - use format MM/YY')
    }
  }

  const validateCVV = (data) => {
    const type = getCardCvvComponent().getAttribute('data-card-type')
    setCvvInvalidMessage(null)
    if (data) {
      !data.match("^[0-9]+$") && setCvvInvalidMessage('Invalid CVV')
      if (type === 'discover' || type === 'visa' || type === 'mastercard') {
        data.length !== 3 && setCvvInvalidMessage('Invalid CVV')
      }
      if (type === 'amex') {
        data.length !== 4 && setCvvInvalidMessage('Invalid CVV')
      }
    }
  }

  const validLength = (data, type) => {
    if (type && data) {
      const card = R.filter((card) => {
        return card.type === type
      }, cardTypes)
      const validLengths = card.length && card[0].validLengths
      const length = stripSpaces(data).length
      return length && validLengths && R.contains(length, validLengths)
    }
  }

  const stripSpaces = (data) => {
    return data && data.replace(/ /g, '')
  }

  const numberMatch = (card, data) => {
    const getMatch = (number) => {
      return number === parseInt(data.charAt(0))
    }
    const match = R.filter(getMatch, card.firstNumber);
    return match && match[0]
  }

  const getCardType = (data) => {
    let firstNumberMatch = R.filter((card) => {
      return numberMatch(card, data)
    }, cardTypes)
    return firstNumberMatch && firstNumberMatch[0] && firstNumberMatch[0].type
  }

  const validateCardEntry = (data, card) => {
    card && (card.invalid = false)
    // card && console.log(card)
    setNumberInvalidMessage(null)
    const entryStripped = stripSpaces(data)

    const type = getCardType(data)
    if (entryStripped) {
      if (type) {
        !validLength(entryStripped, type) && setNumberInvalidMessage('Sorry, that card number is invalid.')
        !validLength(entryStripped, type) &&
          (card && (card.invalid = true))

      } else {
        setNumberInvalidMessage('Unfortunately, we do not accept this type of credit card.')
        card && (card.invalid = true)

      }
      isNaN(entryStripped) && setNumberInvalidMessage('Sorry, that card number is invalid.')
      isNaN(entryStripped) && card && (card.invalid = true)

    }
  }

  const cardIcon = (cardType, returnAlt = false) => {
    const getLogo = () => {
      let type = R.find(R.propEq('type', cardType), cardTypes)
      return !returnAlt ? type.icon : type.iconAlt
    }
    return cardType && getLogo()
  }

  const getCardNumberComponent = () => document.querySelector('lobster-input[data-label="Card Number"]')
  const getCardNumberInput = () => {
    const cardNumberComponent = getCardNumberComponent()
    return cardNumberComponent && cardNumberComponent.querySelector('input')
  }
  const getCardCvvComponent = () => document.querySelector('lobster-input[data-label="CVV"]')
  const getCardCvvInput = () => {
    const cardCvvInput = getCardCvvComponent()
    return cardCvvInput && cardCvvInput.querySelector('input')
  }

  const getSeparateBilling = () => {
    return props.selectedCard ? card.separateBilling : separateBilling
  }

  const setupBlurListeners = (cardNumber, cvv) => {

    const preventNonNumericalInput = (e) => {
      e = e || window.event;
      var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
      var charStr = String.fromCharCode(charCode);

      if (!charStr.match(/^[0-9]+$/))
        e.preventDefault();
    }

    // validation that requires type of card can only happen on blur
    // restrict card number pattern for numeric only
    if (!cardNumber.getAttribute('blurListener')) {
      cardNumber.setAttribute('blurListener', 'true')
      cardNumber.addEventListener('blur', (e) => validateCardEntry(e.currentTarget.value))
      cardNumber.addEventListener('keypress', (e) => preventNonNumericalInput(e))
    }

    // validation that requires type of card can only happen on blur
    if (!cvv.getAttribute('blurListener')) {
      cvv.setAttribute('blurListener', 'true');
      cvv.addEventListener('blur', (e) => validateCVV(e.currentTarget.value));
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setupBlurListeners(cardNumberComponent.current.querySelector('input'), cardCvvComponent.current.querySelector('input'))
    }, 200)
  }, [cardNumberComponent, cardCvvComponent])


  return (
    <PaymentDrawerTwoColumnLayout
      left={<div>
        <LobsterInput
          ref={cardNumberComponent}
          className="card"
          dataLabel="Card Number"
          dataRequired="true"
          setter={(data) => {
            const type = getCardType(data)
            type ? setCardType(type) : setCardType(null)
            return updateCardProperty(data, 'number')
          }}
          getter={() => getCardNumber(card.number)}
          dataDisabled={props.selectedCard}
          dataInvalid={card.invalid}
          data-max="23">
          <LobsterTooltipWrapper
            slot="lobster-tooltip"
            showOn="hover"
            horizontalPosition="right"
            verticalPosition="right">
            <LobsterButton
              slot="lobster-tooltip-trigger"
              data="info_outline"
              dataType="icon"
              dataVariation="tertiary"
              dataSize="small">
            </LobsterButton>
            <LobsterTooltip
              data-type="condensed"
              id="input-label-tooltip">
              <p>{props.content.tooltip}</p>
            </LobsterTooltip>
          </LobsterTooltipWrapper>
          <LobsterLayout
            slot="lobster-tooltip"
            className="payments_card_icon--wrapper"
            dataAlign="center"
            dataJustify="center">
            {cardType && <img src={cardIcon(cardType)} alt={cardIcon(cardType, true)} />}
          </LobsterLayout>
          <LobsterErrorMessage
            dataInteracted={numberInvalidMessage}
            data={numberInvalidMessage}></LobsterErrorMessage>
        </LobsterInput>

        <LobsterLayout
          className="lobster_layout_margin-bottom--sm lobster_layout_margin-top--xxs"
          dataAlign="center">
          <LobsterIcon
            className="lobster_layout_color--primary lobster_layout_margin-right--xxs"
            data="lock_outline"
            dataSize="xsmall"></LobsterIcon>
          <div className="typo_body--xsmall">Your payment info will be stored securely.</div>
        </LobsterLayout>
        <LobsterInput
          dataLabel="Name on Card"
          dataRequired="true"
          className="lobster_layout_margin-bottom--sm"
          setter={(data) => updateCardProperty(data, 'name')}
          getter={() => card.name}></LobsterInput>
        <LobsterLayout className="row_2up">
          <LobsterInput
            dataLabel="Expiration Date"
            dataRequired="true"
            className="lobster_layout_margin-bottom--sm"
            setter={(data) => {
              updateCardProperty(data, 'expirationDate')
              validateExpDate(card.expirationDate)
            }}
            dataInvalid={expirationInvalidMessage}
            dataErrors={expirationInvalidMessage ? [{ 'message': expirationInvalidMessage }] : []}
            getter={() => getExpirationDate(card.expirationDate)}
            dataDisabled={props.selectedCard}
          ></LobsterInput>
          <LobsterInput
            ref={cardCvvComponent}
            dataLabel="CVV"
            dataRequired="true"
            className="lobster_layout_margin-bottom--sm"
            setter={(data) => {
              updateCardProperty(data, 'cvv')
            }}
            getter={() => card.cvv}
            dataInvalid={cvvInvalidMessage}
            dataErrors={cvvInvalidMessage ? [{ 'message': cvvInvalidMessage }] : []}
            dataCardType={cardType}
            dataDisabled={props.selectedCard}
          >
          </LobsterInput>

        </LobsterLayout>
        <div className="lobster_layout_margin-bottom--sm">
          <LobsterCheckbox
            className={!getSeparateBilling() && `checked`}
            dataLabel="Billing address same as origin address"
            getter={() => !getSeparateBilling()}
            setter={(data) => {
              setSeparateBilling(!data)
              updateCardProperty(!data, 'separateBilling')
            }}></LobsterCheckbox>
        </div>
        {getSeparateBilling() && <div>
          <LobsterInput
            dataLabel="Address Line 1"
            dataRequired="true"
            className="lobster_layout_margin-bottom--sm"
            setter={(data) => updateCardProperty(data, 'address1')}
            getter={() => card.address1}></LobsterInput>
          <LobsterInput
            dataLabel="Address Line 2"
            className="lobster_layout_margin-bottom--sm"
            setter={(data) => updateCardProperty(data, 'address2')}
            getter={() => card.address2}></LobsterInput>
          <LobsterInput
            dataLabel="City"
            dataRequired="true"
            className="lobster_layout_margin-bottom--sm"
            setter={(data) => updateCardProperty(data, 'city')}
            getter={() => card.city}></LobsterInput>
          <LobsterLayout className="row_2up">
            <LobsterInput
              dataLabel="State"
              dataRequired="true"
              className="lobster_layout_margin-bottom--sm"
              setter={(data) => updateCardProperty(data, 'state')}
              getter={() => card.state}></LobsterInput>
            <LobsterInput
              dataLabel="Zipcode"
              dataRequired="true"
              className="lobster_layout_margin-bottom--sm"
              setter={(data) => updateCardProperty(data, 'zipcode')}
              getter={() => card.zipcode}></LobsterInput>
          </LobsterLayout>
        </div>}
        <LobsterInput
          dataLabel="Charge to this card"
          dataRequired="true"
          className="lobster_layout_margin-bottom--sm"
          setter={(data) => updateCardProperty(data, 'chargeAmount')}
          getter={() => card.chargeAmount}></LobsterInput>
        <LobsterButton
          data={props.content.ctaText}
          dataType="button"
          dataVariation="secondary"
          className="lobster_layout_margin-bottom--md button_full"
          setter={() => saveCard(card)}
          dataDisabled={saveDisabled(card)}
        ></LobsterButton>
      </div >}></PaymentDrawerTwoColumnLayout >
  );

}

export default PaymentsAddPayment;
