import checklistContentMiddleware from 'middleware/wordpress/checklistContent'

// Reducer
export const checklistContent = (state = {}, action) => {

  //Clears Page Data
  if (action.type === 'CLEAR_CHECKLIST_CONTENT') {
    return {
      data: null,
      error: false,
    }
  }

  // Sets Page Data
  if (action.type === 'STORE_CHECKLIST_CONTENT') {
    return {
      data: checklistContentMiddleware(action.data),
      error: false,
    }
  }

  // Handles Error
  if (action.type === 'CHECKLIST_CONTENT_ERROR') {
    return {
      data: checklistContentMiddleware(action.data),
      error: true
    }
  }

  return state
}
