import React from 'react';
import { LobsterLayout, LobsterDivider } from '@hlk/lobster-react/dist';

function PaymentDrawerTwoColumnLayout(props) {

  return (
    <LobsterLayout
      className="payment_drawer_two_column_layout"
      dataDirection="column"
      directionLg="row">
      <div className="payment_drawer_two_column_layout--column">
        {props.left}
      </div>
      {props.right && <LobsterDivider class="divider_flip-gt-md"></LobsterDivider>}
      <div className="payment_drawer_two_column_layout--column">
        {props.right}
      </div>
    </LobsterLayout>
  );

}

export default PaymentDrawerTwoColumnLayout;
