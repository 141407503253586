import React, { useState } from 'react';
import { LobsterLayout } from '@hlk/lobster-react/dist';
import * as R from 'ramda';

function serviceItem(service, small) {
  return <LobsterLayout
    key={service.type}
    dataAlign="center"
    dataJustify="space-between"
    className="lobster_layout_margin-bottom--xs">
    <div className={`typo_body--bold ${small ? `typo_body--xsmall` : ``}`}>{service.type}</div>
    <div className={small ? `typo_body--xsmall` : ``}>{service.description}</div>
  </LobsterLayout>
}

function ServicesIncludedList(props) {

  const [services] = useState([{
    type: 'Full value protection',
    description: 'Included'
  }, {
    type: 'Full Packing',
    description: 'Included'
  }, {
    type: 'Mini Storage pick-up',
    description: 'Included'
  }, {
    type: '3rd party crating',
    description: '$300'
  }])

  return (<div>
    {R.map(service => serviceItem(service, props.small), services)}
  </div>
  );
}

export default ServicesIncludedList;
