export default (response) => {
  if (response && response.acf) {
    return response.acf
  } else {
    return {
      items_widget_title: "Your Items",
      items_widget_tooltip: "High value inventory can be edited up until your load date.",
      items_widget_zero_state_copy: "It looks like you haven’t added any high value items yet.",
      items_widget_zero_state_cta_label: "Add High Value Items",
      page_content: "Adding or changing items in this list doesn't affect your estimate.<br />< br />Your driver will have a hard copy of this form for you to sign on load day.",
      page_title: "High Value Inventory",
      video: {
        video_id: "dGiQaabX3_o",
        video_title: "Untitled FVP"
      },
      video_heading: "Learn how valuation works",
      video_id: "dGiQaabX3_o",
      video_title: "Untitled FVP"
    }
  }
}
