import React  from 'react';
import { LobsterLayout, LobsterProgressIndicator, LobsterHeading } from '@hlk/lobster-react/dist';


function ChecklistProgress(props) {

  function percentComplete(completed, count) {
    return Math.round((completed / count) * 100)
  };

  return (
    <div>
      <LobsterLayout dataJustify="space-between" dataAlign="flex-end">
        <LobsterHeading display="display-3">
          <h2 className="typo_body-weight--regular">All Tasks</h2>
        </LobsterHeading>
        <div><p className="typo_body--small no-margins">{props.completedTasks} of {props.taskCount} complete</p></div>
      </LobsterLayout>
      <LobsterProgressIndicator
        showPercentage="false"
        type="bar"
        animationLoop="false"
        percentComplete={percentComplete(props.completedTasks, props.taskCount)}
        progressBackground="primary"
        trackBackground="gray-100">
      </LobsterProgressIndicator>
    </div>
  );

}

export default ChecklistProgress;
