import {
  getChecklistItems,
  putChecklistItem,
  postChecklistItem,
  deleteChecklistItem
} from 'lib/api'

import { trackingId } from 'lib/utils'

import { outgoingChecklistItemMiddleware } from 'middleware/api/checklist'

export const CLEAR_CHECKLIST_ITEMS = 'CLEAR_CHECKLIST_ITEMS'
export function clearChecklistItems() {
  return {
    type: CLEAR_CHECKLIST_ITEMS,
  }
}

export const STORE_CHECKLIST_ITEMS = 'STORE_CHECKLIST_ITEMS'
export function storeChecklistItems(data) {
  return {
    type: STORE_CHECKLIST_ITEMS,
    data,
  }
}

export const CHECKLIST_ITEMS_ERROR = 'CHECKLIST_ITEMS_ERROR'
export function ChecklistItemsError(data) {
  return {
    type: CHECKLIST_ITEMS_ERROR,
    data,
  }
}

export function fetchChecklistItems(filters) {
  return function (dispatch, getState) {
    dispatch(clearChecklistItems())

    const id = trackingId(getState())
    return getChecklistItems(id, filters)
      .then(
        (response) => {
          return dispatch(storeChecklistItems(response.data))
        }
      )
      .catch(
        (err) => {
          return dispatch(ChecklistItemsError(null))
        }
      )
  }
}

export function createChecklistItem(data) {
  return function (dispatch, getState) {
    dispatch(clearChecklistItems())
    let successToast = document.querySelector("#checklist_add-success");
    let apiErrorToast = document.querySelector("#checklist_api-error");

    const id = trackingId(getState())
    const outgoing = outgoingChecklistItemMiddleware(data)

    return postChecklistItem(id, outgoing)
      .then(
        (response) => {
          successToast.toastIsOpen = true;
          return dispatch(fetchChecklistItems())
        }
      )
      .catch(
        (err) => {
          apiErrorToast.toastIsOpen = true;
          return dispatch(fetchChecklistItems())
        }
      )
  }
}

export function updateChecklistItem(data) {
  return function (dispatch, getState) {
    dispatch(clearChecklistItems())

    const taskId = data.id
    const outgoing = outgoingChecklistItemMiddleware(data)
    return putChecklistItem(taskId, outgoing)
      .then(
        (response) => {
          return dispatch(fetchChecklistItems())
        }
      )
      .catch(
        (err) => {
          return dispatch(ChecklistItemsError(null))
        }
      )
  }
}

export function removeChecklistItem(id) {
  return function (dispatch) {
    dispatch(clearChecklistItems())

    return deleteChecklistItem(id)
      .then(
        (response) => {
          return dispatch(fetchChecklistItems())
        }
      )
      .catch(
        (err) => {
          return dispatch(ChecklistItemsError(null))
        }
      )
  }
}
