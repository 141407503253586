// Reducer
export const eacceptances = (state = {}, action) => {

  //Clears Checklist Data
  if (action.type === 'CLEAR_EACCEPTANCES') {
    return {
      data: null,
      error: false,
    }
  }

  // Sets Checklist Data
  if (action.type === 'STORE_EACCEPTANCES') {
    return {
      data: action.data,
      error: false,
    }
  }

  // Handles Error
  if (action.type === 'EACCEPTANCES_ERROR') {
    return {
      data: null,
      error: true
    }
  }

  return state
}
