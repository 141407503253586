import { getContacts } from 'lib/api'
import { trackingId } from 'lib/utils'

export const CLEAR_CONTACTS = 'CLEAR_CONTACTS'
export function clearContacts() {
  return {
    type: CLEAR_CONTACTS,
  }
}

export const STORE_CONTACTS = 'STORE_CONTACTS'
export function storeContacts(data) {
  return {
    type: STORE_CONTACTS,
    data,
  }
}

export const CONTACTS_ERROR = 'CONTACTS_ERROR'
export function contactsError(data) {
  return {
    type: CONTACTS_ERROR,
    data,
  }
}

export function fetchContacts(data) {
  return function (dispatch, getState) {
    dispatch(clearContacts())
    const id = trackingId(getState())

    return getContacts(id)
      .then(
        (response) => {
          return dispatch(storeContacts(response.data))
        }
      )
      .catch(
        (err) => {
          console.log("Contacts fetch error: ", err)
          return dispatch(contactsError(null))
        }
      )
  }
}
