import * as R from 'ramda'

import {
  getHVIs,
  postHVIs,
  getHviPdf,
} from 'lib/api'

import { trackingId, fullProfile, userQuotes } from 'lib/utils'
import { hvisMiddlewareOutgoing } from 'middleware/api/hvis'

export const CLEAR_HVIS = 'CLEAR_HVIS'
export function clearHVIs() {
  return {
    type: CLEAR_HVIS,
  }
}

export const STORE_HVIS = 'STORE_HVIS'
export function storeHVIs(data) {
  return {
    type: STORE_HVIS,
    data,
  }
}

export const HVIS_ERROR = 'HVIS_ERROR'
export function hvisError(data) {
  return {
    type: HVIS_ERROR,
    data,
  }
}

export function fetchHVIs(filters) {
  return function (dispatch, getState) {
    const userProfile = fullProfile(getState())
    const id = trackingId(getState())
    const quotes = userQuotes(getState())
    const fullType = R.find(R.propEq('type', 'Full-Service'))(quotes);

    const formatted = {
      agentNumber: R.prop('agentNumber', fullType),
      companyCode: R.prop('companyCode', fullType),
      coverageCode: "LISTED",
      estimateNumber: R.prop("estimateNumber", fullType),
      firstName: R.path(['customer', 'firstName'], userProfile),
      lastName: R.path(['customer', 'lastName'], userProfile),
      highValueInventoryItems: [],
      id: R.prop('id', fullType),
      orderNum: R.path(['orderId', 'orderNum'], fullType),
      orderYear: R.path(['orderId', 'orderYear'], fullType),
      originCity: R.path(['originAddress', 'city'], fullType),
      originState: R.path(['originAddress', 'state'], fullType),
      quoteId: R.prop('id', fullType),
      trackingId: id
    }

    dispatch(clearHVIs())
    return getHVIs(id, filters)
      .then(
        (response) => {
          return dispatch(storeHVIs(response.data))
        }
      )
      .catch(
        (err) => {
          return dispatch(hvisError(formatted))
        }
      )
  }
}


export function createHVI(data, editedItems) {
  data.highValueInventoryItems = editedItems

  return function (dispatch, getState) {
    dispatch(clearHVIs())
    const userProfile = fullProfile(getState())
    const id = trackingId(getState())
    const quotes = userQuotes(getState())
    const fullType = R.find(R.propEq('type', 'Full-Service'))(quotes);

    const outgoing = R.prop('highValueInventoryItems', data)

    const formatted = {
      agentNumber: R.prop('agentNumber', fullType),
      companyCode: R.prop('companyCode', fullType),
      coverageCode: "LISTED",
      estimateNumber: R.prop("estimateNumber", fullType),
      firstName: R.path(['customer', 'firstName'], userProfile),
      lastName: R.path(['customer', 'lastName'], userProfile),
      highValueInventoryItems: outgoing,
      id: R.prop('id', fullType),
      orderNum: R.path(['orderId', 'orderNum'], fullType),
      orderYear: R.path(['orderId', 'orderYear'], fullType),
      originCity: R.path(['originAddress', 'city'], fullType),
      originState: R.path(['originAddress', 'state'], fullType),
      quoteId: R.prop('id', fullType),
      trackingId: id
    }


    return postHVIs(id, formatted)
    .then(
      (response) => {
        return dispatch(fetchHVIs())
      }
    )
    .catch(
      (err) => {
        console.log(err)
        // return dispatch(hvisError(null))
      }
    )
  }
}

export function fetchHviPdf() {
  return function (dispatch, getState) {

    // dispatch(clearHVIs())
    const id = trackingId(getState())

  const options = {
    responseType: 'arraybuffer',
    headers: {
      'Accept': 'application/pdf'
    },
  };

    return getHviPdf(id, options).then(response => {
        const file = new Blob(
          [response.data],
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
    })


    .catch(error => {
      console.log(error);
  });
  }
}
