import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { LobsterLayout, LobsterHeading, LobsterIcon, LobsterButton } from '@hlk/lobster-react/dist';

// TODO: Add Datalayer back:
// window.dataLayer.push({'event': (!props.item.completed ? 'completedListItem' : 'markedListItemIncomplete')})

import {
  updateChecklistItem,
  removeChecklistItem,
} from 'store/actions/api/checklistItems';

import ChecklistCompleteButton from 'components/ChecklistCompleteButton';

function editButtons(props, editDelete, editComplete, getChecklistProperty) {
  return <LobsterLayout
    dataAlign="flex-end"
    dataJustify="space-between"
    className="hidden_lt-sm">
    <LobsterButton
      data="Delete"
      dataType="text"
      dataVariation="tertiary"
      setter={editDelete}
      className="typo_transform--uppercase lobster_layout_margin-right--md hidden_lt-sm">
      <LobsterIcon
        data="delete"
        dataSize="small"
        className="lobster_layout_margin-right--xxs"></LobsterIcon>
    </LobsterButton>
    {props.item && <ChecklistCompleteButton
      isComplete={props.itemCompleteStatus}
      displayOn="desktop"
      onFieldEdit={editComplete}></ChecklistCompleteButton>}
  </LobsterLayout>
}

function addButton(props) {
  return <LobsterButton
    data="Add New"
    dataType="text"
    dataVariation="tertiary"
    className="typo_transform--uppercase"
    setter={props.onAddItem}>
    <LobsterIcon
      data="add"
      dataSize="small"
      className="lobster_layout_margin-right--xxs"></LobsterIcon>
  </LobsterButton>
}

function ChecklistHeader(props) {
  const dispatch = useDispatch();
  const isError = useSelector(state => R.path(['checklistItems', 'error'], state))

  const [checklist, setChecklist] = useState({});

  function editDelete() {
    dispatch(removeChecklistItem(getChecklistProperty('id')))
    window.dataLayer.push({ 'event': 'deleteListItem' })
    let successToast = document.querySelector("#checklist_delete-success");
    successToast.toastIsOpen = true;
    props.onBack()
  }

  function editComplete(data) {

    props.onItemCompleted && props.onItemCompleted(data)

    const originalProperty = (property) => R.path(['item', property], props)
    let apiErrorToast = document.querySelector("#checklist_api-error");

    if (isError) {
      apiErrorToast.toastIsOpen = true
      return
    }
    setChecklistProperty('completed')(data)
    const updates = {
      id: originalProperty('id'),
      title: originalProperty('title'),
      date: originalProperty('date'),
      notes: originalProperty('notes'),
      completed: data
    }
    dispatch(updateChecklistItem(updates))
    window.dataLayer.push({
      'event': `${checklist.completed ? 'markDetailItemIncomplete' : 'completeDetailItem'}`
    });
  }

  function getChecklistProperty(property) {
    const p = R.prop(property, checklist)
    return !R.isNil(p) ? p : R.path(['item', property], props);
  }

  function setChecklistProperty(property) {
    return function (data) {
      const l = R.lensProp(property);
      setChecklist(R.set(l, data, checklist));
    }
  }

  return ([
    <LobsterLayout
      key="checklist-draggable"
      slot="drawer-draggable-button"
      dataAlign="center"
      dataJustify="center">
      <span className="checklist_handle"></span>
    </LobsterLayout>,
    <LobsterLayout
      key="checklist-peek"
      slot="drawer-peek-area"
      dataAlign="center"
      dataJustify="space-between"
      className="checklist_grip lobster_layout_padding-bottom--sm lobster_layout_padding-horizontal--lg">
      <LobsterLayout
        dataAlign="flex-end"
        dataJustify="space-between">
        {props.showBackButton &&
          <LobsterLayout dataAlign="center">
            <LobsterButton
              dataType="icon"
              dataVariation="tertiary"
              data="keyboard_arrow_left"
              setter={() => props.onBack()}
              className="lobster_layout_margin-right--sm"></LobsterButton>
            <LobsterHeading
              display="display-3" className="lobster_layout_margin-right--xxs">
              <h1>{props.checklistTitle}</h1>
            </LobsterHeading>
          </LobsterLayout>
        }

        {!props.showBackButton &&
          <LobsterLayout
            dataAlign="flex-end">
            <LobsterHeading
              display="display-3" className="lobster_layout_margin-right--xxs">
              <h1>{props.checklistTitle}</h1>
            </LobsterHeading>
            {props.showTaskCount &&
              <div className="typo_body--small checklist_item-count">{props.taskCount} items</div>
            }
          </LobsterLayout>
        }
      </LobsterLayout>
      {props.showAddNewButton && addButton(props)}
      {props.showEditButtons && editButtons(props, editDelete, editComplete, getChecklistProperty)}
    </LobsterLayout>,
    <div key="checklist-back">
      {(props.showBackButton && props.item) &&
        <LobsterLayout
          dataAlign="center"
          dataJustify="space-between"
          className="lobster_layout_padding-bottom--sm  lobster_layout_padding-top--sm lobster_layout_padding-horizontal--lg hidden_gt-sm">

          <ChecklistCompleteButton
            isComplete={props.itemCompleteStatus}
            displayOn="mobile"
            onFieldEdit={editComplete}></ChecklistCompleteButton>
          <LobsterButton
            data="Delete"
            dataType="text"
            dataVariation="tertiary"
            displayOn="mobile"
            setter={editDelete}
            className="typo_transform--uppercase lobster_layout_margin-right--md hidden_gt-sm">
            <LobsterIcon
              data="delete"
              dataSize="small"
              className="lobster_layout_margin-right--xxs"></LobsterIcon>
          </LobsterButton>
        </LobsterLayout>
      }
    </div>
  ]);
}

export default ChecklistHeader;
