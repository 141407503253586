import React from 'react';
import { LobsterLayout, LobsterHeading, LobsterButton, LobsterTooltipWrapper, LobsterTooltip, LobsterSheetTrigger } from '@hlk/lobster-react/dist';
import SavedCard from 'components/SavedCard';
import { useSelector, useDispatch } from 'react-redux'

import * as R from 'ramda';
import moment from 'moment'


function PaymentsWidget(props) {
  const userProfile = useSelector(state => R.path(['userProfile', 'data'], state))
  const cards = useSelector(state => R.path(['ccInfo', 'data', 'cards'], state))

  let loadDate = ''
  userProfile.quotes && R.map(quote => {
    if (quote.type === "Full-Service") {
      loadDate = quote.loadDay
    }
  }, userProfile.quotes)

  // Determine of load date is within two days
  let today = moment();
  let two_days = today.clone().add(3, 'days').startOf('day');

  function isWithinTwoDays(momentDate) {
    return momentDate.isBefore(two_days);
  }

  if (!props.paymentWidgetContent) return null;
  return (
    <LobsterLayout
      width="100"
      widthSm="50"
      dataDirection="column"
      className="lobster_layout_padding-vertical-sm">
      <LobsterLayout
        dataAlign="center"
        dataJustify="space-between">

        <LobsterLayout dataAlign="center">
          <LobsterHeading display="display-3">
            <h2>Payment</h2>
          </LobsterHeading>
          <LobsterTooltipWrapper
            showOn="hover"
            horizontalPosition="right"
            verticalPosition="top">
            <LobsterButton
              slot="lobster-tooltip-trigger"
              data="info_outline"
              dataType="icon"
              dataSize="xsmall"
              dataAriaLabel="Your Items Info"
              dataVariation="quarternary"
              onMouseEnter={() => window.dataLayer.push({ 'event': 'toolTip', 'tipName': 'Your Items' })}>
            </LobsterButton>
            <LobsterTooltip
              slot="lobster-tooltip"
              id="items-description">
              <p>{props.paymentWidgetContent.heading_tooltip}</p>
            </LobsterTooltip>
          </LobsterTooltipWrapper>
        </LobsterLayout>
      </LobsterLayout>
      <div className="widget widget--bordered">
      {cards && cards.length && cards.map((card, idx) => {
        return <SavedCard key={idx} number={card.lastFourCardNumber} chargeAmount={card.amount}></SavedCard>
      })}
      </div>
    </LobsterLayout>
  );

}

export default PaymentsWidget;
