import axios from 'axios'
import Keycloak from "keycloak-js";
import { wpUrl } from '../lib/wp';

// Wordpress
// -------------------------------------

export const wp = axios.create({
  baseURL: wpUrl()
});


// Requests


// Unigroup API
// -------------------------------------

export const keycloak = Keycloak(`/keycloak-${process.env.KEYCLOAK_ENV}-${process.env.THEME}.json`)
export const uni = axios.create({
  baseURL: 'https://stgcustomerportalapi.cloud1.unigroup.com/',
});

uni.interceptors.request.use(
  async (config) => {
    try {
      await keycloak.updateToken(5);
      config.headers.Authorization = `Bearer ${keycloak.token}`;
    } catch {
      keycloak.login();
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Logout
export const logout = () => {
  keycloak.logout({ redirectUri: window.location.origin });
};

// User Profile
export const getUserProfile = () => uni.get('/moveinfo')

// E Acceptance
export const getEAcceptance = (quoteId) => uni.get(`quotes/${quoteId}/eacceptances`)
export const getSpecificForm = (formId, data, formType) => uni.get(`/forms/eacceptance/${formId}?formType=${formType}`, data)
export const getCCKey = () => uni.get('/creditcards/generatekey')
export const getCCInfo = (trackingId, quoteId, formId) => uni.get(`/creditcards/opportunity/${trackingId}/quote/${quoteId}/forms/${formId}`)
export const postCCInfo = (trackingId, formId, data) => uni.post(`/creditcards/opportunity/${trackingId}/forms/${formId}`, data)
export const putCCInfo = (trackingId, formId, data) => uni.put(`/creditcards/opportunity/${trackingId}/forms/${formId}`, data)
export const deleteCCInfo = (trackingId, formId, data) => uni.delete(`/creditcards/opportunity/${trackingId}/forms/${formId}`, { data })
export const putBooking = (quoteId, formId) => uni.put(`/quotes/${quoteId}/forms/${formId}/book`)

// Timeline
export const getTimeline = (trackingId) => uni.get(`/moveinfo/${trackingId}/timeline`)
export const getTimelineContentResidential = () => wp.get('/acf/v3/options/residential-milestones')
export const getTimelineContentCommercial = () => wp.get('/acf/v3/options/commercial-milestones')

// HVIs
export const getHVIs = (trackingId) => uni.get(`/moveinfo/${trackingId}/hvis`)
export const postHVIs = (trackingId, data) => uni.post(`/moveinfo/${trackingId}/hvis`, data)
export const getHviPdf = (trackingId, data) => uni.get(`/forms/hvi/${trackingId}`, data)

// Contacts
export const getContacts = (trackingId) => uni.get(`/moveinfo/${trackingId}/agent-contacts`)

// Checklist
export const getChecklistItems = (trackingId) => uni.get(`/moveinfo/${trackingId}/tasks`)
export const postChecklistItem = (trackingId, data) => uni.post(`/moveinfo/${trackingId}/tasks`, data)
export const putChecklistItem = (taskId, data) => uni.put(`/tasks/${taskId}`, data)
export const deleteChecklistItem = (id) => uni.delete(`/tasks/${id}`)

export const getChecklistContent = () => wp.get
  (`/acf/v3/options/checklist`)
export const putChecklistContent = () => uni.put('/')

// Main Navigation - Residential
export const getResidentialMenu = () => wp.get
  (`/acf/v3/options/residential-menus`)

// Main Navigation - Corporate
export const getCorporateMenu = () => wp.get
  (`/acf/v3/options/commercial-menus`)

// Dashboard
export const getDashboardContent = () => wp.get
  (`/acf/v3/options/dashboard`)

// Contacts
export const getContactsContent = () => wp.get
  (`/acf/v3/options/contacts`)

// HVI
export const getHviContent = () => wp.get
  (`/acf/v3/options/high-value-inventory`)

// Payments
export const getPaymentContent = () => wp.get
  (`/acf/v3/options/payments-and-agreements`)
