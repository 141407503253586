import * as R from 'ramda'
import moment from 'moment'

// Dates
export const toUniDate = (data) => {
  const d = moment(data, "M/D/YYYY")
  return d.isValid() ? d.format("YYYY-MM-DD") : '--/--/--'
}
export const fromUniDate = (data) => {
  const d = moment(data, "YYYY-MM-DD")
  return d.isValid() ? d.format("M/D/YYYY") : '--/--/--'
}

export const trackingId = R.path(['userProfile', 'data', 'trackingId'])
export const customerType = R.path(['userProfile', 'data', 'customer', 'customerType'])
export const userQuotes = R.path(['userProfile', 'data', 'quotes'])
export const eacceptances = R.path(['eacceptances', 'data'])
export const fullProfile = R.path(['userProfile', 'data'])
export const fullServiceQuote = R.compose(R.find(R.propEq('type', 'Full-Service')), userQuotes);
