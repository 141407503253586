import React, { useState } from 'react';


import { LobsterInput, LobsterDatepicker, LobsterTextarea, LobsterButton } from '@hlk/lobster-react/dist';

function ChecklistItemEdit(props) {
  const { onFieldEdit, onSave } = props
  const [invalid, setInvalid] = useState(false)
  const [dateInvalid, setDateInvalid] = useState(false)

  const errors = (invalid) => {
    return invalid ? [{ "message": "This field is required." }] : []
  }

  const isBeforeDueDate = (date) => {
    if (date && date.isBefore(props.item.date)) {
      return true
    }
    return false
  }

  const dateErrors = (invalid) => {
    return invalid ? [{ "message": `Date cannot be before original Due Date of ${props.item.date}` }] : []
  }

  return (
    <div
      className="checklist_item_edit-wrapper">
      {props.item && <div>
        {props.item.custom ? <div>
          <LobsterInput
            className="lobster_layout_margin-bottom--sm"
            dataLabel={props.labelTaskName || `Task Name`}
            getter={() => props.item.title}
            setter={(data) => {
              !data ? setInvalid(true) : setInvalid(false)
              onFieldEdit('title')(data)
            }}
            dataRequired
            dataInvalid={invalid}
            dataErrors={errors(invalid)}></LobsterInput>
        </div> : <div>
            <div className="typo_navigation--secondary lobster_layout_margin-bottom--xs">Task Name</div>
            <div className="lobster_layout_margin-bottom--md">{props.item.title}</div>
            <div className="typo_navigation--secondary lobster_layout_margin-bottom--xs">Description</div>
            <div className="lobster_layout_margin-bottom--md">{props.item.description}</div>
          </div>}

        <LobsterDatepicker
          className="lobster_layout_margin-bottom--md"
          dataLabel={props.labelDueDate || `Due Date`}
          dateFormat="M/D/YYYY"
          setter={(date) => {
            isBeforeDueDate(date) ? setDateInvalid(true) : setDateInvalid(false)
            onFieldEdit('date')(date)
          }}
          getter={() => props.item.date}
          dataInvalid={dateInvalid}
          dataErrors={dateErrors(dateInvalid)}
          iconData="event"
          returnType="moment"></LobsterDatepicker>
        <div className="form_field-helper typo_body--xsmall">{props.item.helper}</div>
        <LobsterTextarea
          className="lobster_layout_margin-bottom--md"
          dataLabel={props.labelNotes}
          getter={() => props.item.notes}
          setter={onFieldEdit('notes')}></LobsterTextarea>
        <LobsterButton
          className="checklist_item_edit-button"
          data={props.labelSaveCta || `Save Changes`}
          dataVariation="secondary"
          setter={onSave}
          dataDisabled={props.buttonDisabled || dateInvalid}></LobsterButton>
      </div>
      }
    </div>
  );

}

export default ChecklistItemEdit;
