import {
  getTimeline,
  getTimelineContentResidential,
  getTimelineContentCommercial,
} from 'lib/api'

import { trackingId } from 'lib/utils'
import { customerType } from 'lib/utils'

export const CLEAR_TIMELINE_CONTENT = 'CLEAR_TIMELINE_CONTENT'
export function clearTimelineContent() {
  return {
    type: CLEAR_TIMELINE_CONTENT,
  }
}

export const STORE_TIMELINE_CONTENT = 'STORE_TIMELINE_CONTENT'
export function storeTimelineContent(status, content) {
  return {
    type: STORE_TIMELINE_CONTENT,
    status,
    content,
  }
}

export const TIMELINE_CONTENT_ERROR = 'TIMELINE_CONTENT_ERROR'
export function timelineContentError(data) {
  return {
    type: TIMELINE_CONTENT_ERROR,
    data,
  }
}

export function fetchTimelineContent(filters) {
  return function (dispatch, getState) {
    dispatch(clearTimelineContent())

    // TODO: check for residential or commercial
    const isResidential = customerType(getState()) == "HHGD COD" ? true : false
    
    const id = trackingId(getState())
    return getTimeline(id)
    .then(
      (response) => {
        const timelineStatus = response.data
        const functionToRun = isResidential ? getTimelineContentResidential() : getTimelineContentCommercial()
        return functionToRun
        .then(
          (response) => {
            // Combine status and content
                const timelineContent = response.data
                return dispatch(storeTimelineContent(timelineStatus, timelineContent))
              }
            )
            .catch(
              (err) => {
                return dispatch(timelineContentError(null))
              }
            )
        }
      )
      .catch(
        (err) => {
          return dispatch(timelineContentError(null))
        }
      )
  }
}
