import flex from "@cybersource/flex-sdk-web";
import moment from 'moment';
import * as R from 'ramda';

// Will need to import csError in order to dispatch and track number of cybersource errors

import {
  getEAcceptance,
  getCCInfo,
  postCCInfo,
  putCCInfo,
  deleteCCInfo,
  putBooking,
  getCCKey,
} from 'lib/api'

import {
  userQuotes,
  trackingId as getTrackingId,
  eacceptances,
  fullProfile,
} from 'lib/utils'

import {
  clearCsErrors,
  incrementCsErrors,
} from './csErrors'


export const CLEAR_EACCEPTANCES = 'CLEAR_EACCEPTANCES'
export function clearEAcceptances() {
  return {
    type: CLEAR_EACCEPTANCES,
  }
}

export const STORE_EACCEPTANCES = 'STORE_EACCEPTANCES'
export function storeEAcceptances(data) {
  return {
    type: STORE_EACCEPTANCES,
    data,
  }
}

export const EACCEPTANCES_ERROR = 'EACCEPTANCES_ERROR'
export function eAcceptancesError(data) {
  return {
    type: EACCEPTANCES_ERROR,
    data,
  }
}

export const CLEAR_CC_INFO= 'CLEAR_CC_INFO'
export function clearCCInfo() {
  return {
    type: CLEAR_CC_INFO,
  }
}

export const STORE_CC_INFO = 'STORE_CC_INFO'
export function storeCCInfo(data) {
  return {
    type: STORE_CC_INFO,
    data,
  }
}

export const CC_INFO_ERROR = 'CC_INFO_ERROR'
export function ccInfoError(data) {
  return {
    type: CC_INFO_ERROR,
    data,
  }
}

export const COMPLETE_BOOKING = 'COMPLETE_BOOKING'
export function bookingCompletion() {
  return {
    type: COMPLETE_BOOKING,
  }
}


export function fetchCCInfo() {
  return function(dispatch, getState) {

    const eacceptanceData = eacceptances(getState())
    const quotes = userQuotes(getState())
    const fullType = R.find(R.propEq('type', 'Full-Service'))(quotes);

    const trackingId = getTrackingId(getState())
    const quoteId = R.prop('id', fullType)

    if (!eacceptanceData) {
      return getEAcceptance(quoteId).then(response => {
        // Store all eacceptances
        dispatch(storeEAcceptances(response.data))

        // Check response for booking completion
        const statuses = R.map(R.prop('status'), response.data)
        const isAccepted = R.equals('Accepted')
        const bookingIsComplete = R.all(isAccepted)(statuses)
        if (bookingIsComplete) {
          dispatch(bookingCompletion())
        }

        // get credit card info
        const ccEAcceptance = R.find(R.propEq('formTypeCode', 'CREDIT_CARD_AUTHORIZATION'), response.data)
        const formId = R.prop('formId', ccEAcceptance)

        return getCCInfo(trackingId, quoteId, formId).then((response) => {
          dispatch(clearCCInfo())
          dispatch(storeCCInfo(response.data))
        }).catch(error => {
          console.log(error)
        })

      }).catch(error => {
        console.log(error);
      });
    }
    // Check state for booking completion
    const statuses = R.map(R.prop('status'), eacceptanceData)
    const isAccepted = R.equals('Accepted')
    const bookingIsComplete = R.all(isAccepted)(statuses)
    if (bookingIsComplete) {
      dispatch(bookingCompletion())
    }

    // get eacceptance from existing state
    const ccEAcceptance = R.find(R.propEq('formTypeCode', 'CREDIT_CARD_AUTHORIZATION'), eacceptanceData)
    const formId = R.prop('formId', ccEAcceptance)

    // get CC info
    return getCCInfo(trackingId, quoteId, formId).then((response) => {
      dispatch(storeCCInfo(response.data))
    }).catch(error => {
      console.log(error)
    })

  }
}

export function createCCInfo(ccData) {
  return async function(dispatch, getState) {
    const successToast = document.querySelector("#payment_add-success");

    // Clear CC info so that we can load new data after post

    const eacceptanceData = eacceptances(getState())
    const moveinfo = fullProfile(getState())
    const quotes = userQuotes(getState())
    const fullType = R.find(R.propEq('type', 'Full-Service'))(quotes);

    const trackingId = getTrackingId(getState())
    const quoteId = R.prop('id', fullType)

    const getType = (typeStr) => {
      const map = {
        'visa': '001',
        'mastercard': '002',
        'amex': '003',
        'discover': '004'
      }
      return R.prop(typeStr, map)
    }

    const handler = (formId, cardInfo) => (data) => {
      if (data.error) {
        // dispatch csError then trigger toast
        console.log("CS ERROR: ", data.error)
        dispatch(incrementCsErrors())
        dispatch(fetchCCInfo())
        return
      } else {
        let userAddress = {
          addressLine1: R.prop('address1', ccData),
          addressLine2: R.prop('address2', ccData),
          city: R.prop('city', ccData),
          state: R.prop('state', ccData),
          postalCode: R.prop('zipcode', ccData),
        }

        const formatted = {
          firstName: R.path(['customer', 'firstName'], moveinfo),
          lastName: R.path(['customer', 'lastName'], moveinfo),
          email: R.path(['customer', 'email'], moveinfo),
          amount: R.prop('chargeAmount', ccData),
          address: userAddress.addressLine1 ? userAddress : R.prop('originAddress', moveinfo),
          token: R.prop('token', data),
          expirationYear: R.prop('expiryYear', cardInfo),
          expirationMonth: R.prop('expiryMonth', cardInfo),
          cardType: R.prop('cardType', cardInfo),
          authCode: R.prop('authCode', moveinfo),
          orderId: R.prop('orderId', fullType),
          agentNumber: R.prop('agentNumber', fullType),
          estimateNumber: R.prop('estimateNumber', fullType),
          companyCode: R.prop('companyCode', fullType),
          securityCode: R.prop('securityCode', fullType),
        }

        postCCInfo(trackingId, formId, formatted).then((result) => {
          dispatch(fetchCCInfo())
          dispatch(clearCsErrors())
          successToast.toastIsOpen = true
        }).catch((error) => {
          console.log(error)
          // dispatch csError here then trigger toast
          dispatch(incrementCsErrors())
          dispatch(fetchCCInfo())
        })
      }
    }

    const addCard = async (formId) => {
      const date = R.prop('expirationDate', ccData)
      const dateObj = moment(date, "MM/YY");
      const key = await getCCKey()
      console.log("Generate Key: ", key)
      const cardNumber = R.prop('number', ccData)
      const cardType = getType(R.prop('type', ccData))
      const expiryMonth = dateObj.format('MM')
      const expiryYear = dateObj.format('YYYY')

      var options = {
        kid: R.path(['data', 'kid'], key),
        keystore: R.prop('data', key),
        cardInfo: {
          cardNumber,
          cardType,
          expiryMonth,
          expiryYear,
        },
        encryptionType: "rsaoaep",
        // production: true // without specifying this tokens are created in test env
      };


      console.log("flex Options: ", options)
      flex.createToken(options, handler(formId, options.cardInfo));
    }

    if (!eacceptanceData) {
      return getEAcceptance(quoteId).then(response => {
        // Store all eacceptances
        dispatch(storeEAcceptances(response.data))

        // get credit card info
        const ccEAcceptance = R.find(R.propEq('formTypeCode', 'CREDIT_CARD_AUTHORIZATION'), response.data)
        const formId = R.prop('formId', ccEAcceptance)

        // Do cybersource
        addCard(formId)

      }).catch(error => {
        console.log(error);
      });
    } else {
      // get eacceptance from existing state
      const ccEAcceptance = R.find(R.propEq('formTypeCode', 'CREDIT_CARD_AUTHORIZATION'), eacceptanceData)
      const formId = R.prop('formId', ccEAcceptance)

      // Do cybersource
      addCard(formId)
    }
  }
}

export function editCCInfo(ccData) {
  return async function(dispatch, getState) {
    const successToast = document.querySelector("#payment_add-success");

    const eacceptanceData = eacceptances(getState())
    const lastFour = R.takeLast(4, R.prop('number', ccData))
    const savedCardInfo = R.find(R.propEq('lastFourCardNumber', lastFour), R.path(['ccInfo', 'data', 'cards'], getState()))
    const moveinfo = fullProfile(getState())
    const quotes = userQuotes(getState())
    const fullType = R.find(R.propEq('type', 'Full-Service'))(quotes);
    const trackingId = getTrackingId(getState())
    const quoteId = R.prop('id', fullType)

    let userAddress = {
      addressLine1: R.prop('address1', ccData),
      addressLine2: R.prop('address2', ccData),
      city: R.prop('city', ccData),
      state: R.prop('state', ccData),
      postalCode: R.prop('zipcode', ccData),
    }

    const formatted = {
      firstName: R.path(['customer', 'firstName'], moveinfo),
      lastName: R.path(['customer', 'lastName'], moveinfo),
      amount: R.prop('chargeAmount', ccData),
      address: userAddress.addressLine1 ? userAddress : R.prop('originAddress', moveinfo),
      id: R.prop('id', savedCardInfo),
      lastFourCardNumber: R.takeLast(4, R.prop('number', ccData))
    }


    if (!eacceptanceData) {
      return getEAcceptance(quoteId).then(response => {
        // Store all eacceptances
        dispatch(storeEAcceptances(response.data))

        // get credit card info
        const ccEAcceptance = R.find(R.propEq('formTypeCode', 'CREDIT_CARD_AUTHORIZATION'), response.data)
        const formId = R.prop('formId', ccEAcceptance)

        putCCInfo(trackingId, formId, formatted).then((result) => {
          dispatch(fetchCCInfo())
          dispatch(clearCsErrors())
          successToast.toastIsOpen = true
        }).catch((error) => {
          console.log(error)
          // dispatch csError here then trigger toast
          dispatch(incrementCsErrors())
          dispatch(fetchCCInfo())
        })

      }).catch(error => {
        console.log(error);
        dispatch(incrementCsErrors())
          dispatch(fetchCCInfo())
      });
    }

    // get eacceptance from existing state
    const ccEAcceptance = R.find(R.propEq('formTypeCode', 'CREDIT_CARD_AUTHORIZATION'), eacceptanceData)
    const formId = R.prop('formId', ccEAcceptance)

    // Do CC
    putCCInfo(trackingId, formId, formatted).then((result) => {
      dispatch(fetchCCInfo())
      dispatch(clearCsErrors())
      successToast.toastIsOpen = true
    }).catch((error) => {
      console.log(error)
      // dispatch csError here then trigger toast
        dispatch(incrementCsErrors())
          dispatch(fetchCCInfo())
    })
  }
}

export function removeCCInfo(ccData) {
  return async function(dispatch, getState) {

    const eacceptanceData = eacceptances(getState())
    const quotes = userQuotes(getState())
    const fullType = R.find(R.propEq('type', 'Full-Service'))(quotes);
    const trackingId = getTrackingId(getState())
    const quoteId = R.prop('id', fullType)
    const formatted = R.prop('id', ccData)
  //
    if (!eacceptanceData) {
      return getEAcceptance(quoteId).then(response => {
        // Store all eacceptances
        dispatch(storeEAcceptances(response.data))

        // get credit card info
        const ccEAcceptance = R.find(R.propEq('formTypeCode', 'CREDIT_CARD_AUTHORIZATION'), response.data)
        const formId = R.prop('formId', ccEAcceptance)

        deleteCCInfo(trackingId, formId, formatted).then((result) => {
          dispatch(fetchCCInfo())
          dispatch(clearCsErrors())
        }).catch((error) => {
          console.log(error)
          // dispatch csError here then trigger toast
          dispatch(incrementCsErrors())
          dispatch(fetchCCInfo())
        })

      }).catch(error => {
        console.log(error);
      });
    }
  //
  //   // get eacceptance from existing state
    const ccEAcceptance = R.find(R.propEq('formTypeCode', 'CREDIT_CARD_AUTHORIZATION'), eacceptanceData)
    const formId = R.prop('formId', ccEAcceptance)
  //
  //   // Do CC
    deleteCCInfo(trackingId, formId, formatted).then((result) => {
      dispatch(fetchCCInfo())
      dispatch(clearCsErrors())
    }).catch((error) => {
      console.log(error)
      // dispatch csError here then trigger toast
      dispatch(incrementCsErrors())
          dispatch(fetchCCInfo())
    })
  }
}

export function completeBooking() {
  return async function(dispatch, getState) {

    const eacceptanceData = eacceptances(getState())
    const quotes = userQuotes(getState())
    const fullType = R.find(R.propEq('type', 'Full-Service'))(quotes);

    const quoteId = R.prop('id', fullType)

    // get eacceptance from existing state
    const ccEAcceptance = R.find(R.propEq('formTypeCode', 'CREDIT_CARD_AUTHORIZATION'), eacceptanceData)
    const formId = R.prop('formId', ccEAcceptance)

    // Do booking
    return putBooking(quoteId, formId).then(() => {
      return getEAcceptance(quoteId).then(response => {
        // Refresh all eacceptances
        dispatch(storeEAcceptances(response.data))
        dispatch(bookingCompletion())
      })
    })
  }
}
