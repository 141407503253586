import React from 'react';
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import * as R from 'ramda';

import { LobsterLayout, LobsterToast, LobsterIcon } from '@hlk/lobster-react/dist';

import MainNavigation from 'components/MainNavigation';
import Checklist from 'components/Checklist';

import './PageLayout.scss';

// Main App Layout
const PageLayout = (props) => {
  // Utilties
  const location = useLocation();
  const history = useHistory();

  const getLayoutClass = () => {
    return location.pathname.replace(/\//g, "").replace(/-/g, "_")
  }

  const getOsClass = () => {
    let os = null
    if (navigator.appVersion.indexOf("Win") != -1) os = "windows";
    if (navigator.appVersion.indexOf("Mac") != -1) os = "mac";
    if (navigator.appVersion.indexOf("X11") != -1) os = "unix";
    if (navigator.appVersion.indexOf("Linux") != -1) os = "linux";
    return `os_type--${os}`
  }

  // Data
  const userProfile = useSelector(state => R.path(['userProfile', 'data'], state))
  const customerType = userProfile.customer.customerType;

  if (customerType === 'National Account' && location.pathname === '/payment') {
    history.push('/dashboard')
  }

  if (!userProfile) return null;
  return (
    <LobsterLayout
      className={`page_layout ${getLayoutClass()} ${getOsClass()}`}
      dataDirection="column"
      directionMd="row">
      <MainNavigation></MainNavigation>
      <div className="page_layout-content">
        {props.children}
        <Checklist></Checklist>
      </div>
      <LobsterToast
        id="checklist_add-success"
        toastMessage="Task created" toastDuration="3000" isStatic="false" verticalPosition="top" horizontalPosition="center"
      >
        <LobsterIcon
          data="check"
          dataSize="xsmall"></LobsterIcon>
      </LobsterToast>
      <LobsterToast
        id="checklist_delete-success"
        toastMessage="Task deleted" toastDuration="3000" isStatic="false" verticalPosition="top" horizontalPosition="center"
      >
        <LobsterIcon
          data="check"
          dataSize="small"></LobsterIcon>
      </LobsterToast>
      <LobsterToast
        id="checklist_api-error"
        toastMessage="Failure: Please try again" toastDuration="3000" isStatic="false" verticalPosition="top" horizontalPosition="center"
      >
        <LobsterIcon
          data="close"
          dataSize="small"></LobsterIcon>
      </LobsterToast>

      <LobsterToast
        id="cc_api-error-first"
        toastMessage="Failure: Please try again" toastDuration="3000" isStatic="false" verticalPosition="top" horizontalPosition="center"
      >
        <LobsterIcon
          data="close"
          dataSize="small"></LobsterIcon>
      </LobsterToast>
      
      <LobsterToast
        id="cc_api-error-more"
        toastMessage="Failure: Please try again" toastDuration="3000" isStatic="false" verticalPosition="top" horizontalPosition="center"
      >
        <LobsterIcon
          data="close"
          dataSize="small"></LobsterIcon>
      </LobsterToast>
    </LobsterLayout>

  );

}

export default PageLayout;
