import corporateMenuMiddleware from 'middleware/wordpress/corporateMenu'

// Reducer
export const corporateMenu = (state = {}, action) => {

  //Clears Page Data
  if (action.type === 'CLEAR_CORPORATE_MENU') {
    return {
      data: null,
      error: false,
    }
  }

  // Sets Page Data
  if (action.type === 'STORE_CORPORATE_MENU') {
    return {
      data: corporateMenuMiddleware(action.data),
      error: false,
    }
  }

  // Handles Error
  if (action.type === 'CORPORATE_MENU_ERROR') {
    return {
      data: corporateMenuMiddleware(action.data),
      error: true
    }
  }

  return state
}
