import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { checklistContent } from './reducers/wordpress/checklistContentReducer';
import { mobileMenuDisplay } from './reducers/display/mobileMenuDisplayReducer';
import { checklistDisplay } from './reducers/display/checklistDisplayReducer';

import { userProfile } from './reducers/api/userProfileReducer';
import { checklistItems } from './reducers/api/checklistItemsReducer';
import { hvis } from './reducers/api/hvisReducer';
import { contacts } from './reducers/api/contactsReducer';
import { csErrors } from './reducers/api/csErrors';
import { eacceptances } from './reducers/api/eacceptances';
import { ccInfo } from './reducers/api/ccInfo';
import { bookingComplete } from './reducers/api/bookingCompletion';

import { residentialMenu } from './reducers/wordpress/residentialMenuReducer';
import { corporateMenu } from './reducers/wordpress/corporateMenuReducer';
import { dashboardContent } from './reducers/wordpress/dashboardContentReducer';
import { contactsContent } from './reducers/wordpress/contactsContentReducer';
import { hviContent } from './reducers/wordpress/hviContentReducer';
import { paymentContent } from './reducers/wordpress/paymentContentReducer';

import { timelineContent } from './reducers/wordpress/timelineContentReducer';

const initialState = {
  // User Profile
  userProfile: {
    data: null,
    error: false,
  },

  // Account Settings
  // Checklist
  checklistContent: {
    data: {
      edit_field_labels: {
        task_name: 'Task Name',
        due_date: 'Due Date',
        notes: 'Notes',
        save_cta: 'Save Changes'
      },
      add_view_heading: 'New Task',
      default_view_heading: 'Checklist',
      edit_view_heading: 'Task Details'
    },
    error: false
  },

  checklistItems: {
    data: null,
    error: false
  },

  // Display
  checklistDisplay: false,


  // Mobile Menu
  mobileMenuDisplay: false,

  // Residential Menu
  residentialMenu: {
    data: {
      account_settings_link: {
        menu_item_label: 'Account Settings',
        menu_item_slug: 'account-settings',
        menu_item_target: '_self',
        icon_url_color: null,
        icon_url_white: null,
        icon_default: 'settings'
      },
      menu_item: [{
        menu_item_label: 'Dashboard',
        menu_item_slug: 'dashboard',
        menu_item_slug: 'dashboard',
        icon_default: 'home'
      }],
      sign_out_link: {
        icon_url_color: null,
        icon_url_white: null,
        icon_default: 'exit_to_app',
        menu_item_label: 'Sign Out'
      }
    },
    error: false
  },

  corporateMenu: {
    data: {
      account_settings_link: {
        menu_item_label: 'Account Settings',
        menu_item_slug: 'account-settings',
        menu_item_target: '_self',
        icon_url_color: null,
        icon_url_white: null,
        icon_default: 'settings'
      },
      menu_item: [{
        menu_item_label: 'Dashboard',
        menu_item_slug: 'dashboard',
        menu_item_slug: 'dashboard',
        icon_default: 'home'
      }],
      sign_out_link: {
        icon_url_color: null,
        icon_url_white: null,
        icon_default: 'exit_to_app',
        menu_item_label: 'Sign Out'
      }
    },
    error: false
  },

  // HVIs
  hvis: {
    data: null,
    error: null,
  },

  // Contacts
  contacts: {
    data: null,
    error: null,
  },
  // Dashboard Content
  dashboardContent: {
    data: null,
    error: null
  },

  // Contacts Content
  contactsContent: {
    data: {
      contact_brand_widget: {
        contact_brand_team: "Customer Care Team",
        contact_brand_widget_title: "Contact Brand"
      },
      contacts_widget: {
        after_move_contact_label: "After booking your move",
        booked_contact_zero_state: "Your estimator has not been assigned yet",
        backup_contact_label: "If you can't reach anyone above",
        before_move_contact_label: "Before booking your move",
        before_booking_contact_zero_state: "Your estimator has not been assigned yet",
        contacts_widget_title: "Who to Call",
        in_transit_contact_label: "While your shipment is in transit",
        in_transit_contact_zero_state: "Your driver has not been assigned yet"
      },
      page_title: "Contacts"
    },
    error: null
  },

  // HVI Content
  hviContent: {
    data: null,
    error: null
  },

  // Payment Content
  paymentContent: {
    data: null,
    error: null
  },

  // Timeline Content
  timelineContent: {
    data: null,
    error: null
  },

  // Store Eacceptances
  eacceptances: {
    data: null,
    error: null,
  },
  // Handle Cybersource Errors
  csErrors: 0,

  // CC info for display screen
  ccInfo: {
    data: null,
    error: null,
  },

  // Booking Completion
  bookingComplete: false,
}

export default function configureStore() {
  return createStore(
    combineReducers({
      userProfile,
      checklistDisplay,
      checklistContent,
      checklistItems,
      mobileMenuDisplay,
      residentialMenu,
      corporateMenu,
      hvis,
      contacts,
      dashboardContent,
      contactsContent,
      hviContent,
      paymentContent,
      timelineContent,
      csErrors,
      eacceptances,
      ccInfo,
      bookingComplete,
    }),
    initialState,
    applyMiddleware(thunk)
  );
}
