import dashboardContentMiddleware from 'middleware/wordpress/dashboardContent'

// Reducer
export const dashboardContent = (state = {}, action) => {

  //Clears Page Data
  if (action.type === 'CLEAR_DASHBOARD_CONTENT') {
    return {
      data: null,
      error: false,
    }
  }

  // Sets Page Data
  if (action.type === 'STORE_DASHBOARD_CONTENT') {
    return {
      data: dashboardContentMiddleware(action.data),
      error: false,
    }
  }

  // Handles Error
  if (action.type === 'DASHBOARD_CONTENT_ERROR') {
    return {
      data: dashboardContentMiddleware(action.data),
      error: true
    }
  }

  return state
}
