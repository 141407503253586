import React from 'react';
import { useSelector } from 'react-redux'
import { LobsterLayout, LobsterHeading, LobsterIcon, LobsterDivider, LobsterSheetTrigger, LobsterLink } from '@hlk/lobster-react/dist';
import EstimateLink from 'components/EstimateLink';
import BillOfLadingLink from 'components/BillOfLadingLink';
import reactStringReplace from 'react-string-replace';
import { fullServiceQuote } from 'lib/utils'

import * as R from "ramda";

function TotalDueWidget(props) {

  const quote = useSelector(state => fullServiceQuote(state))
  const totalDue = R.prop('price', quote)

  const viewServicesIncluded = () => {
    window.dataLayer.push(
      {
        'event': 'viewServices'
      }
    );
  }

  const replacementOptions = [{
    match: '[Estimate]',
    type: 'estimate-link'
  }, {
    match: '[Bill of Lading]',
    type: 'bill-of-lading'
  },
  {
    match: '[Tariff UMT1]',
    type: 'lobster-link'
  }]

  const replaceString = (string) => {
    let replacedText;

    // Replace [Estimate]
    replacedText = reactStringReplace(string, '[Estimate]', (match, i) => (
      <EstimateLink key={match + i} sendToDataLayer="true"></EstimateLink>
    ));

    // Replace [Bill of Lading]
    replacedText = reactStringReplace(replacedText, '[Bill of Lading]', (match, i) => (
      <BillOfLadingLink key={match + i} sendToDataLayer="true"></BillOfLadingLink>
    ));

    // Replace [Tariff UMT1]
    replacedText = reactStringReplace(replacedText, '[Tariff UMT1]', (match, i) => (
      <LobsterLink
        key={match + i}
        dataType="text"
        data={props.tariffLink ? props.tariffLink.title : 'Tariff UMT1'}
        dataHref={props.tariffLink && props.tariffLink.url}
        dataTarget="_blank"></LobsterLink>
    ));

    return replacedText
  }


  if (!props.totalDueWidgetContent) return null;
  return (
    <LobsterLayout
      width="100"
      widthSm="50"
      dataDirection="column">
      <div className="widget widget--bordered total_due_widget">
        <LobsterLayout
          className="total_due_widget-header"
          dataAlign="center"
          dataJustify="space-between">
          <LobsterHeading display="display-3">
            <h2>{props.totalDueWidgetContent.title}</h2>
          </LobsterHeading>
          <LobsterHeading display="display-4">
            <h3>${totalDue}</h3>
          </LobsterHeading>
        </LobsterLayout>
        <LobsterDivider></LobsterDivider>
        <LobsterLayout>
          <LobsterSheetTrigger
            dataTarget="services-included-popup"
            data={props.totalDueWidgetContent.services_included_cta_text}
            dataVariation="primary"
            dataType="text"
            dataSize="medium"
            dataDirection="row"
            sheetTitle="Services Included Popup"
            setter={viewServicesIncluded}></LobsterSheetTrigger>
        </LobsterLayout>
      </div>
      <LobsterLayout className="lobster_layout_margin-top--xs lobster_layout_margin-bottom--sm"
        style={{ "zIndex": '1' }}
      >
        <LobsterIcon
          dataSize="xsmall"
          data="info_outline"
          class="lobster_layout_margin-right--xs total_due_widget-info-icon"></LobsterIcon>
        <div className="typo_body--xsmall">
          {replaceString(props.fullTermsInfo)}
        </div>
      </LobsterLayout>
    </LobsterLayout>
  );

}

export default TotalDueWidget;
