import * as R from 'ramda'

import timelineContentMiddleware from 'middleware/wordpress/timelineContent'

const getContentForStatus = (status, type, additionalInfo, data) => {
  const st = status ? status.toLowerCase() : 'incomplete'
  return {
    status,
    type,
    additionalInfo,
    icon_default: R.prop(`milestone_${st}_icon_default`, data),
    icon_url_color: R.prop(`milestone_${st}_icon_url_color`, data),
    icon_url_white: R.prop(`milestone_${st}_icon_url_white`, data),
    milestone_cta: R.prop(`milestone_${st}_milestone_cta`, data),
    milestone_description: R.prop(`milestone_${st}_milestone_description`, data),
    milestone_title: R.prop(`milestone_${st}_milestone_title`, data),
  }
}

// Reducer
export const timelineContent = (state = {}, action) => {

  //Clears Page Data
  if (action.type === 'CLEAR_TIMELINE_CONTENT') {
    return {
      data: null,
      error: false,
    }
  }

  // Sets Page Data
  if (action.type === 'STORE_TIMELINE_CONTENT') {
    const status = R.prop('status', action)
    const content = R.path(['content', 'acf', 'milestone'], action)

    if (status && content) {
      const combined = R.zip(status, content)
      const data = R.map((item) => {
        const s = R.prop('status', item[0])
        const t = R.prop('type', item[0])
        const ai = R.prop('additionalInfo', item[0])
        return getContentForStatus(s, t, ai, item[1])
      }, combined)
      
      return {
        data: timelineContentMiddleware(data),
        error: false,
      }
    }
    return {
      data: null,
      error: true,
    }
  }

  // Handles Error
  if (action.type === 'TIMELINE_CONTENT_ERROR') {
    return {
      data: null,
      error: true
    }
  }

  return state
}
