import userProfileMiddleware from 'middleware/api/userProfile'

// Reducer
export const userProfile = (state = {}, action) => {

  //Clears Page Data
  if (action.type === 'ClEAR_USER_PROFILE') {
    return {
      data: null,
      error: false,
    }
  }

  // Sets Page Data
  if (action.type === 'STORE_USER_PROFILE') {
    return {
      data: userProfileMiddleware(action.data),
      error: false,
    }
  }

  // Handles Error
  if (action.type === 'USER_PROFILE_ERROR') {
    return {
      data: userProfileMiddleware(action.data),
      error: true
    }
  }

  return state
}
