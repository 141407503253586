import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import ChecklistItemEdit from 'components/ChecklistItemEdit';

import { updateChecklistItem } from 'store/actions/api/checklistItems';


function ChecklistStateEdit(props) {
  const dispatch = useDispatch();

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [checklist, setChecklist] = useState({});
  function getChecklistProperty(property) {
    return R.path(['selectedItem', property], props);
  }

  function setChecklistProperty(property) {
    return function (data) {
      const l = R.lensProp(property);
      setChecklist(R.set(l, data, checklist));
      setButtonDisabled(false);
    }
  }

  function editBack() {
    setButtonDisabled(true);
    return props.clearEditItem();
  }

  function editSave() {
    const data = {
      id: getChecklistProperty('id'),
      title: checklist.title || getChecklistProperty('title'),
      date: checklist.date || getChecklistProperty('date'),
      notes: R.isNil(checklist.notes) ? getChecklistProperty('notes') : checklist.notes,
      completed: props.itemCompleteStatus
    }
    dispatch(updateChecklistItem(data))
    editBack()
    window.dataLayer.push({ 'event': 'saveListItemChanges' });
  }

  return (
    <ChecklistItemEdit
      key="2"
      item={props.selectedItem}
      buttonDisabled={buttonDisabled}
      onSave={editSave}
      onFieldEdit={setChecklistProperty}
      labelTaskName={props.labelTaskName}
      labelDueDate={props.labelDueDate}
      labelNotes={props.labelNotes}
      labelSaveCta={props.labelSaveCta}
      itemCompleteStatus={props.itemCompleteStatus}></ChecklistItemEdit>
  );

}

export default ChecklistStateEdit;
