import React from 'react';
import { LobsterLayout, LobsterButton, LobsterLink } from '@hlk/lobster-react/dist';
import PaymentDrawerTwoColumnLayout from 'components/PaymentDrawerTwoColumnLayout';
import EstimateLink from 'components/EstimateLink';
import BillOfLadingLink from 'components/BillOfLadingLink';
import reactStringReplace from 'react-string-replace';
import * as R from 'ramda';

function PaymentsAgree(props) {

  const replacementOptions = [{
    match: '[Estimate]',
    type: 'estimate-link'
  }, {
    match: '[Bill of Lading]',
    type: 'bill-of-lading'
  }]

  const replaceString = (string) => {
    let replacedText;

    // Replace [Estimate]
    replacedText = reactStringReplace(string, '[Estimate]', (match, i) => (
      <EstimateLink key={match + i } sendAgreementLinkToDataLayer="true"></EstimateLink>
    ));

    // Replace [Bill of Lading]
    replacedText = reactStringReplace(replacedText, '[Bill of Lading]', (match, i) => (
      <BillOfLadingLink key={match + i} sendAgreementLinkToDataLayer="true"></BillOfLadingLink>
    ));

    // Replace [Your Rights and Responsibilities When You Move]
    replacedText = reactStringReplace(replacedText, '[Your Rights and Responsibilities When You Move]', (match, i) => (
      <LobsterLink
        key={match + i}
        dataType="text"
        data={props.content.rightsAndResponsibilitesFile.title}
        dataHref={props.content.rightsAndResponsibilitesFile.url}
        dataTarget="_blank"
        onClick={() => window.dataLayer.push({'event': 'agreementLink', 'agreementLinkText': props.content.rightsAndResponsibilitesFile.title})}
        ></LobsterLink>
    ));

    // Replace [Ready to Move]
    replacedText = reactStringReplace(replacedText, '[Ready to Move]', (match, i) => (
      <LobsterLink
        key={match + i}
        dataType="text"
        data={props.content.readyToMoveFile.title}
        dataHref={props.content.readyToMoveFile.url}
        dataTarget="_blank"
        onClick={() => window.dataLayer.push({'event': 'agreementLink', 'agreementLinkText': props.content.readyToMoveFile.title})}
        ></LobsterLink>
    ));

    return replacedText
  }

  return (
    <PaymentDrawerTwoColumnLayout
      left={<div>
        <p className="typo_body--regular">{props.content.introCopy}</p>
        <ol className="typo_body--small">
          {R.map(agreement => {
            return <li key={agreement['agreements_list_item']}>{replaceString(agreement['agreements_list_item'])}</li>
          }, props.content.agreementsList)}
        </ol>
        <p className="typo_body--regular">{props.content.outroCopy}</p>
      </div>}
      right={<div>
        <p className="typo_align--center typo_body--regular">{props.content.agreementStatement}</p>
        <LobsterLayout
          dataJustify="center"
          className="button_full">
          <LobsterButton
            data={props.content.ctaText}
            setter={() => {
              window.dataLayer.push({ 'event': 'acceptAgreements' })
              window.dataLayer.push({
                'event': 'screenLoaded',
                'screenUrl': "/addpaymentmethods"
              })
          
              props.accept()
            }}
          ></LobsterButton>
        </LobsterLayout>
      </div>}>
    </PaymentDrawerTwoColumnLayout>
  );

}

export default PaymentsAgree;
