export const SHOW_CHECKLIST = 'SHOW_CHECKLIST'
export function showChecklist() {
  return {
    type: SHOW_CHECKLIST,
  }
}

export const HIDE_CHECKLIST = 'HIDE_CHECKLIST'
export function hideChecklist() {
  return {
    type: HIDE_CHECKLIST,
  }
}

export const TOGGLE_CHECKLIST = 'TOGGLE_CHECKLIST'
export function toggleChecklist() {
  return {
    type: TOGGLE_CHECKLIST,
  }
}
