import { getCorporateMenu } from 'lib/api'

import { trackingId } from 'lib/utils'

export const CLEAR_CORPORATE_MENU = 'CLEAR_CORPORATE_MENU'
export function clearCorporateMenu() {
  return {
    type: CLEAR_CORPORATE_MENU,
  }
}

export const STORE_CORPORATE_MENU = 'STORE_CORPORATE_MENU'
export function storeCorporateMenu(data) {
  return {
    type: STORE_CORPORATE_MENU,
    data,
  }
}

export const CORPORATE_MENU_ERROR = 'CORPORATE_MENU_ERROR'
export function CorporateMenuError(data) {
  return {
    type: CORPORATE_MENU_ERROR,
    data,
  }
}

export function fetchCorporateMenu(filters) {
  return function (dispatch, getState) {
    dispatch(clearCorporateMenu())

    const id = trackingId(getState())
    return getCorporateMenu(id, filters)
      .then(
        (response) => {
          return dispatch(storeCorporateMenu(response.data))
        }
      )
      .catch(
        (err) => {
          return dispatch(CorporateMenuError(null))
        }
      )
  }
}