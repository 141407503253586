import React from 'react';
import { LobsterLayout, LobsterCheckbox, LobsterIcon } from '@hlk/lobster-react/dist';
import { useSelector, useDispatch } from 'react-redux'
import * as R from 'ramda';


function ChecklistItem(props) {
  const isError = useSelector(state => R.path(['checklistItems', 'error'], state))

  function edit(id) {
    return props.onEdit && props.onEdit(id)
  }

  function onCheckItem(item, status) {    
    window.dataLayer.push({
      'event': `${status ? 'markListItemIncomplete' : 'completeListItem'}`
    });


    let apiErrorToast = document.querySelector("#checklist_api-error");
    // var checkbox = document.querySelector(".lobster_checkbox-input")
    // var checkboxParent = document.querySelector("lobster-checkbox")

    // checkbox.parentNode.classList.add('checked')
    
    // console.log(checkbox)


      if (isError) {
        apiErrorToast.toastIsOpen = true
        return props.onCheck && props.onCheck(item, status)    
      }

    return props.onCheck && props.onCheck(item, !status)
  }

  function dateBeforeToday(date) {
    return new Date(date) < new Date(new Date().toDateString());
  }

  function itemClickEvents() {
    edit(props.item.id);

    let isOverDue = ((dateBeforeToday(props.item.date) && !props.item.completed)) ? 'Yes' : 'No';

    window.dataLayer.push({
      'event': 'viewListItem',
      'overdue': isOverDue
      });
  }


  function onKeyUp(event) {
    if (event.charCode === 13) {
      edit(props.item.id)
    }
  }

  return (
    <LobsterLayout
      className="checklist_item lobster_layout_padding-horizontal--lg"
      dataJustify="space-between"
      dataAlign="center">
      <LobsterLayout dataAlign="center">
        <LobsterCheckbox
          className={`${props.item.completed ? 'checked' : ''}`}
          getter={() => props.item.completed}
          setter={() => onCheckItem(props.item, props.item.completed)}
          dataLabel={props.item.title}></LobsterCheckbox>
      </LobsterLayout>
      <LobsterLayout
        role="button"
        aria-label={`Edit task ${props.item.title}`}
        tabIndex="0"
        onClick={() => itemClickEvents()}
        onKeyPress={(e) => onKeyUp(e)}
        dataJustify="space-between"
        dataAlign="center"
        className="checklist_item-button lobster_layout_flex--grow">
        <div className="typo_body--small">{props.item.title}</div>
        <LobsterLayout dataAlign="center">
          <div className="typo_body--small">
            {dateBeforeToday(props.item.date) && !props.item.completed ? <div className="lobster_layout_color--warn-200">Overdue</div> : props.item.date}
          </div>
          <LobsterIcon
            data="keyboard_arrow_right"
            className="lobster_layout_margin-left--sm"></LobsterIcon>
        </LobsterLayout>
      </LobsterLayout>
    </LobsterLayout>
  );

}

export default ChecklistItem;
