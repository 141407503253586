export default (response) => {
  if (response && response.acf) {
    // console.log(response.acf)
    return response.acf
  } else {
    return {
      agreements_widget: {
        ready_to_move_form: {

        },
        rights_and_responsibilities_form: {

        },
        estimate_cta_text: "Estimate",
        bill_of_lading_cta_text: "Bill of Lading",
        credit_card_authorization_form: "Credit Card Authorization Form"
      },
      full_terms_info: "See [Tariff UMT1], your [Estimate], and [Bill of Lading] for full terms.",
      move_details_widget: {
        title: "Your move",
        view_estimate_cta_text: "View Estimate"
      },
      page_title: "Payments & Agreements",
      payment_prompt: {
        image: {

        },
        heading: "Ready to get moving?",
        payment_trigger_text: "Next: Accept and Pay"
      },
      payment_widget: {
        heading_tooltip: "Credit cards can be edited up until 2 days prior to load date."
      },
      total_due_widget: {
        title: "Your Move",
        services_included_cta_text: "Services Included",
        services_included_modal_title: "Services Included"
      },
      agreements_title: "Agreements",
      agree_cta_text: "Accept & Continue",
      agreement_into_copy: 'By clicking "Accept & Continue" you are booking your move with X and are:',
      agreement_outro_copy: "This replaces all other prior Estimate(s) and Bill(s) of Lading for your move.",
      agreement_statement: "By clicking below, I agree that I have reviewed the documents and agree to all of the statements listed above.",
      agreement_step_1: "Confirming receipt of the [Estimate], the Your Rights and Responsibilities When You Move and Ready to Move pamphlets;",
      agreement_step_2: "acknowledging the valuation election(s) as printed on the Estimate and Bill of Lading;",
      agreement_step_3: "waiving physical inspection, as applicable; and",
      agreement_step_4: "agreeing to all of the terms and conditions of the [Bill of Lading] agreement.",
      delete_card_copy: "This will delete the card ending in []",
      complete_booking_cta_text: "Complete Booking"
    }
  }
}
