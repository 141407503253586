import React from 'react';
import { LobsterHeading } from '@hlk/lobster-react/dist';

function PageBanner(props) {
  return (
    <div className="page_banner">
      <div className="container_centered">
        <LobsterHeading display="display-3">
          <h1>{props.title}</h1>
        </LobsterHeading>
        {props.children}
      </div>
    </div>
  );

}

export default PageBanner;
