import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { LobsterLayout, LobsterHeading, LobsterDivider, LobsterTooltip, LobsterTooltipWrapper, LobsterButton, LobsterVideo, LobsterSheetTrigger } from '@hlk/lobster-react/dist';
import PageBanner from 'components/PageBanner';
import WidgetRow from 'components/WidgetRow';
import HviDrawer from 'components/HviDrawer';
import * as R from 'ramda';

import { fetchHVIs } from 'store/actions/api/hvis';
import { createHVI } from 'store/actions/api/hvis';
import { fetchHviContent, HviContentError } from 'store/actions/wordpress/hviContent';
import { fetchHviPdf } from 'store/actions/api/hvis';

function HighValueInventory() {

  // Utilities
  const dispatch = useDispatch()

  const hviContent = useSelector(state => R.path(['hviContent'], state))
  const itemsWidgetTitle = useSelector(state => R.path(['hviContent', 'data', 'items_widget_title'], state))
  const itemsWidgetTooltip = useSelector(state => R.path(['hviContent', 'data', 'items_widget_tooltip'], state))
  const itemsWidgetZeroStateCopy = useSelector(state => R.path(['hviContent', 'data', 'items_widget_zero_state_copy'], state))
  const itemsWidgetZeroCtaLabel = useSelector(state => R.path(['hviContent', 'data', 'items_widget_zero_state_cta_label'], state))
  const pageContent = useSelector(state => R.path(['hviContent', 'data', 'page_content'], state))
  const pageTitle = useSelector(state => R.path(['hviContent', 'data', 'page_title'], state))
  const addItemsTitle = useSelector(state => R.path(['hviContent', 'data', 'add_items_title'], state))
  const editItemsTitle = useSelector(state => R.path(['hviContent', 'data', 'edit_items_title'], state))
  const videoHeading = useSelector(state => R.path(['hviContent', 'data', 'video_heading'], state))
  const videoId = useSelector(state => R.path(['hviContent', 'data', 'video', 'video_id'], state))
  const videoTitle = useSelector(state => R.path(['hviContent', 'data', 'video', 'video_title'], state))
  const [limitItemsDisplay, setLimitItemsDisplay] = useState(10)
  const [showLessButton, setShowLessButton] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState()
  const [items, setItems] = useState([])
  const [videoPlayed, setVideoPlayed] = useState(false)
  const [loadingContent, setLoadingContent] = useState(true)
  const [loadingHVIS, setLoadingHVIS] = useState(true)
  const mapIndexed = R.addIndex(R.map);

  // Initialize Data
  useEffect(() => {

    hviContent.data ?
      setLoadingContent(false) :
      dispatch(fetchHviContent()).then(() => { setLoadingContent(false) })

    HVIS ?
      setLoadingHVIS(false) :
      dispatch(fetchHVIs()).then((data) => { setLoadingHVIS(false) })

  }, []);

  const HVIS = useSelector(state => state.hvis.data);

  const truncatedItemsCount = () => {
    return HVIS.highValueInventoryItems.length - limitItemsDisplay
  }

  function addItemDataLayer() {
    window.dataLayer.push({ 'event': 'startHighValueItems' })
  }

  function editItemDataLayer() {
    window.dataLayer.push({ 'event': 'editHighValueItems' })
  }

  function saveHVISheetDataLayer() {
    window.dataLayer.push({ 'event': 'saveHighValueItems' })
  }

  function closeHVISheetDataLayer() {
    window.dataLayer.push({ 'event': 'closeScreen', 'screenName': 'High Value Inventory' })
  }

  function playVideoDataLayer() {
    window.dataLayer.push({ 'event': 'videoOpen' })
  }

  const videoUpdated = (evt) => {
    if (evt === 'playing' && !videoPlayed) {
      setVideoPlayed(true)
      playVideoDataLayer()
    }
  }

  return (
    <div>
      <PageBanner title={pageTitle}></PageBanner>
      <WidgetRow bannerOverlay="true">
        <LobsterLayout
          width="100"
          dataDirection="column">
          <div className="widget widget--bordered">
            <LobsterLayout
              dataAlign="center"
              dataJustify="space-between">
              <LobsterLayout dataAlign="center">
                <LobsterHeading display="display-3">
                  <h2>{itemsWidgetTitle}</h2>
                </LobsterHeading>
                <LobsterTooltipWrapper
                  showOn="hover"
                  horizontalPosition="right"
                  verticalPosition="top">
                  <LobsterButton
                    slot="lobster-tooltip-trigger"
                    data="info_outline"
                    dataType="icon"
                    dataSize="xsmall"
                    dataAriaLabel="Your Items Info"
                    dataVariation="quarternary"
                    onMouseEnter={() => window.dataLayer.push({ 'event': 'toolTip', 'tipName': 'Your Items' })}>
                  </LobsterButton>
                  <LobsterTooltip
                    slot="lobster-tooltip"
                    id="items-description">
                    <p>{itemsWidgetTooltip}</p>
                  </LobsterTooltip>
                </LobsterTooltipWrapper>
              </LobsterLayout>
              {HVIS &&
                HVIS.highValueInventoryItems && HVIS.highValueInventoryItems.length > 0 &&
                <LobsterSheetTrigger
                  dataTarget="items-inventory"
                  dataVariation="secondary"
                  dataType="icon"
                  data="edit"
                  sheetTitle="Edit HVI list"
                  setter={() => editItemDataLayer()}>
                </LobsterSheetTrigger>
              }
            </LobsterLayout>
            <LobsterDivider></LobsterDivider>
            <div className="lobster_layout_padding-top--sm">
              {(HVIS && (HVIS.highValueInventoryItems.length === 0))
                && <LobsterLayout
                  key="zero state"
                  dataDirection="column"
                  directionMd="row"
                  alignMd="center"
                  justifyMd="space-between">
                  <p>{itemsWidgetZeroStateCopy}</p>
                  <LobsterSheetTrigger
                    dataTarget="items-inventory"
                    className="button_full"
                    sheetTitle="Add High Value Items"
                    data={itemsWidgetZeroCtaLabel}
                    setter={() => addItemDataLayer()}>
                  </LobsterSheetTrigger>
                </LobsterLayout>}

              {HVIS && HVIS.highValueInventoryItems && HVIS.highValueInventoryItems.length > 0 &&
                <div key="items">
                  <p>{HVIS.highValueInventoryItems.length == 1 ? `You have 1 high value item.` : `You have ${HVIS.highValueInventoryItems.length} high value items.`}</p>
                  <ul className="hvi_list">
                    {mapIndexed((item, idx) => {
                      return (idx + 1) <= limitItemsDisplay && <li className="hvi_list-item" key={idx}>{item.description}</li>
                    }, HVIS.highValueInventoryItems)}
                  </ul>
                  {HVIS.highValueInventoryItems.length > limitItemsDisplay && <LobsterButton
                    data={`See ${truncatedItemsCount()} more ${truncatedItemsCount() > 1 ? `items` : `item`}`}
                    dataType="text"
                    dataVariation="secondary"
                    setter={() => {
                      setLimitItemsDisplay(28)
                      setShowLessButton(true)
                    }}></LobsterButton>}
                  {showLessButton && <LobsterButton
                    data={`See less`}
                    dataType="text"
                    dataVariation="secondary"
                    setter={() => {
                      setLimitItemsDisplay(10)
                      setShowLessButton(false)
                    }}></LobsterButton>}
                </div>
              }

            </div>
          </div>
        </LobsterLayout>
      </WidgetRow>
      {HVIS &&
        <HviDrawer
          title={(HVIS.highValueInventoryItems && HVIS.highValueInventoryItems.length < 1) ? addItemsTitle : editItemsTitle}
          items={HVIS.highValueInventoryItems}
          saveItemsMessage="Save High Value Items"
          maxItemsMessage="You've added the maximum number of items!"
          onSaveItems={editedItems => {
            setItems(editedItems)
            setShowLessButton(false)
            setLimitItemsDisplay(28)
            saveHVISheetDataLayer()
            dispatch(createHVI(HVIS, editedItems))
          }}
          itemLimit="28"
          onDrawerOpen={() => setDrawerOpen(true)}
          onDrawerClose={() => {
            setDrawerOpen(false)
            closeHVISheetDataLayer()
          }}
        ></HviDrawer>}

      <LobsterLayout
        className="hvi_content"
        dataContainer="centered"
        dataDirection="column">
        <div className="lobster_layout_padding-vertical--xs">
          <div>
            <div
              className="lobster_layout_padding-bottom--sm"
              dangerouslySetInnerHTML={{ __html: pageContent }}>
            </div>
            {(HVIS && HVIS.highValueInventoryItems && HVIS.highValueInventoryItems.length >= 1) &&
              <LobsterButton
                dataType="text"
                data="Preview Form"
                onClick={() => {
                  dispatch(fetchHviPdf())
                  window.dataLayer.push({ 'event': 'previewHighValueForm' })
                }}>
              </LobsterButton>
            }
            <LobsterHeading
              className="lobster_layout_margin-top--xl lobster_layout_margin-bottom--sm"
              display="display-3">
              <h2 className="typo_body-weight--regular">{videoHeading}</h2>
            </LobsterHeading>
            <LobsterVideo
              videoUpdated={videoUpdated}
              type="youtube"
              video-id={videoId}
              aspect-ratio="16-9"
              title={videoTitle}>
            </LobsterVideo>
          </div>
        </div>
      </LobsterLayout>
    </div>
  );
}

export default HighValueInventory;
