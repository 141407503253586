import React from 'react';
import { LobsterButton } from '@hlk/lobster-react/dist';
import { fetchEAcceptancePdf } from 'store/actions/api/eacceptancePdf';
import { useDispatch } from 'react-redux';

function BillOfLadingLink(props) {
  const dispatch = useDispatch()

  return (<LobsterButton
    dataType="text"
    data={props.data ? props.data : 'Bill of Lading'}
    setter={() => {
      props.setter && props.setter()
      props.sendToDataLayer && window.dataLayer.push({ 'event': 'viewBillOfLading' })
      props.sendAgreementLinkToDataLayer && window.dataLayer.push({'event': 'agreementLink',
      'agreementLinkText': props.data ? props.data : 'Bill of Lading'})
      dispatch(fetchEAcceptancePdf("BILL_OF_LADING"))
    }}></LobsterButton>
  );

}

export default BillOfLadingLink;
