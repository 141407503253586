import React, { useState, useEffect } from 'react';
import { LobsterLayout, LobsterSheet, LobsterInput, LobsterHeading, LobsterDivider, LobsterIcon, LobsterButton, LobsterSheetTrigger } from '@hlk/lobster-react/dist';
import * as R from 'ramda';

function itemField(item, idx, count, onDelete, onEdit) {

  const deleteDisabled = () => {
    return false
  }

  function deleteItemDataLayer() {
    window.dataLayer.push({ 'event': 'deleteHighValueItem' })
  }

  return (
    <LobsterLayout
      key={idx}
      dataAlign="center" className="lobster_layout_margin-bottom--md">
      <LobsterInput
        dataLabel="Item Name"
        getter={() => item.description}
        setter={(data) => onEdit(idx, data)}></LobsterInput>
      <LobsterButton
        dataType="icon"
        data="delete"
        dataVariation="secondary"
        className="lobster_layout_margin-top--md lobster_layout_margin-left--xs"
        dataAriaLabel={`Delete Item ${item.description}
      `}
        dataDisabled={deleteDisabled()}
        setter={() => {
          onDelete(idx)
          deleteItemDataLayer()
        }}></LobsterButton>
    </LobsterLayout>
  )
}

function HviDrawer(props) {
  const copyItems = () => {
    let items = R.clone(props.items)
    return items
  }



  const mapIndexed = R.addIndex(R.map);
  const [canSaveItems, setCanSaveItems] = useState(false)
  const [editedItems, setEditedItems] = useState(copyItems)
  const [canAddItem, setCanAddItem] = useState(false);

  const editItem = (idx, description) => {

    const item = {
      ...editedItems[idx],
      description: description
    }

    const updatedArray = [
      ...editedItems.slice(0, idx),
      item,
      ...editedItems.slice(idx + 1)
    ]

    setEditedItems(updatedArray)
    setCanSaveItems(true)
  }

  const deleteItem = (idx) => {
    const updated = [
      ...editedItems.slice(0, idx),
      ...editedItems.slice(idx + 1)
    ]
    setEditedItems(updated)
    setCanSaveItems(true)
  }


  const addItem = () => {
    setCanSaveItems(true)
    window.dataLayer.push({ 'event': 'addHighValueItem' })
    return setEditedItems([...editedItems, {}])
  }

  const checkCanAddItem = (items) => {
    const hasContent = item => item.description ? item.description.length > 0 : false;
    const completedItems = R.filter(hasContent, items);
    return setCanAddItem(completedItems.length === items.length)
  }

  const rejectEmptyItems = (items) => {
    const empty = (item) => {
      return !item.description || item.description == ''
    }
    return R.reject(empty, items);
  }

  useEffect(() => {
    editedItems &&
      checkCanAddItem(editedItems)
  })

  if (!editedItems) return null;
  return (
    <LobsterSheet
      dataTarget="items-inventory"
      direction="bottom"
      sheetTitle="Items Inventory"
      sheetOnOpen={props.onDrawerOpen}
      sheetOnClose={() => {
        if (editedItems.length === 0) {
          props.onSaveItems(editedItems)
        }
        props.onDrawerClose()
      }

      }
      className="hvi_drawer">

      <div className="drawer_header">
        <LobsterLayout
          dataAlign="flex-end"
          dataJustify="space-between"
        >
          <LobsterHeading display="display-3">
            <h1>{props.title ? props.title : "Edit Items"}</h1>
          </LobsterHeading>

          <LobsterButton
            data="Delete All"
            dataType="text"
            dataVariation="tertiary"
            className="typo_transform--uppercase"
            setter={() => {
              {
                const updated = []
                setEditedItems(updated)
                setCanSaveItems(false)
              }
            }}
            dataDisabled={R.isEmpty(editedItems[0]) || editedItems.length === 0}>
            <LobsterIcon
              data="delete"
              dataSize="small"
              className="lobster_layout_margin-right--xxs"></LobsterIcon>
          </LobsterButton>
        </LobsterLayout>
        <LobsterDivider></LobsterDivider>

      </div>
      {editedItems &&
        <div className="drawer_content-wrapper">
          {mapIndexed((item, idx) => {
            return itemField(item, idx, editedItems.length, deleteItem, editItem)
          }, editedItems)}
          <LobsterButton
            style={{ display: editedItems.length >= props.itemLimit && `none` }}
            data="Add New"
            dataType="text"
            dataVariation="tertiary"
            className="typo_transform--uppercase"
            setter={addItem}
            dataDisabled={!canAddItem || editedItems.length > props.itemLimit}
          >
            <LobsterIcon
              data="add"
              dataSize="small"
              className="lobster_layout_margin-right--xxs"></LobsterIcon>
          </LobsterButton>
        </div>}
      { editedItems &&
        <div style={{ display: editedItems.length < props.itemLimit && `none` }}>{props.maxItemsMessage}</div>}
      { editedItems &&
        <div className="lobster_layout_margin-top--lg">
          <LobsterSheetTrigger
            data={props.saveItemsMessage}
            dataTarget="items-inventory"
            className="button_full"
            dataVariation="secondary"
            setter={() => props.onSaveItems(rejectEmptyItems(editedItems))}
            dataAriaLabel="Save Items and Close Items Inventory Dialog"
            dataDisabled={!canSaveItems || R.isEmpty(editedItems[0])}></LobsterSheetTrigger>
        </div>}


    </LobsterSheet>
  );

}

export default HviDrawer;
