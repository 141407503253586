import React from 'react';
import { LobsterButton, LobsterIcon } from '@hlk/lobster-react/dist';

function ChecklistCompleteButton(props) {

  return (
    <LobsterButton
      data={props.isComplete ? "Mark Incomplete" : "Complete Task"}
      dataType="text"
      dataVariation="tertiary"
      setter={() => {
        props.onFieldEdit(!props.isComplete)
      }}
      className={`typo_transform--uppercase ${props.displayOn === 'desktop' ? `hidden_lt-sm` : `hidden_gt-sm`}`}>
      <LobsterIcon
        data="check"
        dataSize="small"
        className="lobster_layout_margin-right--xxs"></LobsterIcon>
    </LobsterButton>
  );

}

export default ChecklistCompleteButton;
