import { hvisMiddleware } from 'middleware/api/hvis'

// Reducer
export const hvis = (state = {}, action) => {

  //Clears Page Data
  if (action.type === 'CLEAR_HVIS') {
    return {
      data: null,
      error: false,
    }
  }

  // Sets Page Data
  if (action.type === 'STORE_HVIS') {
    return {
      data: hvisMiddleware(action.data),
      error: false,
    }
  }

  // Handles Error
  if (action.type === 'HVIS_ERROR') {
    return {
      data: hvisMiddleware(action.data),
      error: true,
    }
  }

  return state
}
