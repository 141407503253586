import { getPaymentContent } from 'lib/api'
import { trackingId } from 'lib/utils'

export const CLEAR_PAYMENT_CONTENT = 'CLEAR_PAYMENT_CONTENT'
export function clearPaymentContent() {
  return {
    type: CLEAR_PAYMENT_CONTENT,
  }
}

export const STORE_PAYMENT_CONTENT = 'STORE_PAYMENT_CONTENT'
export function storePaymentContent(data) {
  return {
    type: STORE_PAYMENT_CONTENT,
    data,
  }
}

export const PAYMENT_CONTENT_ERROR = 'PAYMENT_CONTENT_ERROR'
export function PaymentContentError(data) {
  return {
    type: PAYMENT_CONTENT_ERROR,
    data,
  }
}

export function fetchPaymentContent(filters) {
  return function (dispatch, getState) {
    dispatch(clearPaymentContent())

    const id = trackingId(getState())
    return getPaymentContent()
      .then(
        (response) => {
          return dispatch(storePaymentContent(response.data))
        }
      )
      .catch(
        (err) => {
          return dispatch(PaymentContentError(null))
        }
      )
  }
}