import React from 'react';
import { LobsterLayout, LobsterIcon } from '@hlk/lobster-react/dist';

function ImageIcon(props) {

  return (
    <LobsterLayout
      dataAlign="center"
      dataJustify="center">
      {props.iconUrlColor || props.iconUrlWhite || props.iconUrlSelected ?
        <div className={`image_icon image_icon-size--${props.iconSize || `medium`} ${props.iconSelected ? `selected` : ``}`}>
          <img
            className="image_icon--color"
            src={props.iconUrlColor} />
          {props.iconUrlWhite && <img
            className="image_icon--white"
            src={props.iconUrlWhite} />}
        </div>
        : <LobsterIcon
          data={props.defaultIcon || 'texture'}
          dataSize={props.iconSize}></LobsterIcon>}
    </LobsterLayout>
  );

}

export default ImageIcon;
