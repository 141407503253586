import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LobsterLayout } from '@hlk/lobster-react/dist';
import * as R from 'ramda';
import ChecklistProgress from 'components/ChecklistProgress';
import ChecklistWeeksCarousel from 'components/ChecklistWeeksCarousel';
import ChecklistItem from 'components/ChecklistItem';
import ChecklistItemsCompleted from 'components/ChecklistItemsCompleted';

import { updateChecklistItem } from 'store/actions/api/checklistItems';


function ChecklistStateDefault(props) {
  const dispatch = useDispatch();
  const [filterWeek, setFilterWeek] = useState(null);

  const updateFilterWeek = (data) => {
    setFilterWeek(data)
    props.onFilterUpdate(data)
  }

  function isCompleted(task) {
    return task.completed
  }

  function weekFilter(tasks) {
    const filter = props.filters[props.currentFilter - 1]
    return filter ? R.propEq('dueInWeeks', filter)(tasks) : tasks
  }

  function completedTasks(tasks) {
    const byComplete = R.filter(isCompleted);
    const byWeek = R.filter(weekFilter);
    return R.compose(byComplete, byWeek)(tasks);
  }

  function incompleteTasks(tasks) {
    const byComplete = R.filter((task) => !isCompleted(task));
    const byWeek = R.filter(weekFilter);
    orderDescendingByDueDate(tasks)
    return R.compose(byComplete, byWeek)(tasks);
  }


  function orderDescendingByDueDate(tasks) {
    tasks.sort(function (a, b) {
      var keyA = new Date(a.date),
        keyB = new Date(b.date);
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  }

  function markComplete(item, status) {
    const updates = {
      id: item.id,
      title: item.title,
      date: item.date,
      notes: item.notes,
      completed: status
    }

    dispatch(updateChecklistItem(updates))

  }

  const getItemFilter = (title) => {
    const item = R.find(R.propEq('title', title))(props.tasks)
    return item && item['dueInWeeks']
  }

  useEffect(() => {
    props.newItem && updateFilterWeek(getItemFilter(props.newItem))

  }, [props.newItem, props.currentFilter])

  return ([
    <LobsterLayout
      key="widget-row"
      className="checklist_widget_row"
      dataDirection="column-reverse"
      dataJustify="space-between"
      directionLg="row">
      <LobsterLayout
        dataDirection="block"
        width="100"
        widthLg="45">
        <ChecklistProgress
          taskCount={props.tasks.length}
          completedTasks={completedTasks(props.tasks).length}></ChecklistProgress>
      </LobsterLayout>
      <div className="checklist_carousel-wrapper">
        <ChecklistWeeksCarousel
          currentFilter={props.currentFilter}
          filters={props.filters}
          filterWeek={filterWeek}
          onFilterWeekSelected={updateFilterWeek}></ChecklistWeeksCarousel>

      </div>
    </LobsterLayout>,
    <div
      key="checklist-items">
      {R.map(item => {
        return <ChecklistItem
          key={`incomplete ${item.id}`}
          item={item}
          onCheck={markComplete}
          onEdit={props.onSelectItem}></ChecklistItem>
      }, incompleteTasks(props.tasks))}
    </div>,
    <ChecklistItemsCompleted
      key="checklist-completed-items"
      items={completedTasks(props.tasks)}
      onMarkComplete={markComplete}
      onEditItem={props.onSelectItem}></ChecklistItemsCompleted>
  ]);

}

export default ChecklistStateDefault;
