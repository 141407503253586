import { getDashboardContent } from 'lib/api'
import { trackingId } from 'lib/utils'

export const CLEAR_DASHBOARD_CONTENT = 'CLEAR_DASHBOARD_CONTENT'
export function clearDashboardContent() {
  return {
    type: CLEAR_DASHBOARD_CONTENT,
  }
}

export const STORE_DASHBOARD_CONTENT = 'STORE_DASHBOARD_CONTENT'
export function storeDashboardContent(data) {
  return {
    type: STORE_DASHBOARD_CONTENT,
    data,
  }
}

export const DASHBOARD_CONTENT_ERROR = 'DASHBOARD_CONTENT_ERROR'
export function DashboardContentError(data) {
  return {
    type: DASHBOARD_CONTENT_ERROR,
    data,
  }
}

export function fetchDashboardContent(filters) {
  return function (dispatch, getState) {
    dispatch(clearDashboardContent())

    const id = trackingId(getState())
    return getDashboardContent()
      .then(
        (response) => {
          return dispatch(storeDashboardContent(response.data))
        }
      )
      .catch(
        (err) => {
          return dispatch(DashboardContentError(null))
        }
      )
  }
}