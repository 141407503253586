import { getUserProfile } from 'lib/api'

export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE'
export function clearUserProfile() {
  return {
    type: CLEAR_USER_PROFILE,
  }
}

export const STORE_USER_PROFILE = 'STORE_USER_PROFILE'
export function storeUserProfile(data) {
  return {
    type: STORE_USER_PROFILE,
    data,
  }
}

export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR'
export function userProfileError(data) {
  return {
    type: USER_PROFILE_ERROR,
    data,
  }
}

export function fetchUserProfile(username, password) {
  return function (dispatch) {
    dispatch(clearUserProfile())

    return getUserProfile()
      .then(
        (response) => {
          return dispatch(storeUserProfile(response.data))
        }
      )
      .catch(
        (err) => {
          console.log("User Profile fetch error: ", err)
          return dispatch(userProfileError(null))
        }
      )
  }
}
