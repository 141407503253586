import React from 'react';
import { LobsterLayout, LobsterSheet, LobsterIcon, LobsterHeading, LobsterDivider } from '@hlk/lobster-react/dist';
import * as R from 'ramda';
import ImageIcon from 'components/ImageIcon';

function timelineMilestone(milestone) {
  milestone.completed = (milestone.status === 'COMPLETE')
  return (
    <LobsterLayout
      key={milestone.milestone_title}
      dataAlign="center"
      className="timeline_drawer-milestone lobster_layout_padding-vertical--sm">

      <div
        className={`timeline_drawer-milestone-icon ${milestone.active && 'active'} ${milestone.completed && 'completed'} ${!milestone.completed && !milestone.active && 'upcoming'}`}>
        <ImageIcon
          defaultIcon={milestone.icon_default}
          iconUrlColor={!milestone.active && !milestone.completed && milestone.icon_url_color}
          iconUrlWhite={milestone.active && !milestone.completed && milestone.icon_url_white}></ImageIcon>
      </div>
      <div className={`timeline_drawer-milestone-title ${milestone.active && 'active'}`}>{milestone.milestone_title}
      </div>
    </LobsterLayout>
  )
}

function TimelineDrawer(props) {
  return (
    <LobsterSheet
      className="timeline_drawer"
      dataTarget="full-timeline"
      direction="bottom"
      fill="false"
      sheetTitle="full timeline">
      <div className="drawer_header">
        <LobsterHeading display="display-3">
          <h1>Timeline</h1>
        </LobsterHeading>
        <LobsterDivider></LobsterDivider>
      </div>
      <div className="drawer_content-wrapper timeline_drawer-wrapper">
        {props.milestones && R.map(milestone => {
          return timelineMilestone(milestone)
        }, props.milestones)}
      </div>
    </LobsterSheet>
  );

}

export default TimelineDrawer;
