import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { LobsterButton, LobsterInput, LobsterDatepicker, LobsterTextarea, LobsterErrorMessage } from '@hlk/lobster-react/dist';

import { createChecklistItem } from 'store/actions/api/checklistItems';

// TODO: Clear data on creation
// ----------------------------------
function ChecklistStateAdd(props) {
  const dispatch = useDispatch();
  const [
    isChanged,
    setIsChanged,
  ] = useState(false);

  const [
    title,
    setTitle,
  ] = useState(null);

  const [
    date,
    setDate,
  ] = useState(null);

  const [
    notes,
    setNotes,
  ] = useState(null);

  function onBack(itemSaved, title) {
    setIsChanged(false);
    props.onAddItemBack(itemSaved, title)
  }

  function titleSetter(title) {
    if (this.value === "") {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
    setTitle(title);
  }

  function dateSetter(date) {
    if (title == "" || !title) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
    setDate(date);
  }

  function notesSetter(notes) {
    if (title == "" || !title) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
    setNotes(notes);
  }

  function save() {
    dispatch(createChecklistItem({
      title,
      date,
      notes,
      complete: false,
    })).then(() => {
      onBack(true, title);
    })
  }

  function dateInPast(data) {
    if (data && data.isBefore(moment())) {
      return true
    }
    return false
  }

  return (
    <div
      className="checklist_item_edit-wrapper">
      <LobsterInput
        className="lobster_layout_margin-bottom--sm"
        dataLabel={props.labelTaskName || `Task Name`}
        setter={titleSetter}
        dataRequired></LobsterInput>
      <LobsterDatepicker
        className="lobster_layout_margin-bottom--sm"
        dataLabel={props.labelDueDate || `Due Date`}
        dateFormat="M/D/YYYY"
        dataInvalid={dateInPast(date)}
        dataErrors={dateInPast(date) ? [{ 'message': 'Due Date must be in the future' }] : []}
        getter={() => date && date}
        setter={dateSetter}
        returnType="moment">
      </LobsterDatepicker>
      <LobsterTextarea
        className="lobster_layout_margin-bottom--sm"
        dataLabel={props.labelNotes}
        setter={notesSetter}></LobsterTextarea>
      <LobsterButton
        className="checklist_item_edit-button"
        data={props.labelSaveCta || `Save Changes`}
        setter={save}
        dataVariation="secondary"
        dataDisabled={!isChanged || dateInPast(date)}></LobsterButton>
    </div>
  );

}

export default ChecklistStateAdd;
