import React from 'react';
import { LobsterLayout, LobsterHeading, LobsterSheetTrigger, LobsterImage } from '@hlk/lobster-react/dist';
import * as R from 'ramda';


function PaymentsConfirmed(props) {

  return (
    <LobsterLayout
      dataAlign="center"
      dataJustify="center">
      <div>
        {(props && props.paymentSuccessContent.successImage) ? 
          <LobsterLayout 
            className="lobster_layout_margin-bottom--lg"  
            dataAlign="center"
            data-direction="row" 
            data-justify="center">
            <LobsterLayout width="50" widthMd="30" data-justify="center">
              <LobsterImage
                url={props.paymentSuccessContent.successImage.url}
              ></LobsterImage>
            </LobsterLayout>
          </LobsterLayout>
            : 
          <div className="payments_confirmation-graphic"></div>
          }

        <LobsterHeading
          display="display-2"
          className="payments_confirmed--headline">
          <h2>{props.paymentSuccessContent.successSubtitle}</h2>
        </LobsterHeading>

        <ol className="payments_confirmation-list">
        {props.paymentSuccessContent.successListItems && R.map(item => {
            return <li key={item['list_item']}>{item.list_item}</li>
          }, props.paymentSuccessContent.successListItems)}
        </ol>

        <LobsterLayout dataJustify="center">
          <LobsterSheetTrigger
            dataTarget="payment-drawer"
            data={props.paymentSuccessContent.successCtaText}
            className="button_full"
            setter={props.complete}>
          </LobsterSheetTrigger>
        </LobsterLayout>
      </div>
    </LobsterLayout>
  );

}

export default PaymentsConfirmed;
