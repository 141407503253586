import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { LobsterLayout, LobsterHeading, LobsterSheetTrigger } from '@hlk/lobster-react/dist';
import MoveDetailsWidget from 'components/MoveDetailsWidget';
import MoveTrackerCarousel from 'components/MoveTrackerCarousel';
import TimelineDrawer from 'components/TimelineDrawer';
import * as R from 'ramda';
import ServicesIncludedList from 'components/ServicesIncludedList';

import { fetchDashboardContent } from 'store/actions/wordpress/dashboardContent';
import { fetchTimelineContent } from 'store/actions/wordpress/timelineContent';

function Dashboard() {
  // Utilities
  const dispatch = useDispatch()

  // User Profile Data
  const userProfile = useSelector(state => R.path(['userProfile', 'data'], state))

  // Timeline
  const [showFullTimeline, setShowFullTimeline] = useState(true);
  const milestones = useSelector(state => R.path(['timelineContent', 'data'], state))
  const [activeMilestoneSet, setActiveMilesSet] = useState(false)
  const [activeMilestone, setActiveMilestone] = useState(1);
  const [loadingContent, setLoadingContent] = useState(true);
  const [loadingTimeline, setLoadingTimeline] = useState(true);

  const getActiveMilestoneIdx = () => {
    let in_progress = R.findIndex(R.propEq('status', 'IN_PROGRESS'))(milestones)
    let incomplete = R.findIndex(R.propEq('status', 'INCOMPLETE'))(milestones)

    let idx = 0
    if (in_progress <= 0) {
      idx = incomplete
    } else {
      idx = in_progress
    }

    return idx
  }

  const dashboardContent = useSelector(state => R.path(['dashboardContent'], state))
  const bannerWelcomeCopy = useSelector(state => R.path(['dashboardContent', 'data', 'banner', 'welcome_copy'], state))
  const bannerSubHeading = useSelector(state => R.path(['dashboardContent', 'data', 'banner', 'sub_heading'], state))
  const bannerContent = useSelector(state => R.path(['dashboardContent', 'data', 'banner', 'banner_content'], state))
  const bannerTimelineTriggerLabel = useSelector(state => R.path(['dashboardContent', 'data', 'banner', 'full_timeline_trigger_label'], state))
  const moveDetailsWidgeTitle = useSelector(state => R.path(['dashboardContent', 'data', 'move_details_widget_title'], state))
  const servicesWidgetTitle = useSelector(state => R.path(['dashboardContent', 'data', 'services_included_widget_title'], state))

  useEffect(() => {

    dashboardContent.data ?
      setLoadingContent(false) :
      dispatch(fetchDashboardContent()).then(() => { setLoadingContent(false) })

    milestones ?
      setLoadingTimeline(false) :
      dispatch(fetchTimelineContent()).then(() => { setLoadingTimeline(false) })
  }, [])

  const advanceSlides = () => {
    let newSlide = milestones[activeMilestone + 1]
    return newSlide && setActiveMilestone(activeMilestone + 1)
  }
  const receedSlides = () => {
    let newSlide = milestones[activeMilestone - 1]
    return newSlide && setActiveMilestone(activeMilestone - 1)
  }

  if (milestones) {
    if (!activeMilestoneSet) {
      setActiveMilesSet(true)
      setActiveMilestone(getActiveMilestoneIdx())
    }
  }

  return (
    <div>
      <div className="dashboard_banner">
        <LobsterLayout
          dataContainer="centered"
          dataDirection="column"
          directionSm="row"
          directionMd="row">
          <LobsterLayout
            width="100"
            widthSm="50"
            dataDirection="column"
            className="dashboard_banner-copy">
            <LobsterHeading display="display-2">
              <h1>{bannerWelcomeCopy} {userProfile.customer.firstName}!</h1>
            </LobsterHeading>
            <LobsterHeading display="display-3">
              <h2>{bannerSubHeading}</h2>
            </LobsterHeading>
            <p className="typo_body--small">{bannerContent}</p>
            <LobsterSheetTrigger
              dataTarget="full-timeline"
              data={bannerTimelineTriggerLabel}
              onClick={() => {
                window.dataLayer.push({ 'event': 'screenLoaded', 'screenURL': '/move-timeline' }, { 'event': 'viewFullTimeline' })
              }}
              dataVariation="primary"
              dataType="text"
              sheetTitle="full timeline"
              className="lobster_layout_margin-bottom--lg"></LobsterSheetTrigger>
          </LobsterLayout>
          <MoveTrackerCarousel
            milestones={milestones}
            activeMilestoneIdx={activeMilestone}
            onAdvanceSlides={() => advanceSlides()}
            onReceedSlides={() => receedSlides()}
          ></MoveTrackerCarousel>
        </LobsterLayout>
      </div>

      <LobsterLayout
        className="dashboard_content"
        dataContainer="centered"
        dataDirection="column">
        <div className="lobster_layout_padding-vertical--xxl">
          <MoveDetailsWidget
            title={moveDetailsWidgeTitle}
            showOrderNumber
            showHeadingOnDesktop></MoveDetailsWidget>

          {userProfile.customer.customerType === 'National Account' &&
            <LobsterHeading display="display-3" className="lobster_layout_margin-top--lg lobster_layout_margin-bottom--xs">
              <h2>{servicesWidgetTitle}</h2>
            </LobsterHeading>}
          {userProfile.customer.customerType === 'National Account' &&
            <div className="widget widget--bordered">
              <ServicesIncludedList></ServicesIncludedList>
            </div>
          }
        </div>
      </LobsterLayout>
      <TimelineDrawer
        open={showFullTimeline}
        milestones={milestones}></TimelineDrawer>
    </div>
  );
}

export default Dashboard;
