import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda';

import { LobsterLayout, LobsterHeading, LobsterDivider, LobsterLink } from '@hlk/lobster-react/dist';
import PageBanner from 'components/PageBanner';
import WidgetRow from 'components/WidgetRow';

import { fetchContacts } from 'store/actions/api/contacts';
import { fetchContactsContent } from 'store/actions/wordpress/contactsContent';


function contactInfo(contact) {

  const formatPhone = (phone) => {
    const cleaned = ('' + phone).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return ['(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
  }

  return (
    <div>
      {contact.firstName && <div className="typo_body--small">{contact.firstName} {contact.lastName}, {contact.role}</div>}
      {contact.phone &&
        (contact.phone && contact.phoneExt) ?
        <div>
          <LobsterLink
            data={formatPhone(contact.phone)}
            dataHref={`tel:${contact.phone},${contact.phoneExt}`}
            onClick={() => {
              window.dataLayer.push({ 'event': 'callContact', 'contactType': contact.role })
            }}>
          </LobsterLink>
        </div>
        :
        <div>
          <LobsterLink
            data={formatPhone(contact.phone)}
            dataHref={`tel:${contact.phone}`}
            onClick={() => {
              window.dataLayer.push({ 'event': 'callContact', 'contactType': contact.role })
            }}>
          </LobsterLink>
        </div>
      }
      {contact.email &&
        <div>
          <LobsterLink
            data={contact.email}
            dataHref={`mailto:${contact.email}`}
            onClick={() => {
              window.dataLayer.push({ 'event': 'emailContact', 'contactType': contact.role })
            }}>
          </LobsterLink>
        </div>
      }
    </div>
  )
}

function Contacts() {
  // Utilities
  const dispatch = useDispatch()

  const contactsFromState = useSelector(state => R.path(['contacts', 'data'], state))

  const contactsContent = useSelector(state => R.path(['contactsContent'], state))
  const pageTitle = useSelector(state => R.path(['contactsContent', 'data', 'page_title'], state))
  const contactsWidgetTitle = useSelector(state => R.path(['contactsContent', 'data', 'contacts_widget', 'contacts_widget_title'], state))
  const companyContactWidgetTitle = useSelector(state => R.path(['contactsContent', 'data', 'contact_brand_widget', 'contact_brand_widget_title'], state))

  const estimatorZeroStateDescription = useSelector(state => R.path(['contactsContent', 'data', 'contacts_widget', 'before_booking_contact_zero_state'], state))
  const estimatorDescription = useSelector(state => R.path(['contactsContent', 'data', 'contacts_widget', 'before_move_contact_label'], state))

  const estimatorBookedZeroStateDescription = useSelector(state => R.path(['contactsContent', 'data', 'contacts_widget', 'booked_contact_zero_state'], state))
  const estimatorBookedDescription = useSelector(state => R.path(['contactsContent', 'data', 'contacts_widget', 'after_move_contact_label'], state))

  const [loadingContent, setLoadingContent] = useState(true);
  const [loadingContacts, setLoadingContacts] = useState(true);

  let estimator = {}
  let moveCoordinator = {}

  if (contactsFromState) {
    estimator = R.find(R.propEq('role', 'Estimator'))(contactsFromState);
    moveCoordinator = R.find(R.propEq('role', 'MoveCoordinator'))(contactsFromState);
  }
  // Leaving this for now, since UNI will complete the fallback contact
  const driverZeroStateDescription = useSelector(state => R.path(['contactsContent', 'data', 'contacts_widget', 'in_transit_contact_zero_state'], state))
  const driverDescription = useSelector(state => R.path(['contactsContent', 'data', 'contacts_widget', 'in_transit_contact_label'], state))

  const [driverBooked] = useState({
    firstName: 'Johnny',
    lastName: "Appleseed",
    role: 'Driver',
    description: 'While your shipment is in transit',
    phone: '13144872671',
    email: 'jappleseed@bobsmovingandstorage.com'
  });
  const [driver] = useState(null);

  const fallbackContactDescription = useSelector(state => R.path(['contactsContent', 'data', 'contacts_widget', 'backup_contact_label'], state))
  const [fallbackContact] = useState({
    name: 'Jenny Sanders',
    title: 'Estimator',
    phone: '13144873826',
    email: 'jsanders@bobsmovingandstorage.com'
  });

  const customerCareDescription = useSelector(state => R.path(['contactsContent', 'data', 'contact_brand_widget', 'contact_brand_team'], state))
  const [customerCare] = useState({
    phone: '13144873826',
    phoneExt: '987'
  });

  useEffect(() => {
    contactsFromState ?
      setLoadingContacts(false) :
      dispatch(fetchContacts()).then(() => { setLoadingContacts(false) })

    contactsContent.data ?
      setLoadingContent(false) :
      dispatch(fetchContactsContent()).then(() => { setLoadingContent(false) })

  }, [])

  return (
    <div>
      <PageBanner title={pageTitle}></PageBanner>
      <WidgetRow bannerOverlay="true">
        <LobsterLayout
          width="100"
          widthMd="50"
          dataDirection="column">
          <div className="widget widget--bordered lobster_layout_margin-bottom--lg">
            <LobsterLayout
              dataAlign="center"
              dataJustify="space-between">
              <LobsterHeading display="display-3">
                <h2>{contactsWidgetTitle}</h2>
              </LobsterHeading>
            </LobsterLayout>
            <LobsterDivider></LobsterDivider>
            <div className="lobster_layout_padding-top--xs">

              <div className="lobster_layout_margin-bottom--lg">
                <div className="typo_body--bold lobster_layout_margin-bottom--xxs">{estimatorDescription}</div>
                {!estimator ? <div className="typo_body--small">{estimatorZeroStateDescription}</div> : contactInfo(estimator)}
              </div>

              <div className="lobster_layout_margin-bottom--lg">
                <div className="typo_body--bold lobster_layout_margin-bottom--xxs">{estimatorBookedDescription}</div>
                {!moveCoordinator ? <div className="typo_body--small">{estimatorBookedZeroStateDescription}</div> : contactInfo(moveCoordinator)}
              </div>

              <div className="lobster_layout_margin-bottom--lg">
                <div className="typo_body--bold lobster_layout_margin-bottom--xxs">{driverDescription}</div>
                {!driverBooked ? <div className="typo_body--small">{driverZeroStateDescription}</div> : contactInfo(driverBooked)}
              </div>

              <div>
                <div className="typo_body--bold lobster_layout_margin-bottom--xxs">{fallbackContactDescription}</div>
                {contactInfo(fallbackContact)}
              </div>
            </div>
          </div>
        </LobsterLayout>

        <LobsterLayout
          width="100"
          widthMd="50"
          dataDirection="column">
          <div className="widget widget--bordered">
            <LobsterLayout
              dataAlign="center"
              dataJustify="space-between">
              <LobsterHeading display="display-3">
                <h2>{companyContactWidgetTitle}</h2>
              </LobsterHeading>
            </LobsterLayout>
            <LobsterDivider></LobsterDivider>
            <div className="lobster_layout_padding-top--xs">
              <div className="lobster_layout_margin-bottom--lg">
                <div className="typo_body--bold lobster_layout_margin-bottom--xxs">{customerCareDescription}</div>
                {contactInfo(customerCare)}
              </div>
            </div>
          </div>
        </LobsterLayout>
      </WidgetRow>
    </div>
  );
}

export default Contacts;
