import { getResidentialMenu } from 'lib/api'
import { trackingId } from 'lib/utils'

export const CLEAR_RESIDENTIAL_MENU = 'CLEAR_RESIDENTIAL_MENU'
export function clearResidentialMenu() {
  return {
    type: CLEAR_RESIDENTIAL_MENU,
  }
}

export const STORE_RESIDENTIAL_MENU = 'STORE_RESIDENTIAL_MENU'
export function storeResidentialMenu(data) {
  return {
    type: STORE_RESIDENTIAL_MENU,
    data,
  }
}

export const RESIDENTIAL_MENU_ERROR = 'RESIDENTIAL_MENU_ERROR'
export function ResidentialMenuError(data) {
  return {
    type: RESIDENTIAL_MENU_ERROR,
    data,
  }
}

export function fetchResidentialMenu(filters) {
  return function (dispatch, getState) {
    dispatch(clearResidentialMenu())

    const id = trackingId(getState())
    return getResidentialMenu()
      .then(
        (response) => {
          return dispatch(storeResidentialMenu(response.data))
        }
      )
      .catch(
        (err) => {
          return dispatch(ResidentialMenuError(null))
        }
      )
  }
}