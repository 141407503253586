import React from 'react';
import { LobsterAccordion, LobsterAccordionContent, LobsterAccordionItem, LobsterAccordionTrigger } from '@hlk/lobster-react/dist';
import * as R from 'ramda';
import ChecklistItem from 'components/ChecklistItem';

function ChecklistItemsCompleted(props) {

  return (
    <LobsterAccordion
      openFirst="false"
      className="checklist_completed_accordion">
      <LobsterAccordionItem slot="lobster-accordion-item">
        <LobsterAccordionTrigger
          className="checklist_completed_accordion-trigger"
          slot="lobster-accordion-trigger"
          data="Completed"></LobsterAccordionTrigger>
        <LobsterAccordionContent
          className="lobster_layout_padding-top--sm"
          slot="lobster-accordion-content">
          {R.map(item => {
            return <ChecklistItem
              key={`complete ${item.id}`}
              item={item}
              onCheck={props.onMarkComplete}
              onEdit={props.onEditItem}></ChecklistItem>
          }, props.items)}
        </LobsterAccordionContent>
      </LobsterAccordionItem>
    </LobsterAccordion>
  );

}

export default ChecklistItemsCompleted;
