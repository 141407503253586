import React from 'react';
import { LobsterSheet } from '@hlk/lobster-react/dist';
import ServicesIncludedList from 'components/ServicesIncludedList';

function ServicesIncludedModal(props) {
  return (
    <LobsterSheet
      className='services_modal'
      dataTarget="services-included-popup"
      direction="center"
      fill="false"
      sheetTitle="Services Included Popup">
      <div className="sheet_title typo_body--regular typo_body--bold lobster_layout_margin-bottom--sm">{props.servicesIncludedModalContent}</div>
      <div className="lobster_layout_margin-top--md"></div>
      <ServicesIncludedList small></ServicesIncludedList>
    </LobsterSheet>
  );

}

export default ServicesIncludedModal;
