import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { LobsterLayout, LobsterHeading, LobsterIcon, LobsterDivider } from '@hlk/lobster-react/dist';
import * as R from 'ramda';
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { fetchTimelineContent } from 'store/actions/wordpress/timelineContent';
import EstimateLink from 'components/EstimateLink';

function reformatDate(date) {
  return moment(date).format('MMMM D, YYYY')
}

function location(icon, location, date) {
  return (
    <LobsterLayout className="move_details_widget-location lobster_layout_margin-bottom--sm">
      <LobsterIcon
        class="move_details_widget-location-icon"
        dataSize="medium"
        data={icon}></LobsterIcon>
      <div>
        <div>
          <span className="typo_body--default typo_body--bold lobster_layout_margin-right--xs">{location.city}</span>
          <span className="typo_body--small">{location.state} {location.zipcode}</span>
        </div>
        <div className="typo_body--xsmall">{date && reformatDate(date)}</div>
      </div>
    </LobsterLayout>
  )
}

function estimation(label, value, unit) {
  return (
    <LobsterLayout className="move_details_widget-estimation lobster_layout_margin-bottom--sm">
      <div className="typo_body--xsmall typo_body--bold lobster_layout_margin-right--xs">{label}</div>
      <div className="typo_body--xsmall">{value.toLocaleString()} {unit}</div>
    </LobsterLayout>
  )
}


function MoveDetailsWidget(props) {
  const dispatch = useDispatch()
  // Initialize Data
  const userProfile = useSelector(state => R.path(['userProfile', 'data'], state))
  const timeline = useSelector(state => R.path(['timelineContent', 'data'], state))
  let dd = ''
  if (timeline) {
    R.find(item => {
      if (item.type === 'DELIVERY_DAY') {
        dd = item.additionalInfo
      }
    }, timeline)
  }



  useEffect(() => {
    // Timeline Content
    !timeline && dispatch(fetchTimelineContent());
  }, [])

  if (!timeline) return null
  return (
    userProfile.quotes && R.map(quote => {
      if (quote.type === "Full-Service") {
        return <LobsterLayout
          key={quote.id}
          width="100"
          widthSm="50"
          dataDirection="column"
          className="move_details_widget lobster_layout_padding-vertical-sm lobster_layout_margin-bottom--lg">

          <LobsterLayout
            className={!props.showHeadingOnDesktop && `hidden_gt-sm`}
            dataJustify="space-between"
            dataAlign="center">
            <LobsterHeading display="display-3" className="move_details_widget-heading">

              <h2>{(props.moveDetailsWidgetContent && props.moveDetailsWidgetContent.title) ? props.moveDetailsWidgetContent.title : (quote.title ? quote.title : `Your Move`)}</h2>

            </LobsterHeading>
            {props.showOrderNumber && <LobsterLayout
              dataAlign="center"
              className="typo_body--xsmall">
              <div className="typo_body--bold">Order #</div>
              <div>{quote.formattedOrderNumber && quote.formattedOrderNumber}</div>
            </LobsterLayout>}
            {props.showEstimation && <LobsterLayout
              dataAlign="center"
              className="typo_body--xsmall lobster_layout_margin-top--xs lobster_layout_margin-bottom--sm">
              <EstimateLink data={(props.moveDetailsWidgetContent && props.moveDetailsWidgetContent.view_estimate_cta_text) ? props.moveDetailsWidgetContent.view_estimate_cta_text : "Estimate"} sendToDataLayer="true"></EstimateLink>
            </LobsterLayout>}
          </LobsterLayout>

          <div className="widget widget--bordered">
            <LobsterLayout
              dataDirection="column"
              directionLg="row"
              justifyLg="space-between"
              className="move_details_widget-inner-wrapper">
              {location('place',
                {
                  'city': quote.originAddress.city ? quote.originAddress.city : userProfile.originAddress.city,
                  'state': quote.originAddress.state ? quote.originAddress.state : userProfile.originAddress.state,
                  'zipcode': quote.originAddress.postalCode ? quote.originAddress.postalCode : userProfile.originAddress.postalCode
                },
                quote.loadDay)}
              <div className="hidden_gt-md">
                {estimation('Estimated Weight', quote.weight ? quote.weight : 'N/A', "lbs!!!!")}
              </div>
              <div className="hidden_gt-md">
                {estimation('Estimated Mileage', quote.miles ? quote.miles : 'N/A', "miles")}
              </div>
              {location('flag',
                {
                  'city': quote.destinationAddress.city ? quote.destinationAddress.city : userProfile.destinationAddress.city,
                  'state': quote.destinationAddress.state ? quote.destinationAddress.state : userProfile.destinationAddress.state,
                  'zipcode': quote.destinationAddress.postalCode ? quote.destinationAddress.postalCode : userProfile.destinationAddress.postalCode
                },
                dd.actualDeliveryDate ? dd.actualDeliveryDate : null)}
            </LobsterLayout>
            <LobsterDivider className="hidden_lt-md lobster_layout_margin-bottom--sm"></LobsterDivider>
            <LobsterLayout
              className="hidden_lt-md"
              dataDirection="column"
              directionMd="row"
              alignMd="center"
              justifyMd="space-between">
              {estimation('Estimated Weight', quote.weight, "lbs")}
              {estimation('Estimated Mileage', quote.miles, "miles")}
            </LobsterLayout>
          </div>
          {props.showEstimation && <LobsterLayout
            dataAlign="center"
            className="hidden_lt-sm lobster_layout_margin-top--xs lobster_layout_margin-bottom--sm"
            style={{ "zIndex": '1' }}
          >
            <EstimateLink data={(props.moveDetailsWidgetContent && props.moveDetailsWidgetContent.view_estimate_cta_text) ? props.moveDetailsWidgetContent.view_estimate_cta_text : "Estimate"} sendToDataLayer="true"></EstimateLink>
          </LobsterLayout>}
        </LobsterLayout>
      }
    }, userProfile.quotes)
  );
}

export default MoveDetailsWidget;
