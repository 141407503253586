import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as R from 'ramda'
import { NavLink } from 'react-router-dom';
import { LobsterLayout, LobsterButton, LobsterLink } from '@hlk/lobster-react/dist';
import { showMenu, hideMenu } from 'store/actions/display/menu';
import SwipeableDrawer from '@material-ui/core/Drawer';
import ImageIcon from 'components/ImageIcon';

import { fetchResidentialMenu } from 'store/actions/wordpress/residentialMenu';
import { fetchCorporateMenu } from 'store/actions/wordpress/corporateMenu';

import { logout } from 'lib/api'

import logo from 'assets/logo-color-united.svg'

function MenuLink(props) {
  const dispatch = useDispatch();

  function navLinkEvents() {
    dispatch(hideMenu());
    window.dataLayer.push(
      {
        'event': 'mainNav',
        'selectionText': props.label
      }
    );
    window.dataLayer.push(
      {
        'event': 'screenLoaded',
        'screenURL': props.route
      }

    );
  }

  const imageIcon = () => {
    return <ImageIcon
      iconUrlColor={props.iconUrlColor}
      iconUrlWhite={props.iconUrlWhite}
      defaultIcon={props.defaultIcon}></ImageIcon>
  }

  return props.type === 'app-route' ? <NavLink
    onClick={() => navLinkEvents()}
    className="main_navigation-link"
    to={props.route}>
    {imageIcon()}
    {props.label}
  </NavLink> : <LobsterLink
    onClick={() => navLinkEvents()}
    data={props.label}
    dataHref={props.route}
    dataTarget={props.target}
    dataVariation="main-navigation"
    dataSize="">
      {imageIcon()}
    </LobsterLink>
}

function LogoutLink(props) {
  const dispatch = useDispatch();
  const signOut = (e) => {
    e.preventDefault()
    dispatch(hideMenu())
    logout()
  }

  return <a
    href="#logout"
    onClick={signOut}
    className="main_navigation-link"
  >
    <ImageIcon
      iconUrlColor={props.iconUrlColor}
      iconUrlWhite={props.iconUrlWhite}
      defaultIcon={props.defaultIcon}></ImageIcon>
    {props.label}
  </a>
}

function Menu(props) {
  return (
    <LobsterLayout
      dataDirection="column"
      dataJustify="space-between"
      className="main_navigation-menu-wrapper">
      <div>
        <LobsterLayout
          className="main_navigation-container"
          dataAlign="center"
          dataJustify="center">
          <a href='/' className="main_navigation-logo--wrapper">
            <img
              className="screen_reader main_navigation-logo"
              src={props.logo}
              alt="Logo" />
          </a>
        </LobsterLayout>
        <nav>
          {props.menuItems && R.map(item => {
            return <MenuLink
              key={item.menu_item_label}
              route={item.menu_item_slug}
              iconUrlColor={item.icon_url_color}
              iconUrlWhite={item.icon_url_white}
              defaultIcon={item.icon_default}
              label={item.menu_item_label}
              type={item.menu_item_type}
              target={item.menu_item_target}></MenuLink>
          }, props.menuItems)}
        </nav>
      </div>

      <nav>
        <MenuLink
          route={props.accountSettingsLink && props.accountSettingsLink.icon}
          defaultIcon={props.accountSettingsLink && props.accountSettingsLink.icon_default}
          iconUrlColor={props.accountSettingsLink && props.accountSettingsLink.icon_url_color}
          iconUrlWhite={props.accountSettingsLink && props.accountSettingsLink.icon_url_white}
          label={props.accountSettingsLink && props.accountSettingsLink.menu_item_label}></MenuLink>
        <LogoutLink
          defaultIcon={props.signOutLink && props.signOutLink.icon_default}
          label={props.signOutLink && props.signOutLink.menu_item_label}
          iconUrlColor={props.signOutLink && props.signOutLink.icon_url_color}
          iconUrlWhite={props.signOutLink && props.signOutLink.icon_url_white}></LogoutLink>
      </nav>
    </LobsterLayout>
  )
}

function MainNavigation() {
  const dispatch = useDispatch();
  const mobileMenuDisplay = useSelector(state => state.mobileMenuDisplay);
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const userProfile = useSelector(state => R.path(['userProfile', 'data'], state))
  const customerType = userProfile.customer.customerType;
  const menuType = (customerType == 'National Account') ? 'corporateMenu' : 'residentialMenu'
  const menuItems = useSelector(state => R.path([menuType, 'data', 'menu_item'], state))

  const accountSettingsLink = useSelector(state => R.path([menuType, 'data', 'account_settings_link'], state))

  const signOutLink = useSelector(state => R.path([menuType, 'data', 'sign_out_link'], state))

  const [loading, setLoading] = useState(true)
  const showMobileNav = (size) => {
    return size <= 960
  }

  const getWidth = () => window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

  function useCurrentWidth() {
    let [width, setWidth] = useState(getWidth());

    useEffect(() => {
      (menuType === 'corporateMenu') ?
        dispatch(fetchCorporateMenu()).then(() => { setLoading(false) })
        :
        dispatch(fetchResidentialMenu()).then(() => { setLoading(false) })

      let timeoutId = null;
      const resizeListener = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => setWidth(getWidth()), 150);
      };
      window.addEventListener('resize', resizeListener);

      return () => {
        window.removeEventListener('resize', resizeListener);
      }
    }, [])

    return width;
  }

  return (
    <header key="0" className="main_navigation">
      {showMobileNav(useCurrentWidth()) ? ([
        <LobsterLayout
          key="navigation container"
          className="main_navigation-container"
          dataAlign="center"
          dataJustify="space-between">
          <LobsterButton
            dataType="icon"
            data="menu"
            data-variation="tertiary"
            setter={() => dispatch(showMenu())}></LobsterButton>
          <LobsterLayout
            className="main_navigation-logo--wrapper"
            dataJustify="center"
            dataAlign="center"
            width="100">
            <img
              className="screen_reader main_navigation-logo"
              src={logo}
              alt="Logo" />
          </LobsterLayout>
        </LobsterLayout>,
        <React.Fragment key={'1'}>
          <SwipeableDrawer
            open={mobileMenuDisplay}
            onClose={() => dispatch(hideMenu())}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            className="main_navigation-drawer">
            <LobsterButton
              className="main_navigation-button-close"
              dataType="icon"
              data="close"
              dataVariation="tertiary"
              setter={() => dispatch(hideMenu())}></LobsterButton>
            <Menu
              logo={logo}
              loading={loading}
              menuItems={menuItems}
              accountSettingsLink={accountSettingsLink}
              signOutLink={signOutLink}></Menu>
          </SwipeableDrawer>
        </React.Fragment>
      ]) : <Menu
          logo={logo}
          loading={loading}
          menuItems={menuItems}
          accountSettingsLink={accountSettingsLink}
          signOutLink={signOutLink} />}

    </header>
  );

}

export default MainNavigation;
