export const CLEAR_CS_ERRORS = 'CLEAR_CS_ERRORS'
export function clearCsErrors() {
  return {
    type: CLEAR_CS_ERRORS,
  }
}

export const INCREMENT_CS_ERRORS = 'INCREMENT_CS_ERRORS'
export function incrementCsErrors() {
  return {
    type: INCREMENT_CS_ERRORS,
  }
}
