import contactsMiddleware from 'middleware/api/contacts'

// Reducer
export const contacts = (state = {}, action) => {

  //Clears Page Data
  if (action.type === 'CLEAR_CONTACTS') {
    return {
      data: null,
      error: false,
    }
  }

  // Sets Page Data
  if (action.type === 'STORE_CONTACTS') {
    return {
      data: contactsMiddleware(action.data),
      error: false,
    }
  }

  // Handles Error
  if (action.type === 'CONTACTS_ERROR') {
    return {
      data: contactsMiddleware(action.data),
      error: true,
    }
  }

  return state
}
