// Reducer
export const mobileMenuDisplay = (state = {}, action) => {

  if (action.type === 'SHOW_MENU') {
    return true
  }

  if (action.type === 'HIDE_MENU') {
    return false
  }

  if (action.type === 'TOGGLE_MENU') {
    return state.mobileMenuDisplay ? false : true
  }

  return state
}
