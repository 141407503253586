import React from 'react';
import { LobsterLayout } from '@hlk/lobster-react/dist';

function PageBanner(props) {
  return (
    <div className="container_centered">
      <LobsterLayout
        className={`widget_row ${props.bannerOverlay ? `banner_overlay` : ``}`}
        dataDirection="column"
        directionSm={props.desktopReverse ? `row-reverse` : `row`}
        dataWrap="true">
        {props.children}
      </LobsterLayout>
    </div>
  );

}

export default PageBanner;
