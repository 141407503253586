import residentialMenuMiddleware from 'middleware/wordpress/residentialMenu'

// Reducer
export const residentialMenu = (state = {}, action) => {

  //Clears Page Data
  if (action.type === 'CLEAR_RESIDENTIAL_MENU') {
    return {
      data: null,
      error: false,
    }
  }

  // Sets Page Data
  if (action.type === 'STORE_RESIDENTIAL_MENU') {
    return {
      data: residentialMenuMiddleware(action.data),
      error: false,
    }
  }

  // Handles Error
  if (action.type === 'RESIDENTIAL_MENU_ERROR') {
    return {
      data: residentialMenuMiddleware(action.data),
      error: true
    }
  }

  return state
}
