import React from 'react';
import { LobsterButton } from '@hlk/lobster-react/dist';
import { fetchEAcceptancePdf } from 'store/actions/api/eacceptancePdf';
import { useDispatch } from 'react-redux';

function EstimateLink(props) {
  const dispatch = useDispatch()

  return <LobsterButton
    dataType={props.buttonType ? props.buttonType : 'text'}
    data={props.data ? props.data : 'Estimate'}
    setter={() => {
      props.setter && props.setter()
      props.sendToDataLayer && window.dataLayer.push({ 'event': 'viewEstimate' })
      props.sendAgreementLinkToDataLayer && window.dataLayer.push({
        'event': 'agreementLink',
        'agreementLinkText': props.data ? props.data : 'Estimate'
      })
      dispatch(fetchEAcceptancePdf("ONE_PAGE_ESTIMATE"))
    }}></LobsterButton>

}

export default EstimateLink;
