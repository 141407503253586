import * as R from 'ramda'

import { toUniDate, fromUniDate } from 'lib/utils'

const taskLens = R.lensProp('tasks')


// Transform tasks to format used by the UI
export const checklistItemsMiddleware = (data) => {
  const dateLens = R.lensProp('displayDueDate')

  if (data) {
    const formatted = R.map(
      (task) => {
        const d = fromUniDate(R.view(dateLens, task))
        // console.log(typeof d)
        return {
          id: task.id,
          title: task.name,
          date: d,
          dueInWeeks: task.dueInWeeks,
          completed: task.status === "COMPLETED",
          notes: task.notes,
          description: task.description,
          custom: !task.seeded
        }
      },
      R.view(taskLens, data)
    )
    return R.set(taskLens, formatted, data)
  }
}

// Transform a task to the format used by the API
export const outgoingChecklistItemMiddleware = (data) => {
  const dateLens = R.lensProp('date')
  const date = toUniDate(R.view(dateLens, data))
  return data.id ? {
    id: data.id,
    name: data.title,
    dueDate: date,
    status: data.completed ? "COMPLETED" : "REVIEW",
    notes: data.notes
  } : {
      name: data.title,
      dueDate: date,
      status: data.completed ? "COMPLETED" : "REVIEW",
      notes: data.notes
    }
}
