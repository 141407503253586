import React, { useState } from 'react';
import { LobsterLayout, LobsterButton, LobsterHeading, LobsterSheetTrigger, LobsterErrorMessage } from '@hlk/lobster-react/dist';
import SavedCard from 'components/SavedCard';
import { useSelector } from 'react-redux'

import * as R from 'ramda';
import moment from 'moment'

function PaymentsBalance(props) {
  const userProfile = useSelector(state => R.path(['userProfile', 'data'], state))
  let loadDate = ''
  userProfile.quotes && R.map(quote => {
    if (quote.type === "Full-Service") {
      loadDate = quote.loadDay
    }
  }, userProfile.quotes)

  // Determine of load date is within two days
  let today = moment();
  let two_days = today.clone().add(3, 'days').startOf('day');

  function isWithinTwoDays(momentDate) {
    return momentDate.isBefore(two_days);
  }


  // const [balance, setBalance] = useState(3063.04);
  // const [totalDue, setTotalDue] = useState(6063.04);

  const mapIndexed = R.addIndex(R.map);

  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return (
    <LobsterLayout dataDirection="column" style={{ maxWidth: '400px' }}>

      <LobsterLayout
        dataJustify="space-between"
        className="lobster_layout_margin-bottom--xxl">
        <div>
          <LobsterHeading
            data="Balance"
            display="display-5"
            type="h4"></LobsterHeading>
          <div className={`payments_value--balance ${props.balance < props.totalDue && 'error'}`}>{numberFormat.format(props.balanceDue)}</div>
        </div>
        <div className="typo_align--right">
          <LobsterHeading
            data="Total Due"
            display="display-5"
            type="h4"></LobsterHeading>
          <div className="payments_value--totaldue">{numberFormat.format(props.totalDue)}</div>
        </div>
      </LobsterLayout>

      <LobsterLayout
        dataJustify="space-between"
        className="lobster_layout_margin-bottom--sm">
        <LobsterHeading
          data="Your Payment Methods"
          display="display-5"
          type="h4"></LobsterHeading>
        <LobsterButton
          dataType="text"
          data="Add New"
          setter={() => {
            window.dataLayer.push({ 'event': 'addCard' })
            props.onAddCard()
          }}
          dataDisabled={props.savedCards.length === 4 || isWithinTwoDays(moment(loadDate))}></LobsterButton>
      </LobsterLayout>

      {/* saved cards here */}
      <div className="lobster_layout_margin-bottom--xxl">
        {mapIndexed((card, idx) => {
          return <SavedCard
            key={idx}
            number={card.lastFourCardNumber}
            chargeAmount={card.amount}
            onUpdate={(data, idx) => props.onUpdateCard(data, idx)}
            onEdit={() => {
              window.dataLayer.push({ 'event': 'editCard' });
              props.onEditCard(idx)
            }}
            onDeleteAttempt={() => {
              window.dataLayer.push({ 'event': 'deleteCard' })
              props.onDeleteAttempt(idx)
            }}></SavedCard>
        }, props.savedCards)}
        {props.savedCards.length === 0 && <p className="typo_body--small">Use the 'add new' button to add up to 4 payment methods to this estimate</p>}
        {props.savedCards.length >= 4 && <p className="typo_body--small">You've added the maximum amount of payment methods</p>}

      {props.balanceDue < props.totalDue &&
        <LobsterErrorMessage
          data="Payment amounts don't equal the total amount due."
          dataInteracted="true"
          >
          </LobsterErrorMessage>
        }
      </div>

      {/* booking here */}
      <div className="lobster_layout_margin-bottom--xxl">
        <p className="typo_align--center">{props.content.preCtaText}</p>
        <LobsterLayout
          dataJustify="center"
          className="button_full">
          <LobsterButton
            data={props.content.completeBookingCtaText}
            setter={() => {
              window.dataLayer.push({
                'event': 'completeBooking',
                'numCards': props.savedCards.length
              })
              props.complete()
            }}
            dataDisabled={!props.hasSavedCards || (parseFloat(props.balanceDue) != 0)}
          ></LobsterButton>
        </LobsterLayout>
        <LobsterLayout
          dataJustify="center">
          <LobsterSheetTrigger
            dataTarget="payment-acceptance"
            data="View Payment Agreement Acceptance"
            dataType="text"
            className="lobster_layout_margin-top--sm"
            sheet-title="Payment Agreement Acceptance"
          ></LobsterSheetTrigger>
        </LobsterLayout>
      </div>

    </LobsterLayout>
  );

}

export default PaymentsBalance;
