import * as R from 'ramda'
import { checklistItemsMiddleware } from 'middleware/api/checklist'

// Reducer
export const checklistItems = (state = {}, action) => {

  //Clears Checklist Data
  if (action.type === 'ClEAR_CHECKLIST_ITEMS') {
    return {
      data: null,
      error: false,
    }
  }

  // Sets Checklist Data
  if (action.type === 'STORE_CHECKLIST_ITEMS') {
    return {
      data: checklistItemsMiddleware(action.data),
      error: false,
    }
  }

  // Handles Error
  if (action.type === 'CHECKLIST_ITEMS_ERROR') {
    return {
      data: checklistItemsMiddleware(action.data),
      error: true
    }
  }

  return state
}
