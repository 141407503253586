export default (response) => {
  if (response && response.acf) {
    return response.acf
  } else {
    return {
      banner: {
        welcome_copy: "Welcome",
        sub_heading: "Your move timeline",
        banner_content: "This is where you are in your move process. You can also view your upcoming milestones as well as anything you have completed in the past.",
        full_timeline_trigger_label: "View Full Timeline"
      },
      move_details_widget_title: "Your Move", services_included_widget_title: "Services Included"
    }
  }
}
