import React from 'react';
import { LobsterLayout, LobsterDivider, LobsterButton, LobsterSheetTrigger } from '@hlk/lobster-react/dist';
import { useSelector, useDispatch } from 'react-redux'
import * as R from 'ramda';
import moment from 'moment'

function SavedCard(props) {
  const userProfile = useSelector(state => R.path(['userProfile','data'], state))
  let loadDate = ''
  userProfile.quotes && R.map(quote => {
    if (quote.type === "Full-Service") {
      loadDate = quote.loadDay
    }
  }, userProfile.quotes)

  // Determine of load date is within two days
  let today = moment();
  let two_days = today.clone().add(3, 'days').startOf('day');

  function isWithinTwoDays(momentDate) {
    return momentDate.isBefore(two_days);
  }

  return ([
    <LobsterLayout dataJustify="space-between" key="saved-card">
      <div>
        <div>Card ending in {props.number}</div>
        <div>${props.chargeAmount}</div>
      </div>
      <LobsterLayout>
        {props.onEdit && <LobsterButton
          setter={props.onEdit}
          dataType="icon"
          dataVariation="secondary"
          data="edit"
          dataDisabled={isWithinTwoDays(moment(loadDate))}
          >
        </LobsterButton>}
        {props.onDeleteAttempt && <LobsterSheetTrigger
          dataTarget="confirm-delete-card"
          sheetTitle="Confirm Delete Card"
          dataType="icon"
          dataVariation="secondary"
          data="delete"
          setter={props.onDeleteAttempt}>
        </LobsterSheetTrigger>}
      </LobsterLayout>
    </LobsterLayout>,
    <LobsterDivider key="saved-card-divider"></LobsterDivider>
  ]);

}

export default SavedCard;
