
// Reducer
export const csErrors = (state = {}, action) => {

  //Clears Page Data
  if (action.type === 'CLEAR_CS_ERRORS') {
    return 0
  }

  // Sets Page Data
  if (action.type === 'INCREMENT_CS_ERRORS') {
    const toast = document.querySelector("#payment_add-error-cybersource-fail");
    toast.toastIsOpen = true
    const val = state.csErrors + 1
    return val
  }

  return state
}
