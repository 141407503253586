import { getContactsContent } from 'lib/api'
import { trackingId } from 'lib/utils'

export const CLEAR_CONTACTS_CONTENT = 'CLEAR_CONTACTS_CONTENT'
export function clearContactsContent() {
  return {
    type: CLEAR_CONTACTS_CONTENT,
  }
}

export const STORE_CONTACTS_CONTENT = 'STORE_CONTACTS_CONTENT'
export function storeContactsContent(data) {
  return {
    type: STORE_CONTACTS_CONTENT,
    data,
  }
}

export const CONTACTS_CONTENT_ERROR = 'CONTACTS_CONTENT_ERROR'
export function ContactsContentError(data) {
  return {
    type: CONTACTS_CONTENT_ERROR,
    data,
  }
}

export function fetchContactsContent(filters) {
  return function (dispatch, getState) {
    dispatch(clearContactsContent())

    const id = trackingId(getState())
    return getContactsContent()
      .then(
        (response) => {
          return dispatch(storeContactsContent(response.data))
        }
      )
      .catch(
        (err) => {
          return dispatch(ContactsContentError(null))
        }
      )
  }
}