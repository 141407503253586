import React from 'react';
import { LobsterLayout, LobsterButton, LobsterDivider, LobsterIcon, LobsterLink, LobsterSheetTrigger, LobsterProgressIndicator, LobsterHeading, LobsterInput, LobsterTextarea, LobsterRadioGroup, LobsterCheckbox, LobsterTooltip, LobsterTooltipWrapper } from '@hlk/lobster-react/dist';
import * as R from 'ramda';
import PageBanner from 'components/PageBanner';

function section(label, demo, direction) {
  return <LobsterLayout
    dataAlign={direction === 'column' ? '' : 'center'}
    dataDirection={direction}
    className="lobster_layout_margin-bottom--md">
    {demo}
    <div className="typo_body--xsmall lobster_layout_margin-horizontal--lg">{label}</div>
  </LobsterLayout>
}

function Styleguide() {

  const sectionHeader = (section) => {
    return <div className="lobster_layout_margin-top--xl lobster_layout_margin-bottom--sm">
      <div className="lobster_layout_padding-top--xl">{section}</div>
      <LobsterDivider></LobsterDivider>
    </div>
  }

  return (
    <div className="container_centered lobster_layout_margin-all--md">

      {sectionHeader('Buttons')}

      {section('primary', <LobsterButton data="Lorem Ipsum"></LobsterButton>)}
      {section('primary:disabled', <LobsterButton
        data="Lorem Ipsum"
        dataDisabled></LobsterButton>)}

      {section('secondary', <LobsterButton
        data="Lorem Ipsum"
        dataVariation="secondary"></LobsterButton>)}
      {section('secondary:disabled', <LobsterButton
        data="Lorem Ipsum"
        dataDisabled
        dataVariation="secondary"></LobsterButton>)}

      {section('chip', <LobsterButton
        data="Lorem Ipsum"
        dataVariation="chip"></LobsterButton>)}
      {section('chip:disabled', <LobsterButton
        data="Lorem Ipsum"
        dataDisabled
        dataVariation="chip"></LobsterButton>)}

      {section('chip selected', <LobsterButton
        data="Lorem Ipsum"
        dataVariation="chip-selected"></LobsterButton>)}
      {section('chip selected:disabled', <LobsterButton
        data="Lorem Ipsum"
        dataDisabled
        dataVariation="chip-selected"></LobsterButton>)}

      {/*  */}
      {sectionHeader('Text Buttons (Button elements styled to look like anchor tags)')}

      {section('primary', <LobsterButton
        data="Lorem Ipsum"
        dataType="text"></LobsterButton>)}
      {section('primary:disabled', <LobsterButton
        data="Lorem Ipsum"
        dataType="text"
        dataDisabled></LobsterButton>)}

      {section('secondary', <LobsterButton
        data="Lorem Ipsum"
        dataType="text"
        dataVariation="secondary"></LobsterButton>)}
      {section('secondary:disabled', <LobsterButton
        data="Lorem Ipsum"
        dataType="text"
        dataVariation="secondary"
        dataDisabled></LobsterButton>)}

      {section('tertiary', <LobsterButton
        data="Lorem Ipsum"
        dataType="text"
        dataVariation="tertiary">
        <LobsterIcon data="cake"></LobsterIcon>
      </LobsterButton>)}
      {section('tertiary:disabled', <LobsterButton
        data="Lorem Ipsum"
        dataType="text"
        dataVariation="tertiary"
        dataDisabled>
        <LobsterIcon data="cake"></LobsterIcon>
      </LobsterButton>)}

      {section('size small', <LobsterButton
        data="Lorem Ipsum"
        dataSize="small"
        dataType="text"></LobsterButton>)}

      {/*  */}
      {sectionHeader('Icon Buttons')}

      {section('primary', <LobsterButton
        data="favorite"
        dataType="icon"></LobsterButton>)}
      {section('primary:disabled', <LobsterButton
        data="favorite"
        dataType="icon"
        dataDisabled></LobsterButton>)}

      {section('secondary', <LobsterButton
        data="favorite"
        dataType="icon"
        dataVariation="secondary"></LobsterButton>)}
      {section('secondary:disabled', <LobsterButton
        data="favorite"
        dataType="icon"
        dataVariation="secondary"
        dataDisabled></LobsterButton>)}

      {section('tertiary', <LobsterButton
        data="favorite"
        dataType="icon"
        dataVariation="tertiary"></LobsterButton>)}
      {section('tertiary:disabled', <LobsterButton
        data="favorite"
        dataType="icon"
        dataVariation="tertiary"
        dataDisabled></LobsterButton>)}

      {section('quarternary', <LobsterButton
        data="favorite"
        dataType="icon"
        dataVariation="quarternary"></LobsterButton>)}
      {section('quarternary:disabled', <LobsterButton
        data="favorite"
        dataType="icon"
        dataVariation="quarternary"
        dataDisabled></LobsterButton>)}

      {section('size xsmall', <LobsterButton
        data="favorite"
        dataType="icon"
        dataSize="xsmall"></LobsterButton>)}

      {/*  */}
      {sectionHeader('Text Links')}

      {section('primary', <LobsterLink data="Lorem Ipsum"></LobsterLink>)}

      {section('secondary', <LobsterLink
        className="typo_transform--uppercase"
        data="Download"
        dataSize="small"
        dataVariation="secondary"></LobsterLink>)}

      {section('button-primary', <LobsterLink data="Lorem Ipsum" dataVariation="button-primary"></LobsterLink>)}

      {section('size small', <LobsterLink data="Lorem Ipsum" dataSize="small"></LobsterLink>)}

      {/*  */}
      {sectionHeader('Icon Sizes')}

      {section('xsmall', <LobsterIcon data="cake" dataSize="xsmall"></LobsterIcon>)}
      {section('small', <LobsterIcon data="cake" dataSize="small"></LobsterIcon>)}
      {section('medium', <LobsterIcon data="cake"></LobsterIcon>)}

      {/*  */}
      {sectionHeader('Banner')}
      {section('', <PageBanner title="Banner Title">
        <div className="lobster_layout_padding-vertical--lg">Banner Text Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec ullamcorper nulla non metus auctor fringilla. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.</div>
        <LobsterSheetTrigger
          dataTarget="full-timeline"
          data="Banner Link"
          dataVariation="primary"
          dataType="text"
          sheetTitle="full timeline"
          className="lobster_layout_margin-bottom--lg"></LobsterSheetTrigger>
      </PageBanner>, 'column')}

      {/*  */}
      {sectionHeader('Progress Bar')}
      {section('', <LobsterProgressIndicator
        showPercentage="false"
        type="bar"
        animationLoop="false"
        percentComplete={50}
        progressBackground="primary"
        trackBackground="gray-100">
      </LobsterProgressIndicator>, 'column')}

      {/*  */}
      {sectionHeader('Widget')}
      {section('', <div className="widget widget--bordered">
        <div>Sed posuere consectetur est at lobortis. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Sed posuere consectetur est at lobortis. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Aenean lacinia bibendum nulla sed consectetur. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.</div>
      </div>, 'column')}

      {/*  */}
      {sectionHeader('Colors')}
      {section('', <div className="lobster_layout_padding-all--xs lobster_layout_background-color--primary-100">primary-100</div>, 'column')}
      {section('', <div className="lobster_layout_padding-all--xs lobster_layout_background-color--primary-200">primary-200</div>, 'column')}
      {section('', <div className="lobster_layout_padding-all--xs lobster_layout_background-color--primary-300">primary-300</div>, 'column')}

      {section('', <div className="lobster_layout_padding-all--xs lobster_layout_background-color--secondary-100">secondary-100</div>, 'column')}

      {section('', <div className="lobster_layout_padding-all--xs lobster_layout_background-color--warn-100">warn-100</div>, 'column')}
      {section('', <div className="lobster_layout_padding-all--xs lobster_layout_background-color--warn-200">warn-200</div>, 'column')}

      {section('', <div className="lobster_layout_padding-all--xs lobster_layout_background-color--gray-100">gray-100</div>, 'column')}
      {section('', <div className="lobster_layout_padding-all--xs lobster_layout_background-color--gray-700">gray-700</div>, 'column')}
      {section('', <div className="lobster_layout_padding-all--xs lobster_layout_background-color--gray-900">gray-900</div>, 'column')}

      {section('', <div className="lobster_layout_padding-all--xs lobster_layout_background-color--black">black</div>, 'column')}
      {section('', <div className="lobster_layout_padding-all--xs lobster_layout_background-color--black-alpha-25">black-alpha-25</div>, 'column')}

      <div className="lobster_layout_margin-bottom--md">Colors Apply to Mayflower Only</div>
      {section('', <div className="lobster_layout_padding-all--xs lobster_layout_background-color--primary-400">primary-400</div>, 'column')}
      {section('', <div className="lobster_layout_padding-all--xs lobster_layout_background-color--primary-500">primary-500</div>, 'column')}
      {section('', <div className="lobster_layout_padding-all--xs lobster_layout_background-color--primary-600">primary-600</div>, 'column')}
      {section('', <div className="lobster_layout_padding-all--xs lobster_layout_background-color--primary-700">primary-700</div>, 'column')}

      {section('', <div className="lobster_layout_padding-all--xs lobster_layout_background-color--gray-200">gray-200</div>, 'column')}

      {section('', <div className="lobster_layout_padding-all--xs lobster_layout_background-color--secondary-200">secondary-200</div>, 'column')}

      {/*  */}
      {sectionHeader('Typography')}
      {section('display-2', <LobsterHeading data="Heading" display="display-2"></LobsterHeading>)}

      {section('display-3', <LobsterHeading data="Heading" display="display-3"></LobsterHeading>)}
      {section('display-3 modified font weight', <LobsterHeading display="display-3">
        <h2 className="typo_body-weight--regular">All Tasks</h2>
      </LobsterHeading>)}

      {section('display-4', <LobsterHeading data="Heading" display="display-4"></LobsterHeading>)}
      {section('display-5', <LobsterHeading data="Heading" display="display-5"></LobsterHeading>)}

      {section('xsmall', <div className="typo_body--xsmall">Lorem Ipsum</div>)}
      {section('small', <div className="typo_body--small">Lorem Ipsum</div>)}
      {section('body', <div>Lorem Ipsum</div>)}

      {section('bold', <div className="typo_body--bold">Lorem Ipsum</div>)}
      {section('Navigation Secondary', <div className="typo_navigation--secondary">Lorem Ipsum</div>)}

      {section('Form Error', <div className="typo_form--error">Lorem Ipsum</div>)}

      {section('Button', <div className="typo_button">Lorem Ipsum</div>)}

      {/*  */}
      {sectionHeader('Forms')}
      {section('Input', <LobsterInput dataLabel="Input Label"></LobsterInput>)}
      {section('Input Disabled', <LobsterInput dataLabel="Input Label" dataDisabled></LobsterInput>)}
      {section('Input Invalid', <LobsterInput dataLabel="Input Label" dataInvalid dataErrors={[{ "message": "error message" }]}></LobsterInput>)}
      {section('Input w/ Tooltip', <LobsterInput dataLabel="Input Label">
        <lobster-tooltip-wrapper slot="lobster-tooltip" show-on="hover" horizontal-position="right" vertical-position="bottom">
          <lobster-button slot="lobster-tooltip-trigger" data="help_outline" data-type="icon" data-variation="secondary"
            data-size="xsmall">
          </lobster-button>
          <lobster-tooltip data-type="expanded" id="input-label-tooltip">
            <p>Curabitur blandit tempus porttitor. Cras justo odio, dapibus ac facilisis in, egestas eget quam.</p>
          </lobster-tooltip>
        </lobster-tooltip-wrapper>
      </LobsterInput>)}

      {section('Textarea', <LobsterTextarea dataLabel="Textarea"></LobsterTextarea>)}
      {section('Textarea Disabled', <LobsterTextarea dataLabel="Textarea Label" dataDisabled></LobsterTextarea>)}

      {section('Radio Group Label', <LobsterRadioGroup
        dataLabel="Radio Group"
        getter={() => 'strawberry'}
        dataOptions={[{
          "label": "Strawberry",
          "value": "strawberry"
        }, {
          "label": "Banana",
          "value": "banana"
        }]}>
      </LobsterRadioGroup>)}
      {section('Radio Group Label', <LobsterRadioGroup
        dataLabel="Radio Group"
        getter={() => 'banana'}
        dataOptions={[{
          "label": "Strawberry (disabled)",
          "value": "strawberry",
          "disabled": true
        }, {
          "label": "Banana",
          "value": "banana"
        }]}>
      </LobsterRadioGroup>)}

      {section('Checkbox', <LobsterCheckbox dataLabel="Checkbox Label"></LobsterCheckbox>)}
      {section('Checkbox Disabled', <LobsterCheckbox dataLabel="Checkbox Label" dataDisabled></LobsterCheckbox>)}

      {/*  */}
      {sectionHeader('Tooltips')}
      {section('Tooltip (on hover)', <LobsterTooltipWrapper
        showOn="hover"
        horizontalPosition="right"
        verticalPosition="top">
        <LobsterButton
          slot="lobster-tooltip-trigger"
          data="info_outline"
          dataType="icon"
          dataVariation="quarternary"
          dataSize="xsmall"
          dataAriaLabel="Your Items Info">
        </LobsterButton>
        <LobsterTooltip
          slot="lobster-tooltip"
          id="items-description">
          <p>Curabitur blandit tempus porttitor. Cras justo odio, dapibus ac facilisis in, egestas eget quam.</p>
        </LobsterTooltip>
      </LobsterTooltipWrapper>)}
      {section('Tooltip (on click)', <LobsterTooltipWrapper
        showOn="click"
        horizontalPosition="right"
        verticalPosition="top">
        <LobsterButton
          slot="lobster-tooltip-trigger"
          data="info_outline"
          dataType="icon"
          dataVariation="quarternary"
          dataSize="xsmall"
          dataAriaLabel="Your Items Info">
        </LobsterButton>
        <LobsterTooltip
          slot="lobster-tooltip"
          id="items-description">
          <p>Curabitur blandit tempus porttitor. Cras justo odio, dapibus ac facilisis in, egestas eget quam.</p>
        </LobsterTooltip>
      </LobsterTooltipWrapper>)}

    </div>
  );
}

export default Styleguide;
