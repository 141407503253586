import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as R from 'ramda';

import { LobsterDrawer } from '@hlk/lobster-react/dist';

import { showChecklist, hideChecklist } from 'store/actions/display/checklist';
import ChecklistHeader from 'components/ChecklistHeader';
import ChecklistStateDefault from 'components/ChecklistStateDefault';
import ChecklistStateEdit from 'components/ChecklistStateEdit';
import ChecklistStateAdd from 'components/ChecklistStateAdd';

import { fetchChecklistItems } from 'store/actions/api/checklistItems';
import { fetchChecklistContent } from 'store/actions/wordpress/checklistContent';


function Checklist() {
  // Utilities and Setup
  const dispatch = useDispatch()

  // Display
  const checklistDisplay = useSelector(state => state.checklistDisplay)

  // Tasks
  const tasks = useSelector(state => R.path(['checklistItems', 'data', 'tasks'], state))
  const filters = useSelector(state => R.path(['checklistItems', 'data', 'filters'], state))
  const [currentFilter, setCurrentFilter] = useState(0)

  // Internal State
  const [checklistState, setChecklistState] = useState('default');
  const [selectedItem, setSelectedItem] = useState(null);
  const [newAddedItem, setNewAddedItem] = useState(null);
  const [itemCompleteStatus, setItemCompleteStatus] = useState(null);

  // Content
  const headingDefault = useSelector(state => R.path(['checklistContent', 'data', 'default_view_heading'], state))
  const headingAdd = useSelector(state => R.path(['checklistContent', 'data', 'add_view_heading'], state))
  const headingEdit = useSelector(state => R.path(['checklistContent', 'data', 'edit_view_heading'], state))
  const labelTaskName = useSelector(state => R.path(['checklistContent', 'data', 'edit_field_labels', 'task_name'], state))
  const labelDueDate = useSelector(state => R.path(['checklistContent', 'data', 'edit_field_labels', 'due_date'], state))
  const labelNotes = useSelector(state => R.path(['checklistContent', 'data', 'edit_field_labels', 'notes'], state))
  const labelSaveCta = useSelector(state => R.path(['checklistContent', 'data', 'edit_field_labels', 'save_cta'], state))

  function editItem(id) {
    updateSelectedItem(id)
    return setChecklistState('edit')
  }

  function updateSelectedItem(id) {
    const item = R.find(item => {
      return item.id === id
    }, tasks)
    return item ? (
      setSelectedItem(item),
      setItemCompleteStatus(item.completed)
    ) : (
        setSelectedItem(null),
        setItemCompleteStatus(null)
      )
  }

  function addItem() {
    window.dataLayer.push({
      'event': 'addListItem'
    });
    setTimeout(() => {
      setChecklistState('add')
      dispatch(showChecklist());
    }, 1000);

    return
  }

  function setStateDefault() {
    updateSelectedItem(null)
    return setChecklistState('default')
  }

  function checklistDrawerOpenEvents() {
    dispatch(showChecklist());
    window.dataLayer.push({
      'event': 'openChecklist',
      'screenURL': window.location.pathname,

    });
  }

  function checklistDrawerCloseEvents() {
    dispatch(hideChecklist());
    window.dataLayer.push({
      'event': 'closeChecklist',
    });
    return setChecklistState('default')

  }

  function updateFilter(data) {
    // +1 is to account for 'all' filter not in array
    setCurrentFilter((R.indexOf(data, filters) + 1) || 0)
    // clear the manual filter update for newly added items
    setNewAddedItem(null)
  }

  // Find the percentage of the window the drawer should fill when open
  // offset: any pixel amount to remove from the fill area
  function percentOfWindow(offset = 0) {
    return Math.round(100 - (offset / window.innerHeight * 100))
  }

  function checklistStateRender(state) {
    if (state === 'edit') {
      return <ChecklistStateEdit
        key="edit"
        tasks={tasks}
        clearEditItem={setStateDefault}
        selectedItem={selectedItem}
        labelTaskName={labelTaskName}
        labelDueDate={labelDueDate}
        labelNotes={labelNotes}
        labelSaveCta={labelSaveCta}
        itemCompleteStatus={itemCompleteStatus}
      ></ChecklistStateEdit>
    }
    if (state === 'add') {
      return <ChecklistStateAdd
        key="add"
        onAddItemBack={(itemAdded, title) => {
          itemAdded && setNewAddedItem(title)
          return setStateDefault()
        }}
        labelTaskName={labelTaskName}
        labelDueDate={labelDueDate}
        labelNotes={labelNotes}
        labelSaveCta={labelSaveCta}></ChecklistStateAdd>
    }
    return <ChecklistStateDefault
      key="default"
      filters={filters}
      currentFilter={currentFilter}
      onFilterUpdate={updateFilter}
      tasks={tasks}
      onSelectItem={editItem}
      onAddItem={addItem}
      newItem={newAddedItem}></ChecklistStateDefault>
  }

  const checklistTitle = (state) => {
    if (state === 'edit') {
      return headingEdit || 'Task Details'
    }
    if (state === 'add') {
      return headingAdd || 'New Task'
    }
    if (state === 'default') {
      return headingDefault || 'Checklist'
    }
  }

  // Initialize Data
  useEffect(() => {
    dispatch(fetchChecklistContent())
    dispatch(fetchChecklistItems())
  }, [])

  if (!tasks) return null;
  return (
    <LobsterDrawer
      drawerTitle="Checklist"
      className="checklist"
      open={checklistDisplay}
      drawerOnOpen={() => checklistDrawerOpenEvents()}
      drawerOnClose={() => checklistDrawerCloseEvents()}
      peekHeight="94"
      openWindowHeight={percentOfWindow(158)}
      openWindowHeightXs={percentOfWindow(158)}
      openWindowHeightSm={percentOfWindow(158)}
      openWindowHeightMd={percentOfWindow(37)}
      openWindowHeightLg={percentOfWindow(37)}>

      <ChecklistHeader
        showTaskCount={checklistState === 'default'}
        showAddNewButton={checklistState === 'default'}
        showBackButton={checklistState != 'default'}
        showEditButtons={checklistState === 'edit'}
        taskCount={tasks.length}
        onAddItem={addItem}
        onItemCompleted={(data) => setItemCompleteStatus(data)}
        checklistTitle={checklistTitle(checklistState)}
        onBack={setStateDefault}
        item={selectedItem}
        itemCompleteStatus={itemCompleteStatus}></ChecklistHeader>
      <div>
        {checklistStateRender(checklistState)}
      </div>
    </LobsterDrawer>

  );

}

export default Checklist;
