import React from 'react';
import { LobsterLayout, LobsterIcon, LobsterButton } from '@hlk/lobster-react/dist';
import { NavLink } from 'react-router-dom';
import * as R from 'ramda';
import ImageIcon from 'components/ImageIcon';
import { Swipeable } from 'react-swipeable'
import moment from 'moment'
import EstimateLink from 'components/EstimateLink';

function milestoneSlide(milestone, idx, count, visible, state) {
  milestone.completed = (milestone.status === 'COMPLETE')

  // Combine data variables with description string from ACF
  if (milestone.type === 'GET_AN_ESTIMATE') {
    let estimator = milestone.additionalInfo.estimator;
    let time = milestone.additionalInfo.surveyTimestamp;
    let date = moment(time);
    let dateComponent = date.utc().format('dddd, MMMM D, YYYY');
    let timeComponent = date.utc().format('h:mm z');

    let replaceArray = ['estimator', 'date', 'time']
    let replaceWith = [estimator, dateComponent, timeComponent]

    for (var i = 0; i < replaceArray.length; i++) {
      milestone.milestone_description = milestone.milestone_description.split('[' + replaceArray[i] + ']').join(replaceWith[i]);
    }
  }

  if (milestone.type === 'DELIVERY_DAY') {
    let firstDate = milestone.additionalInfo.actualDeliveryDate
    let lastDate = milestone.additionalInfo.latestDeliveryDate

    if ((firstDate != '') && (lastDate != '')) {
      let deliverySpreadString = moment(firstDate).format('dddd, MMMM D, YYYY') + ' and ' + moment(lastDate).format('dddd, MMMM D, YYYY');
      milestone.milestone_description = milestone.milestone_description.replace('[Delivery_Spread]', deliverySpreadString);
    }
  }

  return (
    <LobsterLayout
      key={idx}
      className={`move_tracker-slide ${!visible ? 'hidden' : 'visible'} ${state ? state : ''}`}
      aria-hidden={(state == 'active') ? 'false' : 'true'}
      tabIndex={(state == 'active') ? '0' : '-1'}
      aria-roledescription="slide">

      <LobsterLayout
        width="100"
        className="move_tracker-slider-inner"
        dataAlign="center">
        <LobsterLayout
          dataJustify="center"
          dataAlign="center"
          className="lobster_layout_margin-right--sm move_tracker-slide-icon lobster_layout_flex--noshrink">
          {milestone.completed ?
            <LobsterIcon data="check"></LobsterIcon> :
            <ImageIcon
              defaultIcon={milestone.icon_default}
              iconUrlColor={milestone.icon_url_color}></ImageIcon>}
        </LobsterLayout>
        <div className="widget widget--bordered">
          <LobsterLayout
            dataDirection="column-reverse"
            directionMd="row"
            dataJustify="space-between"
            dataAlign="flex-start"
            alignMd="center">
            <div className="typo_body--bold">{milestone.milestone_title}</div>
            <div className="typo_navigation--secondary">Step {idx + 1} of {count}</div>
          </LobsterLayout>
          <div className="move_tracker-slide--meta">
            <div className="typo_body--small lobster_layout_padding-top--sm">{milestone.milestone_description}</div>
            {milestone.milestone_cta.milestone_cta_label &&
              <LobsterLayout>
                {milestone.milestone_cta.milestone_cta_url.toLowerCase() === '[estimate]' ?
                  <EstimateLink
                    buttonType="button"
                    tabIndex={(state == 'active') ? '0' : '-1'}
                    aria-hidden={(state == 'active') ? 'false' : 'true'}
                    data={milestone.milestone_cta.milestone_cta_label}
                    sendAgreementLinkToDataLayer="false"
                    onClick={() => window.dataLayer.push([
                      { 'event': 'stepCTA', 'timelineStep': `Step${(idx + 1)}_${milestone.milestone_title}` },
                      { 'event': 'screenLoaded', 'screenURL': `${milestone.milestone_cta.milestone_cta_url}` }])}>
                  </EstimateLink>
                  : <NavLink
                    tabIndex={(state == 'active') ? '0' : '-1'}
                    aria-hidden={(state == 'active') ? 'false' : 'true'}
                    className={`lobster_layout-fill lobster_layout_margin-top--sm link_variation-button--primary`}
                    to={milestone.milestone_cta.milestone_cta_url}
                    onClick={() => window.dataLayer.push([{
                      'event': 'stepCTA', 'timelineStep': `Step${(idx + 1)}_${milestone.milestone_title}`
                    }, { 'event': 'screenLoaded', 'screenURL': `${milestone.milestone_cta.milestone_cta_url}` }])}>
                    {milestone.milestone_cta.milestone_cta_label}
                  </NavLink>}
              </LobsterLayout>
            }
          </div>
        </div>
      </LobsterLayout>
    </LobsterLayout >
  )
}

function MoveTrackerCarousel(props) {
  const mapIndexed = R.addIndex(R.map);
  const getSlideState = (idx) => {
    if (idx === props.activeMilestoneIdx) {
      return 'active'
    } else if (prevSlide(idx)) {
      return 'previous'
    } else if (nextSlide(idx)) {
      return 'next'
    }
  }

  const prevSlide = (idx) => {
    return props.activeMilestoneIdx === idx - 1
  }

  const nextSlide = (idx) => {
    return props.activeMilestoneIdx === idx + 1
  }

  const slideIsVisible = (idx) => {
    return props.activeMilestoneIdx === idx || prevSlide(idx) || nextSlide(idx)
  }

  return (
    <div className="move_tracker-wrapper">
      <Swipeable
        preventDefaultTouchmoveEvent
        onSwipedDown={props.onReceedSlides}
        onSwipedUp={props.onAdvanceSlides}>
        <LobsterLayout
          dataDirection="column"
          dataJustify="space-between"
          dataAlign="center"
          aria-roledescription="carousel">
          <LobsterButton
            className="move_tracker-previous"
            dataAriaLabel="View previous milestones"
            aria-controls="movetracker-items"
            type="button"
            dataType="icon"
            data="keyboard_arrow_up"
            dataVariation="carousel-icon"
            onClick={() => window.dataLayer.push({ 'event': 'timelineScroll', 'scrollDirection': 'Up' })}
            setter={props.onReceedSlides}

          ></LobsterButton>
          <div
            className="move_tracker-slides-wrapper"
            aria-live="polite"
            id="movetracker-items">
            {props.milestones && mapIndexed((milestone, idx) => {
              return milestoneSlide(milestone, idx, props.milestones.length, slideIsVisible(idx), getSlideState(idx))
            }, props.milestones)}
          </div>
          <LobsterButton
            className="move_tracker-next"
            dataAriaLabel="View next milestones"
            dataType="icon"
            aria-controls="movetracker-items"
            data="keyboard_arrow_down"
            dataVariation="carousel-icon"
            onClick={() => window.dataLayer.push({ 'event': 'timelineScroll', 'scrollDirection': 'Down' })}
            setter={props.onAdvanceSlides}></LobsterButton>
        </LobsterLayout>
      </Swipeable>
    </div>
  );

}

export default MoveTrackerCarousel;
