import contactsContentMiddleware from 'middleware/wordpress/contactsContent'

// Reducer
export const contactsContent = (state = {}, action) => {

  //Clears Page Data
  if (action.type === 'CLEAR_CONTACTS_CONTENT') {
    return {
      data: null,
      error: false,
    }
  }

  // Sets Page Data
  if (action.type === 'STORE_CONTACTS_CONTENT') {
    return {
      data: contactsContentMiddleware(action.data),
      error: false,
    }
  }

  // Handles Error
  if (action.type === 'CONTACTS_CONTENT_ERROR') {
    return {
      data: contactsContentMiddleware(action.data),
      error: true
    }
  }

  return state
}
