import * as R from 'ramda'

const hvisLens = R.lensProp('highValueInventoryItems')

export const hvisMiddleware = (data) => {
  return data
}

// Transform a HVI to the format used by the API
export const hvisMiddlewareOutgoing = (data) => {

  let formatted = R.map((item) => {
    return {
      description: item.description,
    }
  },R.view(hvisLens, data)
  )
  return R.set(hvisLens, formatted, data)
}
