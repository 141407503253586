import React from 'react';

// React Routing
import { Route, Redirect, BrowserRouter as Router } from 'react-router-dom'

// Views imports
import Payment from 'views/Payment';
import Dashboard from 'views/Dashboard';
import HighValueInventory from 'views/HighValueInventory';
import Contacts from 'views/Contacts';
import Styleguide from 'views/Styleguide';
import PageLayout from 'components/PageLayout';

const Root = () => {
  return <Router>
    <PageLayout>
      <Route exact path="/" component={Dashboard} />
      <Route path="/payment" component={Payment} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/high-value-inventory" component={HighValueInventory} />
      <Route path="/contacts" component={Contacts} />
      <Route path="/styleguide" component={Styleguide} />
    </PageLayout>
  </Router>
}

export default Root
