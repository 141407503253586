import {
  getEAcceptance,
  getSpecificForm
} from 'lib/api'
import { userQuotes } from 'lib/utils'
import * as R from 'ramda';

export function fetchEAcceptancePdf(formTypeCode) {
  return function (dispatch, getState) {

  const quotes = userQuotes(getState())
  // get quote id from customer id... 
  let fullType = R.find(R.propEq('type', 'Full-Service'))(quotes); 
  const options = {
    responseType: 'arraybuffer',
    headers: {
      'Accept': 'application/pdf'
    },
  };

  const tab = window.open();
  return getEAcceptance(fullType.id, options).then(response => {  
  

  let formType = R.find(R.propEq('formTypeCode', formTypeCode))(response.data); 
  return getSpecificForm(formType.formRefId, options, formType.formTypeCode).then(response => {
    const file = new Blob(
      [response.data], 
      {type: 'application/pdf'});
    const pdfURL = window.URL.createObjectURL(file);


    tab.location.href = pdfURL;


    })
  }).catch(error => {
    console.log(error);
    });
  }
}



