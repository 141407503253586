import React, { useEffect } from 'react';
import * as R from 'ramda';

import { LobsterButton, LobsterIcon, LobsterLayout } from '@hlk/lobster-react/dist';

// Slider Imports
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Single Slide
function CarouselSlide(name, selected, onSelect) {

  function onSelectEvents() {
    window.dataLayer.push({ 'event': 'checklistTimingClick', 'selectionText': `${name}` });
    onSelect(name);
  }

  return (
    <div key={name}>
      <LobsterButton
        className="checklist_weeks_carousel-slide"
        data={!name ? 'All' : name}
        dataSize="small"
        dataVariation={selected ? 'chip-selected' : 'chip'}
        setter={() => onSelectEvents()}></LobsterButton>
    </div>
  )
}

// Carousel of slides
function ChecklistWeeksCarousel(props) {

  function NextArrow(props) {
    const { className, onClick } = props;
    function onClickEvents() {
      window.dataLayer.push({ 'event': 'checklistTimingScroll', 'scrollDirection': 'right' });
      onClick();
    }
    return (
      <div
        className={className}
        onClick={onClickEvents}
      />
    );
  }

  function PrevArrow(props) {
    const { className, onClick } = props;
    function onClickEvents() {
      window.dataLayer.push({ 'event': 'checklistTimingScroll', 'scrollDirection': 'left' });
      onClick();
    }
    return (
      <div
        className={className}
        onClick={onClickEvents}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    initialSlide: props.currentFilter,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 543,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 429,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
    ]
  };

  const mapIndexed = R.addIndex(R.map);

  return (
    <LobsterLayout
      dataAlign="center"
      className="checklist_weeks_carousel-wrapper">

      <div className="checklist_weeks_carousel-prev">
        <LobsterIcon data="keyboard_arrow_left"></LobsterIcon>
      </div>

      <Slider
        className="checklist_weeks_carousel"
        {...settings}>
        {CarouselSlide(null, props.currentFilter === 0, props.onFilterWeekSelected)}
        {mapIndexed((filter, idx) => {
          return CarouselSlide(filter, props.currentFilter === (idx + 1), props.onFilterWeekSelected)
        }, props.filters)}
      </Slider>

      <div className="checklist_weeks_carousel-next">
        <LobsterIcon data="keyboard_arrow_right"></LobsterIcon>
      </div>

    </LobsterLayout>
  );

}

export default ChecklistWeeksCarousel;
